import styles from './Stoerer.module.scss';
import Dialog from '../Dialog/Dialog';
import Button, { ButtonStyle } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import DialogType from '../Dialog/DialogType';

const Stoerer = (props) => {
    const { t } = useTranslation();

    const mapType = (stoererType) => {
        switch (stoererType) {
            case 'WARNING':
                return DialogType.WARNING;
            case 'ERROR':
                return DialogType.ERROR;
            case 'INFO':
            default:
                return DialogType.INFO;
        }
    };

    const onClickWithoutPropagation = (event, callback) => {
        event.stopPropagation();
        if (callback) {
            callback();
        }
    };

    const actions =
        props.stoerer.button_type === 'READ_SNOOZE' ? (
            <>
                <Button
                    style={ButtonStyle.SECONDARY}
                    text={t('stoerer.snooze')}
                    icon={'bell'}
                    onClick={(event) =>
                        onClickWithoutPropagation(event, () =>
                            props.close(props.stoerer.internal_id, true)
                        )
                    }
                />
                <Button
                    style={ButtonStyle.PRIMARY}
                    text={t('stoerer.close')}
                    icon={'checkmark'}
                    onClick={(event) =>
                        onClickWithoutPropagation(event, () =>
                            props.close(props.stoerer.internal_id, false)
                        )
                    }
                />
            </>
        ) : null;

    return props.stoerer.type === 'IMAGE' ? (
        <Dialog
            onClose={() => props.close(props.stoerer.internal_id, false)}
            image={props.stoerer.image_url}
            link={props.stoerer.image_link}
            actions={actions}
            dark={props.stoerer.close_type === 'LIGHT'}
        />
    ) : (
        <Dialog
            title={props.stoerer.text_headline}
            message={props.stoerer.text_intro}
            more={props.stoerer.text_content}
            type={mapType(props.stoerer.text_style)}
            onClose={() => props.close(props.stoerer.internal_id, false)}
            actions={actions}
        />
    );
};

export default Stoerer;
