import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-relay';
import { useUserContext } from '../../../contexts/UserContext';
import Button, { ButtonStyle } from '../../Button/Button';
import Dialog from '../../Dialog/Dialog';
import DialogType from '../../Dialog/DialogType';
import ConfirmDeleteUserDialog from '../ConfirmDeleteUserDialog';

import styles from '../PermissionsTable.module.scss';
import classNames from 'classnames';

const MainCell = ({
    row,
    toggleEditRow,
    resetChanges,
    children,
    deleteMutation,
    deleteMutationUpdater,
    updateMutation,
    updateMutationUpdater,
    onUserDeleted,
    canDelete,
}) => {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const { me } = useUserContext();
    const [userToDelete, setUserToDelete] = useState(undefined);
    const [commitUpdate, isUpdating] = useMutation(updateMutation);
    const [commitDelete, isDeleting] = useMutation(deleteMutation);

    const deleteUser = useCallback(
        (user) => {
            commitDelete({
                variables: { crmId: user.crmId },
                onError: (networkError) => {
                    const message =
                        networkError instanceof Error
                            ? networkError.message
                            : t('generics.unexpectedError');
                    setError(message);
                },
                onCompleted: (_, errors) => {
                    if (errors?.length) {
                        const [mutationError] = errors;
                        setError(mutationError.message ?? t('generics.unexpectedError'));
                    } else {
                        onUserDeleted(user);
                    }
                },
                updater: (store) => deleteMutationUpdater(store, user.crmId),
            });
        },
        [commitDelete, t, deleteMutationUpdater, onUserDeleted]
    );

    const saveChanges = useCallback(
        (crmId, { permissions, emailServices }, row) => {
            commitUpdate({
                variables: {
                    crmId,
                    input: {
                        permissions: Object.entries(permissions ?? {}).map(
                            ([permissionId, isEnabled]) => ({ permissionId, isEnabled })
                        ),
                        emailServices: Object.entries(emailServices ?? {}).map(
                            ([emailServiceId, isEnabled]) => ({ emailServiceId, isEnabled })
                        ),
                    },
                },
                onError: (networkError) => {
                    const message =
                        networkError instanceof Error
                            ? networkError.message
                            : t('generics.unexpectedError');
                    setError(message);
                },
                onCompleted: (_, errors) => {
                    if (errors?.length) {
                        const [mutationError] = errors;
                        setError(mutationError.message ?? t('generics.unexpectedError'));
                    } else {
                        toggleEditRow(row);
                    }
                },
                updater: (store) =>
                    updateMutationUpdater(store, crmId, { permissions, emailServices }),
            });
        },
        [commitUpdate, t, updateMutationUpdater, toggleEditRow]
    );

    return (
        <>
            {children}
            <div
                className={classNames({
                    [styles.MainCellActions]: true,
                    [styles.MainCellActionsColumn]: !!row.state.isEditing,
                })}
            >
                {row.state.isEditing ? (
                    <>
                        <Button
                            icon='save'
                            style={ButtonStyle.Primary}
                            text={t('generics.saveButton')}
                            disabled={isUpdating}
                            onClick={() => saveChanges(row.original.crmId, row.state.changes, row)}
                        />
                        <Button
                            icon='cross'
                            style={ButtonStyle.SECONDARY}
                            text={t('generics.cancelButton')}
                            disabled={isUpdating}
                            onClick={() => {
                                resetChanges(row);
                                toggleEditRow(row);
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Button
                            icon='edit'
                            style={ButtonStyle.SECONDARY}
                            onClick={() => toggleEditRow(row)}
                        />
                        {canDelete && row.original.crmId !== me.crm_id && (
                            <Button
                                icon='trash'
                                style={ButtonStyle.SECONDARY}
                                onClick={() => setUserToDelete(row.original)}
                                disabled={isDeleting}
                            />
                        )}
                    </>
                )}
            </div>
            {userToDelete && (
                <ConfirmDeleteUserDialog
                    email={userToDelete.email}
                    onCancel={() => setUserToDelete(undefined)}
                    onConfirm={() => {
                        deleteUser(userToDelete);
                        setUserToDelete(undefined);
                    }}
                />
            )}
            {error && (
                <Dialog
                    title={t('generics.error')}
                    message={error}
                    type={DialogType.ERROR}
                    onClose={() => setError('')}
                />
            )}
        </>
    );
};

export default MainCell;
