import PropTypes from 'prop-types';
import FlagBg from './svgs/bg.svg';
import FlagDa from './svgs/da.svg';
import FlagDe from './svgs/de.svg';
import FlagEn from './svgs/en.svg';
import FlagEt from './svgs/et.svg';
import FlagFi from './svgs/fi.svg';
import FlagFr from './svgs/fr.svg';
import FlagEl from './svgs/el.svg';
import FlagGa from './svgs/ga.svg';
import FlagIt from './svgs/it.svg';
import FlagHr from './svgs/hr.svg';
import FlagLv from './svgs/lv.svg';
import FlagLt from './svgs/lt.svg';
import FlagMt from './svgs/mt.svg';
import FlagNl from './svgs/nl.svg';
import FlagPl from './svgs/pl.svg';
import FlagPt from './svgs/pt.svg';
import FlagRo from './svgs/ro.svg';
import FlagSv from './svgs/sv.svg';
import FlagSk from './svgs/sk.svg';
import FlagSl from './svgs/sl.svg';
import FlagUa from './svgs/ua.svg';
import FlagEs from './svgs/es.svg';
import FlagCs from './svgs/cs.svg';
import FlagHu from './svgs/hu.svg';
import FlagTr from './svgs/tr.svg';
import FlagNo from './svgs/no.svg';
import FlagZh from './svgs/zh.svg';
import FlagHy from './svgs/hy.svg';
import FlagRu from './svgs/ru.svg';

import styles from './Flag.module.scss';

const flags = {
    bg: FlagBg,
    da: FlagDa,
    de: FlagDe,
    en: FlagEn,
    et: FlagEt,
    fi: FlagFi,
    fr: FlagFr,
    el: FlagEl,
    ga: FlagGa,
    it: FlagIt,
    hr: FlagHr,
    lv: FlagLv,
    lt: FlagLt,
    mt: FlagMt,
    nl: FlagNl,
    pl: FlagPl,
    pt: FlagPt,
    ro: FlagRo,
    sv: FlagSv,
    sk: FlagSk,
    sl: FlagSl,
    ua: FlagUa,
    es: FlagEs,
    cs: FlagCs,
    hu: FlagHu,
    tr: FlagTr,
    no: FlagNo,
    zh: FlagZh,
    hy: FlagHy,
    ru: FlagRu,
};

const Flag = (props) => {
    return (
        <img
            data-testid='Flag'
            src={flags[props.cc]}
            alt={props.cc}
            className={`${styles.Flag} ${props.className}`}
        />
    );
};

Flag.propTypes = {
    cc: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Flag;
