import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';
import { CrossIcon } from '../Icon/Icon';

import styles from './SidePanel.module.scss';

const SidePanel = (props) => {
    return (
        <div
            className={classNames({
                [styles.SidePanel]: true,
                [styles.SidePanelMobile]: props.isMobileView,
            })}
            data-testid='SidePanel'
        >
            <div className={styles.SidePanelTitle}>
                {props.title}
                <CrossIcon className={styles.SidePanelClose} onClick={props.onClose} />
            </div>
            <div className={styles.SidePanelContent}>
                <LocalErrorBoundary>{props.children}</LocalErrorBoundary>
            </div>
        </div>
    );
};

SidePanel.propTypes = {
    title: PropTypes.string,
};

export default SidePanel;
