import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { useLazyLoadQuery } from 'react-relay';

export const BookletsQuery = graphql`
    query useBookletsQuery {
        booklets {
            id
            internal_id
            title
            type
            include_other_information
            include_general_information
            timeframe
            from
            to
            group_returns
            routes
        }
    }
`;

function useBooklets() {
    const data = useLazyLoadQuery(BookletsQuery);
    return data.booklets.map((booklet) => bookletMapper(booklet));
}

export const bookletMapper = (booklet) => ({
    id: booklet.id,
    internal_id: booklet.internal_id,
    title: booklet.title,
    type: booklet.type,
    include_other_information: booklet.include_other_information,
    include_general_information: booklet.include_general_information,
    timeframe: booklet.timeframe,
    from: booklet.from,
    to: booklet.to,
    group_returns: booklet.group_returns,
    routes: booklet.routes,
});

export function useTransforms() {
    const bytesToString = useCallback((size) => {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        const unit = ['B', 'kB', 'MB', 'GB', 'TB'][i];
        const readableSize = (size / Math.pow(1024, i)).toFixed(1) * 1;
        return `${readableSize} ${unit}`;
    }, []);

    return {
        bytesToString,
    };
}

export default useBooklets;
