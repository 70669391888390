import React from 'react';

const AppStateContext = React.createContext({
    feature: false,
    setFeature: () => {},
    showHotline: false,
    setShowHotline: () => {},
});

export default AppStateContext;
