import React, { useEffect, useRef, useState } from 'react';

import styles from './StorageConditions.module.scss';
import { useTranslation } from 'react-i18next';

const StorageConditions = (props) => {
    const ref = useRef();
    const [height, setHeight] = useState('auto');
    const { i18n } = useTranslation();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        window.addEventListener('message', (event) => {
            const message = event.data;
            if (message.height && Number.isInteger(message.height)) {
                setHeight(48 + message.height + 'px');
            }
        });
    }, []);

    return (
        <div className={styles.StorageConditionsWrapper}>
            <iframe
                className={styles.StorageConditions}
                src={
                    (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                    '/' +
                    i18n.language +
                    '/storage-conditions/' +
                    props.hafasId
                }
                ref={ref}
                style={{ height: height }}
            />
        </div>
    );
};
export default StorageConditions;
