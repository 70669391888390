import useDateFormatter from '../../../hooks/useDateFormatter';

import styles from '../PermissionsTable.module.scss';
import classNames from 'classnames';

export const lastModifiedSortType = (
    { values: { lastModified: lastModified1 } },
    { values: { lastModified: lastModified2 } }
) => {
    const value1 = lastModified1.at || '';
    const value2 = lastModified2.at || '';
    return value1.localeCompare(value2, undefined, { numeric: true });
};

const LastModifiedCell = ({ row, value }) => {
    const { toDateTimeString } = useDateFormatter();
    const className = row.state.isEditing ? styles.LastModifiedEditing : '';

    return (
        <>
            {value.by && (
                <div className={classNames([className, styles.CellBreak])} title={value.by}>
                    {value.by}
                </div>
            )}
            {value.at && (
                <div
                    className={classNames([className, styles.CellBreak])}
                    title={toDateTimeString(value.at)}
                >
                    {toDateTimeString(value.at)}
                </div>
            )}
        </>
    );
};

export default LastModifiedCell;
