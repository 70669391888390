import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from './Select';

const LanguageSelect = ({
    clearable = true,
    defaultValue = undefined,
    onChange = () => undefined,
    readonly = false,
    required = false,
    error = '',
    value = undefined,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Select
            label={t('profile.languageLabel')}
            placeholder={t('generics.pleaseSelect')}
            clearable={clearable}
            defaultValue={defaultValue}
            onChange={onChange}
            readonly={readonly}
            required={required}
            error={error}
            value={value}
            {...props}
            options={[
                { label: t('languages.de'), value: 'GERMAN' },
                { label: t('languages.en'), value: 'ENGLISH' },
            ]}
        />
    );
};

LanguageSelect.propTypes = {
    clearable: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LanguageSelect;
