import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { RelayEnvironmentProvider } from 'react-relay';

import AppLoadingIndicator from './components/AppLoadingIndicator/AppLoadingIndicator';
import RelayEnvironment from './RelayEnvironment';
import reportWebVitals from './reportWebVitals';
import AuthGuard from './components/Auth/AuthGuard';
import AppConfigContextWrapper from './contexts/AppConfigContext';
import AppRoutes from './routes/AppRoutes';

import './i18n';

import './index.css';

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENV) {
    Sentry.init({
        environment: process.env.REACT_APP_SENTRY_ENV,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        tracesSampleRate: 0,
        release: process.env.REACT_APP_VERSION,
    });
}

const root = createRoot(document.getElementById('root'));
_renderApp(root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function _renderApp(root) {
    root.render(
        <React.StrictMode>
            <RelayEnvironmentProvider environment={RelayEnvironment}>
                <Suspense fallback={null}>
                    <AuthGuard>
                        <AppConfigContextWrapper>
                            <Suspense fallback={<AppLoadingIndicator />}>
                                <AppRoutes />
                            </Suspense>
                        </AppConfigContextWrapper>
                    </AuthGuard>
                </Suspense>
            </RelayEnvironmentProvider>
        </React.StrictMode>
    );
}
