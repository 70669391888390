import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './TextArea.module.scss';
import inputStyles from '../Input/Input.module.scss';
import Field from '../Field';

const TextArea = ({
    label = undefined,
    value: propValue = undefined,
    readonly = false,
    className = undefined,
    fitToHeight = false,
    onChange = () => undefined,
    ...props
}) => {
    const [value, setValue] = useState(propValue);
    const [height, setHeight] = useState('auto');
    const textAreaRef = useRef();

    useLayoutEffect(() => {
        if (!fitToHeight) return;
        setHeight(`${textAreaRef.current.scrollHeight}px`);
    }, [fitToHeight]);

    useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    return (
        <Field label={label} className={className} block={props.block} data-testid='TextArea'>
            <textarea
                ref={textAreaRef}
                className={`${inputStyles.Input} ${styles.Input} ${className}`}
                readOnly={readonly}
                value={value}
                onChange={({ target }) => {
                    setValue(target.value);
                    onChange(target.value);
                }}
                onInput={() => {
                    if (!fitToHeight) return;
                    textAreaRef.current.style.height = '0px';
                    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
                }}
                style={{ height }}
            />
        </Field>
    );
};

TextArea.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    readonly: PropTypes.bool,
    className: PropTypes.string,
    fitToHeight: PropTypes.bool,
    onChange: PropTypes.func,
};

export default TextArea;
