import { AuthError, TOTPError, NotAuthorizedError } from './components/Auth/Error';

// @TODO define errors in one file and use them all over the app
export class BackendError extends Error {}

async function assertAuthenticated(response) {
    if (response.status === 401) {
        throw new AuthError('AuthenticationRequired');
    }
}

async function assertOk(response) {
    if (response.status !== 200) {
        throw new Error('Server error');
    }
}

async function assertNotInMaintenance(response) {
    if (response.status === 503) {
        // @todo refactor to dedupe the code

        sessionStorage.clear();
        localStorage.clear();

        const apiUrl = process.env.REACT_APP_API_URL;
        const form = document.createElement('form');
        form.method = 'post';
        form.action = (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) + '/logout';
        document.body.appendChild(form);
        form.submit();
    }
}

async function assertAuthorized(data) {
    const authError = data.errors?.find(
        ({ message }) => message === 'This action is unauthorized.'
    );
    if (authError !== undefined) {
        throw new NotAuthorizedError('NotAuthorized');
    }
}

async function assertTOTPAuthenticated(data) {
    const totpError = data.errors?.find(
        ({ extensions }) => extensions.category === 'totp_required'
    );
    if (totpError !== undefined) {
        throw new TOTPError(
            totpError.extensions.totp_secret,
            totpError.extensions.totp_qrcode_image
        );
    }
}

async function assertNoError(data) {
    const unexpectedError = data.errors?.find(({ extensions }) => !extensions.validation); // @todo double check how validation errors sent back
    if (unexpectedError !== undefined) {
        throw new BackendError(unexpectedError.message); // @TODO double check if this is how error messages sent back
    }
}

async function fetchGraphQL(text, variables) {
    const access_token = localStorage.getItem('access_token');
    const totpToken = sessionStorage.getItem('totp_token') || '';
    const auth_header = {};
    if (access_token) {
        auth_header.Authorization = `Bearer ${JSON.parse(access_token).access_token}`;
    }

    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(
        (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) + '/graphql',
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-TOTP': totpToken,
                ...auth_header,
            },
            body: JSON.stringify({
                query: text,
                variables,
            }),
        }
    );

    // mutation -> onError will handle @TODO doublecheck
    // query -> error boundary will handle
    await assertNotInMaintenance(response);
    await assertAuthenticated(response);
    await assertOk(response);

    const data = await response.json();

    await assertAuthorized(data);
    await assertTOTPAuthenticated(data);
    await assertNoError(data);

    return data;
}

export default fetchGraphQL;
