import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-relay';
import { useTranslation } from 'react-i18next';

import Flag from '../Flag/Flag';
import { setCookie } from '../../helpers/cookie-manager';
import { viewType } from '../../constants';
import { ChangeLanguageMutation } from '../ChangeLanguageForm/ChangeLanguageForm';

import styles from './FooterMobile.module.scss';

const FooterMobile = ({ className = '' }) => {
    const [error, setError] = useState('');
    const [updateLanguage, isLoading] = useMutation(ChangeLanguageMutation);
    const { i18n, t } = useTranslation();

    return (
        <div className={`${styles.FooterMobile} ${className}`} data-testid='FooterMobile'>
            <div>© {t('footer.companyName')} </div>
            <div className={`${styles.FooterMobileLinks}`}>
                <div>
                    <a
                        className={`${styles.FooterMobileLink}`}
                        href={t('footer.websiteUrl')}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {t('footer.websiteLinkPrefix')} {t('footer.websiteLink')}
                    </a>
                </div>
                <div>
                    <a
                        className={`${styles.FooterMobileLink}`}
                        href={t('footer.privacyPolicyUrl')}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {t('footer.privacyPolicyLink')}
                    </a>
                </div>
                <div>
                    <a
                        className={`${styles.FooterMobileLink}`}
                        href={t('footer.imprintUrl')}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {t('footer.imprintLink')}
                    </a>
                </div>
                <div>
                    <button
                        className={`${styles.FooterMobileLink}`}
                        onClick={() => {
                            setCookie(viewType.COOKIE_NAME, viewType.COOKIE_VALUE_DESKTOP);
                            window.location.reload();
                        }}
                    >
                        {t('footer.viewTypeDesktopLink')}
                    </button>
                </div>
            </div>

            {_getLngSwitcher(i18n, t, error, setError, updateLanguage, isLoading)}
        </div>
    );
};

FooterMobile.propTypes = {
    className: PropTypes.string,
};

export default FooterMobile;

// check current language and return the other one
function _getLngSwitcher(i18n, t, error, setError, updateLanguage, isLoading) {
    const currentLng = i18n.language;
    const newLanguage = currentLng === 'de' ? 'en' : 'de';

    if (error) {
        return <div className={`${styles.FooterMobileLngSelectorContainer}`}>{error}</div>;
    }

    return (
        <div className={`${styles.FooterMobileLngSelectorContainer}`}>
            <button
                className={`${styles.FooterMobileLngSelector}`}
                disabled={isLoading}
                onClick={() => {
                    setError('');
                    updateLanguage({
                        variables: {
                            // @todo get from back-end/config
                            input: { language: newLanguage === 'de' ? 'GERMAN' : 'ENGLISH' },
                        },
                        onError: (networkError) => {
                            const message =
                                networkError instanceof Error
                                    ? networkError.message
                                    : t('generics.unexpectedError');
                            setError(message);
                        },
                        onCompleted: (_, errors) => {
                            if (errors?.length) {
                                const [mutationError] = errors;
                                if (mutationError.extensions.validation) {
                                    setError(
                                        mutationError.extensions.validation[
                                            'input.language'
                                        ]?.[0] ?? ''
                                    );
                                } else {
                                    setError(mutationError.message);
                                }

                                return;
                            }

                            window.location.reload();
                        },
                    });
                }}
            >
                <div className={`${styles.FooterMobileLngSelectorInner}`}>
                    <Flag key={newLanguage} cc={newLanguage} />
                    <span>{newLanguage === 'de' ? 'Deutsch' : 'English'}</span>
                </div>
            </button>
        </div>
    );
}
