import graphql from 'babel-plugin-relay/macro';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmailServicesList from '../EmailServicesList/EmailServicesList';

import styles from './EmailServices.module.scss';
import EmailServicesListEditor from '../EmailServicesList/EmailServicesListEditor';
import Button, { ButtonStyle } from '../Button/Button';
import { useMutation } from 'react-relay';
import Alert, { AlertType } from '../Alert/Alert';
import { Gender } from '../Form/Select/GenderSelect';

export const ChangeEmailServicesMutation = graphql`
    mutation EmailServicesMutation($input: UpdateEmailServicesInput!) {
        updateEmailServices(input: $input) {
            id
            emailServices {
                emailServiceId
                isEnabled
            }
        }
    }
`;

const EmailServices = ({ user, onChange, emailServicesConfig }) => {
    const [isReadonly, setIsReadonly] = useState(true);
    const [error, setError] = useState('');
    const [updateEmailServices, isLoading] = useMutation(ChangeEmailServicesMutation);
    const { t } = useTranslation();
    const [changedValues, setChangedValues] = useState({});

    const submitForm = () => {
        setError('');
        updateEmailServices({
            variables: {
                input: {
                    emailServices: Object.keys(changedValues).map(function (emailServiceId) {
                        return {
                            emailServiceId: emailServiceId,
                            isEnabled: changedValues[emailServiceId],
                        };
                    }),
                },
            },
            onError: (networkError) => {
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        setError(
                            mutationError.extensions.validation['input.emailServices']?.[0] ?? ''
                        );
                    } else {
                        setError(mutationError.message);
                    }
                } else {
                    onChange();
                    setIsReadonly(true);
                }
            },
        });
    };

    const resetForm = () => {
        setChangedValues({});
    };

    return (
        <div className={styles.EmailServices} data-testid='EmailServices'>
            <p>
                {t(
                    user.gender === Gender.TECHNICAL
                        ? 'profile.emailServicesDescriptionServiceAddress'
                        : 'profile.emailServicesDescriptionEmployee'
                )}
            </p>
            <br />
            <div className={styles.EmailServicesSpace}>
                {isReadonly || user.gender === Gender.TECHNICAL ? (
                    <EmailServicesList
                        emailServices={user.emailServices}
                        emailServicesConfig={emailServicesConfig}
                    />
                ) : (
                    <EmailServicesListEditor
                        emailServices={user.emailServices}
                        onChange={(emailService, newValue) => {
                            setChangedValues({
                                ...changedValues,
                                [emailService]: newValue,
                            });
                        }}
                        emailServicesConfig={emailServicesConfig}
                    />
                )}
            </div>
            {error && <Alert type={AlertType.ERROR}>{error}</Alert>}
            {user.gender !== Gender.TECHNICAL ? (
                <div className={styles.FormButtons}>
                    {isReadonly ? (
                        <Button
                            style={ButtonStyle.SECONDARY}
                            text={t('profile.modifyEmailServices')}
                            onClick={() => setIsReadonly(false)}
                            block
                        />
                    ) : (
                        <>
                            <Button
                                style={ButtonStyle.PRIMARY}
                                text={t('profile.saveEmailServices')}
                                onClick={() => submitForm()}
                                disabled={isLoading}
                                block
                            />
                            <Button
                                style={ButtonStyle.SECONDARY}
                                text={t('generics.cancelButton')}
                                onClick={() => {
                                    resetForm();
                                    setIsReadonly(true);
                                }}
                                disabled={isLoading}
                                block
                            />
                        </>
                    )}
                </div>
            ) : null}
        </div>
    );
};

EmailServices.propTypes = {
    user: PropTypes.shape({
        emailServices: PropTypes.arrayOf(
            PropTypes.shape({
                emailServiceId: PropTypes.string.isRequired,
                isEnabled: PropTypes.bool.isRequired,
            })
        ).isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    emailServicesConfig: PropTypes.arrayOf(
        PropTypes.shape({
            display: PropTypes.bool,
            name: PropTypes.string,
            description: PropTypes.string,
            services: PropTypes.arrayOf(
                PropTypes.shape({
                    emailServiceId: PropTypes.string,
                    name: PropTypes.string,
                    description: PropTypes.string,
                })
            ),
        })
    ),
};

export default EmailServices;
