import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import styles from './UpsertRoute.module.scss';
import Select from '../../Form/Select/Select';
import CheckBox from '../../Form/CheckBox/CheckBox';
import RadioGroup from '../../Form/RadioGroup/RadioGroup';
import Button, { ButtonStyle } from '../../Button/Button';
import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import { uuidv4 } from '@graphql-tools/mock/utils';
import Dialog from '../../Dialog/Dialog';
import DialogType from '../../Dialog/DialogType';

export const UpsertRouteMutation = graphql`
    mutation UpsertRouteMutation($id: ID, $input: UpsertBookletRouteInput!) {
        upsertBookletRoute(id: $id, input: $input) {
            routes
        }
    }
`;

const filterTerminalByCountryId = (terminals, filterID: number | string) => {
    if (filterID === 'all' || filterID === '') {
        return terminals;
    }
    return terminals.filter((terminal) => terminal.countryID === filterID);
};

const filterTerminalByDirectConnection = (terminals, filterIds: []) => {
    return terminals.filter((terminal) => filterIds.includes(terminal.internal_id));
};

const UpsertRoute = (props) => {
    const initialTerminal = [
        {
            id: 1,
            value: '',
        },
    ];
    const [error, setError] = useState('');
    const [startCountry, setStartCountry] = useState(props.routeToEdit?.startCountry ?? 'all');
    const [startTerminal, setStartTerminal] = useState(props.routeToEdit?.startTerminal ?? '');
    const [startTerminalError, setStartTerminalError] = useState('');
    const [destinationCountry, setDestinationCountry] = useState(
        props.routeToEdit?.destinationCountry ?? 'all'
    );
    const [destinationTerminal, setDestinationTerminal] = useState(
        props.routeToEdit?.destinationTerminal ?? ''
    );
    const [destinationTerminalError, setDestinationTerminalError] = useState('');
    const [preferredRoute, setPreferredRoute] = useState(
        props.routeToEdit?.preferredRoute ?? 'allRoutes'
    );
    const [includeReturnDirection, setIncludeReturnDirection] = useState(
        props.routeToEdit?.includeReturnDirection ?? false
    );

    const [viaTerminals, setViaTerminals] = useState(
        props.routeToEdit?.viaTerminals ?? initialTerminal
    );
    const [notViaTerminals, setNotViaTerminals] = useState(
        props.routeToEdit?.notViaTerminals ?? initialTerminal
    );

    const [filteredStartTerminal, setFilteredStartTerminal] = useState(props.terminals);
    const [filteredDestinationTerminal, setFilteredDestinationTerminal] = useState(props.terminals);

    const [upsertBooklet] = useMutation(UpsertRouteMutation);

    const getTerminalConnectionsFor = useCallback(
        (start, dest): [] => {
            if (start) {
                return props.terminalConnections
                    .filter((conn) => conn.start === start)
                    .map((conn) => conn.dest);
            }
            if (dest) {
                return props.terminalConnections
                    .filter((conn) => conn.dest === dest)
                    .map((conn) => conn.start);
            }
            return props.terminals.map((terminal) => terminal.internal_id);
        },
        [props.terminalConnections, props.terminals]
    );

    useEffect(() => {
        let terminals;
        if (preferredRoute === 'directTrainServicesOnly') {
            const filter = getTerminalConnectionsFor(null, destinationTerminal);
            terminals = filterTerminalByCountryId(
                filterTerminalByDirectConnection(props.terminals, filter),
                startCountry
            );
            if (!terminals.map((t) => t.internal_id).includes(startTerminal)) {
                setStartTerminal('');
            }
        } else {
            terminals = filterTerminalByCountryId(props.terminals, startCountry);
        }
        setFilteredStartTerminal(terminals);
    }, [
        startCountry,
        startTerminal,
        destinationTerminal,
        preferredRoute,
        props.terminals,
        getTerminalConnectionsFor,
    ]);

    useEffect(() => {
        let terminals;
        if (preferredRoute === 'directTrainServicesOnly') {
            const filter = getTerminalConnectionsFor(startTerminal, null);
            terminals = filterTerminalByCountryId(
                filterTerminalByDirectConnection(props.terminals, filter),
                destinationCountry
            );
            if (!terminals.map((t) => t.internal_id).includes(destinationTerminal)) {
                setDestinationTerminal('');
            }
        } else {
            terminals = filterTerminalByCountryId(props.terminals, destinationCountry);
        }
        setFilteredDestinationTerminal(terminals);
    }, [
        destinationCountry,
        startTerminal,
        destinationTerminal,
        preferredRoute,
        props.terminals,
        getTerminalConnectionsFor,
    ]);

    const updatePreferredRoute = (value: string) => {
        setPreferredRoute(value);
    };

    const handleAddTerminal = (type) => {
        if (type === 'via') {
            setViaTerminals([...viaTerminals, { id: viaTerminals.length + 1, value: '' }]);
        } else {
            setNotViaTerminals([...notViaTerminals, { id: notViaTerminals.length + 1, value: '' }]);
        }
    };

    const handleTerminalChange = (id, value, type) => {
        if (type === 'via') {
            const updatedTerminals = viaTerminals.map((terminal) =>
                terminal.id === id ? { ...terminal, value } : terminal
            );
            setViaTerminals(updatedTerminals);
        } else {
            const updatedTerminals = notViaTerminals.map((terminal) =>
                terminal.id === id ? { ...terminal, value } : terminal
            );
            setNotViaTerminals(updatedTerminals);
        }
    };

    const generateTerminalOptions = (terminals) => {
        return terminals.map((terminal) => ({
            label: terminal.name,
            value: terminal.internal_id,
        }));
    };

    const generateCountryOptions = (countries) => {
        const options = [
            {
                label: t('connections.selectCountry'),
                value: 'all',
            },
        ];

        countries.forEach((country) => {
            options.push({
                label: country.name,
                value: country.internal_id,
            });
        });

        return options;
    };

    const isValidForm = () => {
        let error = true;

        if (
            (startTerminal && destinationCountry !== 'all') ||
            (destinationTerminal && startCountry !== 'all') ||
            (startTerminal && destinationTerminal)
        ) {
            error = false;
        }

        if (
            (!startTerminal && startCountry === 'all') ||
            (!destinationTerminal && destinationCountry === 'all')
        ) {
            setStartTerminalError(t('connections.startTerminalRequired'));
            setDestinationTerminalError(t('connections.destinationTerminalRequired'));
            error = true;
        }

        if (
            !startTerminal &&
            startCountry !== 'all' &&
            !destinationTerminal &&
            destinationCountry !== 'all'
        ) {
            setStartTerminalError(t('connections.startTerminalRequired'));
            setDestinationTerminalError(t('connections.destinationTerminalRequired'));
            error = true;
        }
        return !error;
    };

    const addRoutes = (
        existingRoutes,
        terminals,
        startTerminal,
        destinationTerminal,
        preferredRoute,
        includeReturnDirection,
        viaTerminalsData,
        notViaTerminalsData
    ) => {
        terminals.forEach((terminal) => {
            const newRoute = {
                id: uuidv4(),
                startTerminal: startTerminal || terminal.internal_id,
                destinationTerminal: destinationTerminal || terminal.internal_id,
                preferredRoute,
                includeReturnDirection,
                viaTerminals: viaTerminalsData,
                notViaTerminals: notViaTerminalsData,
            };
            existingRoutes.push(newRoute);

            if (includeReturnDirection) {
                const newReturnRoute = {
                    id: uuidv4(),
                    destinationTerminal: startTerminal || terminal.internal_id,
                    startTerminal: destinationTerminal || terminal.internal_id,
                    preferredRoute,
                    includeReturnDirection,
                    viaTerminals: viaTerminalsData,
                    notViaTerminals: notViaTerminalsData,
                };
                existingRoutes.push(newReturnRoute);
            }
        });

        return existingRoutes;
    };

    const submitForm = () => {
        if (isValidForm()) {
            const viaTerminalsData =
                preferredRoute === 'specifiedRoute' ? viaTerminals : initialTerminal;
            const notViaTerminalsData =
                preferredRoute === 'specifiedRoute' ? notViaTerminals : initialTerminal;

            let existingRoutes = [];
            if (props.bookletRoutes) {
                existingRoutes = props.bookletRoutes;
            }
            let isEditOnly = false;
            let terminals = [];
            if (startTerminal === '' && startCountry) {
                terminals = filterTerminalByCountryId(filteredStartTerminal, startCountry);
            } else if (destinationTerminal === '' && destinationCountry) {
                terminals = filterTerminalByCountryId(
                    filteredDestinationTerminal,
                    destinationCountry
                );
            } else {
                isEditOnly = true;
                terminals.push({ internal_id: startTerminal });
            }

            if (props.routeToEdit?.id) {
                const editIndex = existingRoutes.findIndex(
                    (route) => route.id === props.routeToEdit?.id
                );
                if (isEditOnly) {
                    if (editIndex !== -1) {
                        const notEditedRoute = existingRoutes[editIndex];

                        existingRoutes[editIndex] = {
                            id: props.routeToEdit.id,
                            startTerminal,
                            destinationTerminal,
                            preferredRoute,
                            includeReturnDirection,
                            viaTerminals: viaTerminalsData,
                            notViaTerminals: notViaTerminalsData,
                        };

                        if (
                            notEditedRoute.includeReturnDirection !== includeReturnDirection &&
                            includeReturnDirection
                        ) {
                            const newReturnRoute = {
                                id: uuidv4(),
                                destinationTerminal: startTerminal,
                                startTerminal: destinationTerminal,
                                preferredRoute,
                                includeReturnDirection,
                                viaTerminals: viaTerminalsData,
                                notViaTerminals: notViaTerminalsData,
                            };
                            existingRoutes.push(newReturnRoute);
                        }
                    } else {
                        // Handle case when route to edit is not found
                        console.log('Route to edit not found.');
                    }
                } else {
                    if (editIndex !== -1) {
                        existingRoutes.splice(editIndex, 1);

                        existingRoutes = addRoutes(
                            existingRoutes,
                            terminals,
                            startTerminal,
                            destinationTerminal,
                            preferredRoute,
                            includeReturnDirection,
                            viaTerminalsData,
                            notViaTerminalsData
                        );
                    }
                }
            } else {
                existingRoutes = addRoutes(
                    existingRoutes,
                    terminals,
                    startTerminal,
                    destinationTerminal,
                    preferredRoute,
                    includeReturnDirection,
                    viaTerminalsData,
                    notViaTerminalsData
                );
            }

            // console.log('existingRoutes', existingRoutes);
            // return false;
            upsertBooklet({
                variables: {
                    id: props.booklet_id,
                    input: {
                        routes: JSON.stringify(existingRoutes),
                    },
                },
                onError: (networkError) => {
                    const message =
                        networkError instanceof Error
                            ? networkError.message
                            : t('generics.unexpectedError');
                    setError(message);
                },
                onCompleted: (_, errors) => {
                    if (errors?.length) {
                        const [mutationError] = errors;

                        if (mutationError.extensions.validation) {
                            console.log(
                                'mutationError.extensions.validation',
                                mutationError.extensions.validation
                            );
                        } else {
                            setError(mutationError.message ?? t('generics.unexpectedError'));
                        }
                    } else {
                        props.onClose();
                        props.reloadData();
                    }
                },
            });
        }
    };

    const handleRemoveViaTerminal = (index) => {
        const updatedViaTerminals = [...viaTerminals];
        updatedViaTerminals.splice(index, 1);
        setViaTerminals(updatedViaTerminals);
    };

    const handleRemoveNotViaTerminal = (index) => {
        const updatedNotViaTerminals = [...notViaTerminals];
        updatedNotViaTerminals.splice(index, 1);
        setNotViaTerminals(updatedNotViaTerminals);
    };

    return (
        <>
            {error && (
                <Dialog
                    title={t('generics.error')}
                    message={error}
                    type={DialogType.ERROR}
                    onClose={() => setError('')}
                />
            )}
            <div data-testid='UpsertRoute'>
                <div className={styles.ContentSection}>
                    <div className={styles.FormSection}>
                        <div>
                            <h3 className={styles.HeadingGroup}>{t('connections.columnStart')}</h3>
                            <div className={styles.FormDivGroup}>
                                <Select
                                    value={startCountry}
                                    onChange={(value) => {
                                        setStartCountry(value ?? '');
                                        setStartTerminal('');
                                    }}
                                    label={t('connections.land')}
                                    placeholder={t('generics.pleaseSelect')}
                                    {...props}
                                    clearable={false}
                                    options={generateCountryOptions(props.countries)}
                                />
                            </div>

                            <div className={styles.FormDivGroup}>
                                <Select
                                    value={startTerminal}
                                    onChange={(value) => {
                                        setStartTerminal(value);
                                        setStartTerminalError('');
                                        setDestinationTerminalError('');
                                    }}
                                    label={t('connections.terminal')}
                                    placeholder={t('connections.selectTerminal')}
                                    {...props}
                                    options={generateTerminalOptions(filteredStartTerminal)}
                                    noOptionsMessage={() => t('generics.noOptionsAvailable')}
                                    error={startTerminalError}
                                    clearable={false}
                                />
                            </div>

                            <div className={styles.FormDivGroup}>
                                <CheckBox
                                    checked={includeReturnDirection}
                                    label={t('connections.includeReturnDirection')}
                                    onChange={(value) =>
                                        setIncludeReturnDirection(!includeReturnDirection)
                                    }
                                    readonly={false}
                                />
                            </div>
                        </div>

                        <div>
                            <h3 className={styles.HeadingGroup}>
                                {t('connections.columnDestination')}
                            </h3>
                            <div className={styles.FormDivGroup}>
                                <Select
                                    value={destinationCountry}
                                    onChange={(value) => {
                                        setDestinationCountry(value ?? '');
                                        setDestinationTerminal('');
                                    }}
                                    label={t('connections.land')}
                                    placeholder={t('generics.pleaseSelect')}
                                    {...props}
                                    options={generateCountryOptions(props.countries)}
                                    clearable={false}
                                />
                            </div>

                            <div className={styles.FormDivGroup}>
                                <Select
                                    value={destinationTerminal}
                                    onChange={(value) => {
                                        setDestinationTerminal(value);
                                        setStartTerminalError('');
                                        setDestinationTerminalError('');
                                    }}
                                    label={t('connections.terminal')}
                                    placeholder={t('connections.selectTerminal')}
                                    {...props}
                                    options={generateTerminalOptions(filteredDestinationTerminal)}
                                    noOptionsMessage={() => t('generics.noOptionsAvailable')}
                                    error={destinationTerminalError}
                                    clearable={false}
                                />
                            </div>
                        </div>

                        <div>
                            <h3 className={styles.HeadingGroup}>
                                {t('connections.preferredRoute')}
                            </h3>
                            <div className={`${styles.FormDivGroup} ${styles.PreferredRouteDiv}`}>
                                <RadioGroup
                                    name={'preferredRoute'}
                                    value={preferredRoute}
                                    onChange={updatePreferredRoute}
                                    options={[
                                        {
                                            label: t('connections.preferredRouteOptions.allRoutes'),
                                            value: 'allRoutes',
                                            default: true,
                                        },
                                        {
                                            label: t(
                                                'connections.preferredRouteOptions.directTrainServicesOnly'
                                            ),
                                            value: 'directTrainServicesOnly',
                                            isDisabled: false,
                                            disabledMessage: t(
                                                'connections.featureCurrentlyNotAvailable'
                                            ),
                                        },
                                        {
                                            label: t(
                                                'connections.preferredRouteOptions.specifiedRoute'
                                            ),
                                            value: 'specifiedRoute',
                                        },
                                    ]}
                                />
                            </div>
                            {preferredRoute === 'specifiedRoute' ? (
                                <>
                                    <div
                                        className={`${styles.FormDivGroup} ${styles.PreferredRouteDiv}`}
                                    >
                                        <label>
                                            <span className={styles.Label}>
                                                {t('connections.via')}
                                            </span>
                                        </label>
                                        {viaTerminals.map((terminal, index) => (
                                            <div
                                                className={styles.ViaNotViaTerminals}
                                                key={`viaTerminals-${index}`}
                                            >
                                                <Select
                                                    key={terminal.id}
                                                    value={terminal.value}
                                                    onChange={(value) =>
                                                        handleTerminalChange(
                                                            terminal.id,
                                                            value,
                                                            'via'
                                                        )
                                                    }
                                                    placeholder={t('connections.selectTerminal')}
                                                    {...props}
                                                    options={generateTerminalOptions(
                                                        props.terminals
                                                    )}
                                                    noOptionsMessage={() =>
                                                        t('generics.noOptionsAvailable')
                                                    }
                                                    clearable={false}
                                                />

                                                {index !== 0 ? (
                                                    <Button
                                                        icon={'cross'}
                                                        style={ButtonStyle.SECONDARY}
                                                        onClick={() =>
                                                            handleRemoveViaTerminal(index)
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        ))}
                                        {viaTerminals.length === 2 ? null : (
                                            <button
                                                type='button'
                                                className={styles.AddTerminalButton}
                                                onClick={() => handleAddTerminal('via')}
                                            >
                                                {t('connections.addTerminal')}
                                            </button>
                                        )}
                                    </div>

                                    <div
                                        className={`${styles.FormDivGroup} ${styles.PreferredRouteDiv}`}
                                    >
                                        <label>
                                            <span className={styles.Label}>
                                                {t('connections.notVia')}
                                            </span>
                                        </label>
                                        {notViaTerminals.map((terminal, index) => (
                                            <div
                                                className={styles.ViaNotViaTerminals}
                                                key={`notViaTerminals-${index}`}
                                            >
                                                <Select
                                                    key={terminal.id}
                                                    value={terminal.value}
                                                    onChange={(value) =>
                                                        handleTerminalChange(
                                                            terminal.id,
                                                            value,
                                                            'notVia'
                                                        )
                                                    }
                                                    placeholder={t('connections.selectTerminal')}
                                                    {...props}
                                                    options={generateTerminalOptions(
                                                        props.terminals
                                                    )}
                                                    noOptionsMessage={() =>
                                                        t('generics.noOptionsAvailable')
                                                    }
                                                    clearable={false}
                                                />
                                                {index !== 0 ? (
                                                    <Button
                                                        icon={'cross'}
                                                        style={ButtonStyle.SECONDARY}
                                                        onClick={() =>
                                                            handleRemoveNotViaTerminal(index)
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        ))}

                                        {notViaTerminals.length === 5 ? null : (
                                            <button
                                                type='button'
                                                className={styles.AddTerminalButton}
                                                onClick={() => handleAddTerminal('notVia')}
                                                disabled={notViaTerminals.length === 5}
                                            >
                                                {t('connections.addTerminal')}
                                            </button>
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>

                    <div className={styles.FooterSection}>
                        <Button
                            style={ButtonStyle.SECONDARY}
                            text={t('connections.cancel')}
                            onClick={props.onClose}
                        />

                        <Button
                            style={ButtonStyle.PRIMARY}
                            text={
                                props.routeToEdit
                                    ? t('connections.editRouteButton')
                                    : t('connections.addRouteButton')
                            }
                            onClick={submitForm}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const UpsertRoutePropTypes = {};

UpsertRoute.propTypes = UpsertRoutePropTypes;

export const UpsertRouteProp = PropTypes.shape(UpsertRoutePropTypes);
export default UpsertRoute;
