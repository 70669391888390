import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-relay';
import Button, { ButtonStyle } from '../Button/Button';
import { CheckmarkIcon } from '../Icon/Icon';
import { ModalBox } from '../Modal/Modal';
import hotlineImage from '../ServiceHotline/servicehotline.svg';
import styles from './TOTPWall.module.scss';

const STATES = {
    Setup: 0,
    Form: 1,
    Authorized: 2,
};

export const AuthenticateMutation = graphql`
    mutation TOTPWallMutation($input: AuthenticateViaTotpInput!) {
        authenticateViaTotp(input: $input)
    }
`;

const Setup = ({ qrCode, secret, onNext }) => {
    const { t } = useTranslation();

    return (
        <>
            <p className={styles.ContentDescription}>{t('userManagement.totpSetupDescription')}</p>
            <div className={styles.QRCodeContainer}>
                <span>{t('userManagement.totpQRCode')}</span>
                <img src={`data:image/png;base64, ${qrCode}`} alt='' height='180' width='180' />
                <span>{t('userManagement.totpSecret', { secret })}</span>
            </div>
            <span>{t('userManagement.totpAlreadySetup', { secret })}</span>
            <div className={styles.SetupButton}>
                <Button
                    style={ButtonStyle.PRIMARY}
                    onClick={() => onNext()}
                    text={t('userManagement.totpSetupButton')}
                    multiline
                />
            </div>
        </>
    );
};

const Authorized = ({ onClose }) => {
    const { t } = useTranslation();

    useEffect(() => {
        const timeoutId = setTimeout(onClose, 5000);
        return () => clearTimeout(timeoutId);
    }, [onClose]);

    return (
        <>
            <div className={styles.CheckMarkIconContainer}>
                <CheckmarkIcon className={styles.CheckMarkIcon} />
            </div>
            <p className={styles.FeedbackMessage}>{t('userManagement.totpAuthorized')}</p>
            <div className={styles.ButtonContainer}>
                <Button
                    style={ButtonStyle.SECONDARY}
                    text={t('generics.next')}
                    onClick={() => onClose()}
                    block
                />
            </div>
        </>
    );
};

const Form = ({ length, onAuthorized }) => {
    const { t } = useTranslation();
    const [chars, setChars] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const inputElsRef = useRef([]);
    const [error, setError] = useState('');
    const [authenticate, isLoading] = useMutation(AuthenticateMutation);

    const onChange = useCallback(
        (index, value) => {
            if (value.length) {
                const trimmedValue = value.trim();
                if (!trimmedValue || isNaN(trimmedValue)) return;
                if (!chars[index]) {
                    chars[index] = trimmedValue;
                    setChars([...chars]);
                    setActiveIndex(index + 1);
                } else {
                    chars.splice(index, 1, trimmedValue);
                    setChars([...chars]);
                    setActiveIndex(index + 1);
                }
            }
        },
        [chars]
    );

    const submit = useCallback(
        (token) => {
            authenticate({
                variables: {
                    input: { token },
                },
                onError: (networkError) => {
                    const message =
                        networkError instanceof Error
                            ? networkError.message
                            : t('generics.unexpectedError');
                    setError(message);
                },
                onCompleted: (response, errors) => {
                    if (errors?.length) {
                        const [mutationError] = errors;
                        if (mutationError.extensions.validation) {
                            setError(mutationError.extensions.validation['input.token'][0]);
                        } else {
                            setError(mutationError.message);
                        }
                    } else {
                        sessionStorage.setItem('totp_token', response.authenticateViaTotp);
                        onAuthorized();
                    }
                },
            });
        },
        [onAuthorized, authenticate, t]
    );

    useEffect(() => {
        if (chars.length === length) {
            inputElsRef.current.forEach((el) => el.blur());
            submit(chars.join(''));
        }
    }, [submit, chars, length, activeIndex]);

    useEffect(() => {
        inputElsRef.current[activeIndex]?.focus();
    }, [activeIndex]);

    return (
        <>
            <p className={styles.ContentDescription}>{t('userManagement.totpModalContent')}</p>
            <div className={styles.FormContainer}>
                <form
                    className={classNames({
                        [styles.PasswordForm]: true,
                        [styles.Error]: !!error,
                    })}
                >
                    {Array.from({ length: length }).map((_, idx) => (
                        <input
                            className={styles.Input}
                            ref={(el) => (inputElsRef.current[idx] = el)}
                            key={idx}
                            value={chars[idx] ?? ''}
                            onChange={() => undefined}
                            onKeyDown={({ key }) => {
                                if (!isNaN(key)) {
                                    onChange(idx, key);
                                } else if (key === 'Backspace') {
                                    chars.splice(chars[idx] ? idx : idx - 1, 1);
                                    setChars([...chars]);
                                    setActiveIndex(idx - 1);
                                } else if (key === 'Delete') {
                                    chars.splice(idx, 1);
                                    setChars([...chars]);
                                } else if (key === 'ArrowRight') {
                                    setActiveIndex(idx + 1);
                                } else if (key === 'ArrowLeft') {
                                    setActiveIndex(idx - 1);
                                }
                            }}
                            onClick={() => {
                                if (error) {
                                    setError('');
                                    setChars([]);
                                    setActiveIndex(0);
                                } else if (activeIndex !== idx) {
                                    setActiveIndex(Math.min(chars.length, idx));
                                }
                            }}
                            disabled={isLoading}
                        />
                    ))}
                </form>
                {error && <span className={styles.ErrorMessage}>{error}</span>}
            </div>
            <div className={styles.ResetHelper}>
                <img width='30' height='30' alt='' src={hotlineImage} />
                {t('userManagement.totpResetHelper')}
            </div>
        </>
    );
};

const TOTPWall = ({
    backgroundImg,
    passwordLength = 6,
    onSuccess,
    secret = null,
    qrCode = null,
    className = '',
}) => {
    const { t } = useTranslation();
    const [state, setState] = useState(secret ? STATES.Setup : STATES.Form);

    return (
        <div
            data-testid='TOTPWall'
            className={classNames({
                [styles.TOTPWall]: true,
                [className]: !!className,
            })}
            style={{ backgroundImage: `url("${backgroundImg}"` }}
        >
            <ModalBox title={t('userManagement.totpModalTitle')}>
                <div className={styles.ModalContent}>
                    {state === STATES.Setup && (
                        <Setup
                            qrCode={qrCode}
                            secret={secret}
                            onNext={() => setState(STATES.Form)}
                        />
                    )}
                    {state === STATES.Form && (
                        <Form
                            length={passwordLength}
                            onAuthorized={() => setState(STATES.Authorized)}
                        />
                    )}
                    {state === STATES.Authorized && <Authorized onClose={() => onSuccess()} />}
                </div>
            </ModalBox>
        </div>
    );
};

TOTPWall.propTypes = {
    backgroundImg: PropTypes.string.isRequired,
    passwordLength: PropTypes.number,
    className: PropTypes.string,
    secret: PropTypes.string,
    qrCode: PropTypes.string,
};
export default TOTPWall;
