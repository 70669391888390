import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from './Connections.module.scss';
import { useTranslation } from 'react-i18next';
import Table from '../Table/Table';
import PropTypes from 'prop-types';
import Button, { ButtonStyle } from '../Button/Button';
import emptyStateImage from './empty-state.png';
import Modal from '../Modal/Modal';
import UpsertRoute from './UpsertRoute/UpsertRoute';
import ModalsContext from '../../contexts/ModalsContext';
import useRouteTerminals from './UpsertRoute/useRouteTerminals';
import useRouteTerminalConnections from './UpsertRoute/useRouteTerminalConnections';
import Dialog from '../Dialog/Dialog';
import DialogType from '../Dialog/DialogType';
import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';
import ConfirmDeleteRouteDialog from './ConfirmDeleteRouteDialog';

export const ConnectionsRouteDeleteMutation = graphql`
    mutation ConnectionsRouteDeleteMutation($id: ID, $input: UpsertBookletRouteInput!) {
        upsertBookletRoute(id: $id, input: $input) {
            routes
        }
    }
`;

export function sortOptions(options: string[]): string[] {
    return options.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
    );
}

const Connections = (props) => {
    const { t } = useTranslation();
    const [routeToEdit, setRouteToEdit] = useState(undefined);
    const [filteredConnections, setFilteredConnections] = useState(
        props.bookletRoutes ? props.bookletRoutes : []
    );
    const [isDisabled, setIsDisabled] = useState(props.isConnectionAddDisabled);
    const [routeToUpsert, setRouteToUpsert] = useState(false);
    const { modal, closeModal } = useContext(ModalsContext);
    const [terminals] = useState(useRouteTerminals());
    const [terminalConnections] = useState(useRouteTerminalConnections());
    const [countries, setCountries] = useState([]);
    const [routeToDelete, setRouteToDelete] = useState(undefined);
    const [error, setError] = useState('');
    const [upsertBooklet] = useMutation(ConnectionsRouteDeleteMutation);

    useEffect(() => {
        if (terminals) {
            const uniqueCountries = new Map();

            terminals.forEach((terminal) => {
                uniqueCountries.set(terminal.countryID, {
                    internal_id: terminal.countryID,
                    name: terminal.countryName,
                });
            });
            setCountries(sortOptions(Array.from(uniqueCountries.values())));
        }
    }, [terminals]);

    useEffect(() => {
        setIsDisabled(props.isConnectionAddDisabled);
    }, [props.isConnectionAddDisabled]);

    useEffect(() => {
        if (terminals && props.bookletRoutes) {
            const bookletRoutes = mapBookletRoutes(
                props.bookletRoutes ? props.bookletRoutes : [],
                terminals
            );
            setFilteredConnections(bookletRoutes);
        }
    }, [props.bookletRoutes, terminals]);

    const closeUpsertRoute = () => {
        setRouteToUpsert(false);
        setRouteToEdit(undefined);
        closeModal();
    };
    const columns = useMemo(
        () => [
            {
                Header: t('connections.columnStart'),
                accessor: 'startTerminalName',
                sortable: true,
            },
            {
                Header: t('connections.columnDestination'),
                accessor: 'destinationTerminalName',
                sortable: true,
            },
            {
                Header: t('connections.columnCountry'),
                accessor: 'country',
                Cell: ({ row }) => (
                    <>
                        {row.original.startTerminalCountryName} -{' '}
                        {row.original.destinationTerminalCountryName}
                    </>
                ),
            },
            {
                Header: t('connections.columnViaOrNotVia'),
                accessor: 'via',
                Cell: ({ row }) => {
                    const notViaTerminalExist = row.original.notViaTerminals.some(
                        (terminal) => terminal.value
                    );
                    return (
                        <>
                            {row.original.viaTerminals.map((terminal, index) => {
                                return terminal.value ? (
                                    <span key={`via-${index}`}>
                                        {terminal.terminalName}
                                        {index < row.original.viaTerminals.length - 1 && ', '}
                                        {notViaTerminalExist &&
                                            index === row.original.viaTerminals.length - 1 &&
                                            ','}
                                    </span>
                                ) : null;
                            })}
                            {row.original.notViaTerminals.map((terminal, index) =>
                                terminal.value ? (
                                    <div key={`not-via-${index}`}>
                                        {index === 0 && ' ['}
                                        <span>
                                            {terminal.terminalName}
                                            {index < row.original.notViaTerminals.length - 1 &&
                                                ', '}
                                        </span>
                                        {index === row.original.notViaTerminals.length - 1 && '] '}
                                    </div>
                                ) : null
                            )}
                        </>
                    );
                },
            },
            {
                Header: t('connections.columnAction'),
                accessor: 'id',
                cellClass: styles.lastCell,
                Cell: ({ row }) => (
                    <div className={styles.lastCellActions}>
                        <Button
                            icon='edit'
                            style={ButtonStyle.SECONDARY}
                            tooltip={t('connections.editRoute')}
                            onClick={() => setRouteToEdit(row.original)}
                        />

                        <Button
                            icon='trash'
                            style={ButtonStyle.SECONDARY}
                            tooltip={t('connections.removeRoute')}
                            onClick={() => setRouteToDelete(row.original)}
                        />
                    </div>
                ),
            },
        ],
        [t]
    );
    const defaultSort = useMemo(
        () => [
            {
                id: 'start',
                desc: false,
            },
        ],
        []
    );

    const deleteBookletRoute = () => {
        if (routeToDelete && props.bookletRoutes) {
            let existingRoutes = props.bookletRoutes;
            const deleteIndex = existingRoutes.findIndex((route) => route.id === routeToDelete.id);
            if (deleteIndex !== -1) {
                existingRoutes.splice(deleteIndex, 1);
            }
            upsertBooklet({
                variables: {
                    id: props.booklet_id,
                    input: {
                        routes: JSON.stringify(existingRoutes),
                    },
                },
                onError: (networkError) => {
                    const message =
                        networkError instanceof Error
                            ? networkError.message
                            : t('generics.unexpectedError');
                    setError(message);
                },
                onCompleted: (_, errors) => {
                    if (errors?.length) {
                        const [mutationError] = errors;

                        if (mutationError.extensions.validation) {
                        } else {
                            setError(mutationError.message ?? t('generics.unexpectedError'));
                        }
                    } else {
                        setRouteToDelete(undefined);
                        props.reloadData();
                    }
                },
            });
        }
    };

    return (
        <>
            {routeToUpsert || routeToEdit ? (
                <div className={styles.ModalCustomWidth}>
                    <Modal
                        isOpen={true}
                        title={routeToEdit ? t('connections.editRoute') : t('connections.addRoute')}
                        size={modal?.size}
                        onClose={closeUpsertRoute}
                    >
                        <UpsertRoute
                            onClose={closeUpsertRoute}
                            terminals={terminals}
                            terminalConnections={terminalConnections}
                            countries={countries}
                            bookletRoutes={props.bookletRoutes}
                            booklet_id={props.booklet_id}
                            routeToEdit={routeToEdit}
                            reloadData={props.reloadData}
                        />
                    </Modal>
                </div>
            ) : null}

            {routeToDelete && (
                <ConfirmDeleteRouteDialog
                    onCancel={() => setRouteToDelete(undefined)}
                    onConfirm={() => {
                        deleteBookletRoute();
                    }}
                    routeToDelete={routeToDelete}
                />
            )}

            {error && (
                <Dialog
                    title={t('generics.error')}
                    message={error}
                    type={DialogType.ERROR}
                    onClose={() => setError('')}
                />
            )}

            <div className={styles.TopSection}>
                <div className={styles.SectionCreate}>
                    <div>
                        <h3 className={styles.Heading}>
                            {t('connections.routes')} ({filteredConnections.length})
                        </h3>
                    </div>
                    <div className={styles.CreateButtonSection}>
                        <Button
                            icon='add'
                            style={ButtonStyle.SECONDARY}
                            text={t('connections.addRoutes')}
                            tooltip={
                                isDisabled
                                    ? t('connections.pleaseSaveTheBookletToAddConnections')
                                    : ''
                            }
                            onClick={() => {
                                setRouteToUpsert(true);
                            }}
                            disabled={isDisabled}
                        />
                    </div>
                </div>
                <div className={styles.TableContainer}>
                    <Table
                        columns={columns}
                        data={filteredConnections}
                        defaultSort={defaultSort}
                        emptyState={
                            <div>
                                <img src={emptyStateImage} alt={''} />
                                <div className={styles.EmptyHeadline}>
                                    {t('connections.emptyStateHeadline')}
                                </div>
                                <div className={styles.EmptySubHeadline}>
                                    {t('connections.emptyStateSubHeadline')}
                                </div>
                            </div>
                        }
                    />
                </div>
            </div>
        </>
    );
};

Connections.propTypes = {
    booklet_id: PropTypes.number,
    isConnectionAddDisabled: PropTypes.bool,
    bookletRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            startTerminal: PropTypes.number.isRequired,
            destinationTerminal: PropTypes.number.isRequired,
            includeReturnDirection: PropTypes.bool,
            preferredRoute: PropTypes.string,
            viaTerminals: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    value: PropTypes.number,
                })
            ),
            notViaTerminals: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    value: PropTypes.number,
                })
            ),
        })
    ),
    connections: PropTypes.array,
};

export const mapBookletRoutes = (routes, terminals) => {
    const terminalsById = {};
    terminals.forEach((terminal) => {
        terminalsById[terminal.internal_id] = terminal;
    });

    return routes.map((route) => {
        const startTerminalDetails = terminalsById[route.startTerminal];
        const destinationTerminalDetails = terminalsById[route.destinationTerminal];
        return {
            ...route,
            startTerminalName: startTerminalDetails?.name ?? '',
            startTerminalCountryName: startTerminalDetails?.countryName ?? '',
            destinationTerminalName: destinationTerminalDetails?.name ?? '',
            destinationTerminalCountryName: destinationTerminalDetails?.countryName ?? '',
            viaTerminals: route.viaTerminals.map((viaTerminal) => {
                const viaTerminalDetails = terminalsById[viaTerminal.value];
                return {
                    ...viaTerminal,
                    terminalName: viaTerminalDetails?.name ?? '',
                    countryName: viaTerminalDetails?.countryName ?? '',
                };
            }),
            notViaTerminals: route.notViaTerminals.map((notViaTerminal) => {
                const notViaTerminalDetails = terminalsById[notViaTerminal.value];
                return {
                    ...notViaTerminal,
                    terminalName: notViaTerminalDetails?.name ?? '',
                    countryName: notViaTerminalDetails?.countryName ?? '',
                };
            }),
        };
    });
};

export default Connections;
