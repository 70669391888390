import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CheckmarkIcon } from '../Icon/Icon';
import styles from './Steps.module.scss';

const Steps = ({ labels, activeStep = 0 }) => {
    return (
        <div
            data-testid='Steps'
            className={classNames({
                [styles.Steps]: true,
                [styles.StepsTwo]: labels.length === 2,
                [styles.StepsThree]: labels.length === 3,
            })}
        >
            {labels.map((label, idx) => (
                <div
                    key={label}
                    className={classNames({
                        [styles.Item]: true,
                        [styles.ItemActive]: idx === activeStep,
                        [styles.ItemReady]: idx < activeStep,
                    })}
                >
                    <div className={styles.IconLane}>
                        <div className={styles.Icon}>
                            {idx < activeStep ? (
                                <CheckmarkIcon className={styles.ReadyIcon} />
                            ) : (
                                idx + 1
                            )}
                        </div>
                    </div>
                    {label}
                </div>
            ))}
        </div>
    );
};

Steps.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeStep: PropTypes.number,
};
export default Steps;
