import React from 'react';

const useModalsContext = () => {
    const [modals, setModals] = React.useState([]);

    const showModal = (title, content, position = 'back', size = 'default') => {
        const newModal = { title, content, size };
        switch (position) {
            case 'back':
                setModals([...modals, newModal]);
                break;
            case 'front': {
                const [currentModal, ...otherModals] = modals;
                setModals([currentModal, newModal, ...otherModals]);
                break;
            }
            default:
                return;
        }
    };

    const hideCurrentModal = () => {
        const [, ...newModals] = modals;
        setModals(newModals);
    };

    return {
        modal: modals[0],
        closeModal: hideCurrentModal,
        showModal,
    };
};

export default useModalsContext;
