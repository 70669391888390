import React from 'react';
import PropTypes from 'prop-types';
import { AuthError } from '../Auth/Error';
import { useLocation } from 'react-router-dom';

const decorateWithHooks = (Component) => {
    return (props) => {
        const location = useLocation();
        return <Component location={location} {...props} />;
    };
};

class SupportBarButtonErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        if (!(error instanceof AuthError)) {
            return { hasError: true };
        }
        throw error;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.key !== prevProps.location.key) {
            this.setState({ hasError: false });
        }
    }

    render() {
        return this.state.hasError ? null : this.props.children;
    }
}

SupportBarButtonErrorBoundary.propTypes = {
    location: PropTypes.shape({
        key: PropTypes.string.isRequired,
    }).isRequired,
};

export default decorateWithHooks(SupportBarButtonErrorBoundary);
