import React, { Suspense, useContext, useEffect } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { isMobile } from 'react-device-detect';

import AppLoadingIndicator from '../components/AppLoadingIndicator/AppLoadingIndicator';

import { getCookie } from '../helpers/cookie-manager';
import { viewType } from '../constants';

export const Features = {
    MESSAGES: 'MESSAGES',
    DOWNLOADS: 'DOWNLOADS',
    PAGES: 'PAGES',
    ORDERS: 'ORDERS',
    TIMETABLE: 'TIMETABLE',
    ONLINE_BOOKING: 'ONLINE_BOOKING',
    BOOKLETS: 'BOOKLETS',
    USER_MANAGEMENT: 'USER_MANAGEMENT',
};

export const AppQuery = graphql`
    query AppConfigContext_AppQuery($mobile: Boolean!) {
        app(mobile: $mobile) {
            features {
                feature
                label
                restricted
                defaultOptions {
                    key
                    value
                }
            }
            navigation {
                label
                icon
                tooltip {
                    icon
                    message
                }
                feature
                featureOptions {
                    key
                    value
                }
                children {
                    label
                    icon
                    tooltip {
                        icon
                        message
                    }
                    feature
                    featureOptions {
                        key
                        value
                    }
                }
            }
            navigationBottom {
                label
                icon
                tooltip {
                    icon
                    message
                }
                feature
                featureOptions {
                    key
                    value
                }
                children {
                    label
                    icon
                    tooltip {
                        icon
                        message
                    }
                    feature
                    featureOptions {
                        key
                        value
                    }
                }
            }
            options {
                transport_frequencies {
                    value
                    label
                }
                transport_unit_amounts {
                    value
                    label
                }
                transport_unit_types {
                    value
                    label
                }
                transport_weights {
                    value
                    label
                }
            }
        }
    }
`;

const AppQueryLoader = ({ children }) => {
    var selectedViewType = getCookie(viewType.COOKIE_NAME),
        // the user has selected the mobile view or the device is mobile
        isMobileView =
            selectedViewType === viewType.COOKIE_VALUE_MOBILE || (!selectedViewType && isMobile);

    const [appQueryRef, loadAppQuery] = useQueryLoader(AppQuery);

    useEffect(() => {
        loadAppQuery({ mobile: isMobileView });
    }, [loadAppQuery, isMobileView]);

    return (
        <Suspense fallback={<AppLoadingIndicator />}>
            {appQueryRef && (
                <ContextWrapper appQueryRef={appQueryRef} isMobileView={isMobileView}>
                    {children}
                </ContextWrapper>
            )}
        </Suspense>
    );
};

const ContextWrapper = ({ appQueryRef, isMobileView, children }) => {
    const { app } = usePreloadedQuery(AppQuery, appQueryRef);

    const contextValue = {
        features: app.features.reduce(
            (result, { feature, ...rest }) => ({
                ...result,
                [feature]: {
                    ...rest,
                    getOption(optionKey) {
                        return (rest.defaultOptions || []).find(({ key }) => optionKey === key)
                            ?.value;
                    },
                },
            }),
            {}
        ),
        isMobileView: isMobileView,
        navigation: app.navigation,
        getNavigationItemByFeature: (featureId) =>
            app.navigation
                .reduce((items, item) => {
                    return [...items, ...(item.children ? item.children : [item])];
                }, [])
                .find(({ feature }) => feature === featureId),
        navigationBottom: app.navigationBottom,
        options: {
            transportFrequencies: app.options.transport_frequencies,
            transportUnitAmounts: app.options.transport_unit_amounts,
            transportUnitTypes: app.options.transport_unit_types,
            transportWeights: app.options.transport_weights,
        },
    };

    return <AppConfigContext.Provider value={contextValue}>{children}</AppConfigContext.Provider>;
};

export const AppConfigContext = React.createContext({
    features: {},
    isMobileView: false,
    navigation: [],
    navigationBottom: [],
    options: {
        transportFrequencies: [],
        transportUnitAmounts: [],
        transportUnitTypes: [],
        transportWeights: [],
    },
});

export const useAppConfigContext = () => {
    return useContext(AppConfigContext);
};

export default AppQueryLoader;
