import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '../Icon/Icon';
import styles from './Gallery.module.scss';

const GalleryItem = (props) => {
    const { selected, previous, direction } = props;

    let animationClass = 'Hidden';
    if (selected) {
        animationClass = direction === 'left' ? 'SlideInFromRight' : 'SlideInFromLeft';
    }
    if (previous) {
        animationClass = direction === 'left' ? 'SlideOutToLeft' : 'SlideOutToRight';
    }

    return (
        <div
            className={`${styles.GalleryItem} ${styles[animationClass]} ${
                selected ? styles.Selected : ''
            }`}
        >
            {props.children}
        </div>
    );
};

const Gallery = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [previousIndex, setPreviousIndex] = useState(null);
    const [animationDirection, setAnimationDirection] = useState(null);
    const count = React.Children.toArray(props.children).length;

    const swapImages = (oldIndex, newIndex, forceDirection) => {
        const direction = forceDirection || (oldIndex < newIndex ? 'left' : 'right');
        setPreviousIndex(oldIndex);
        setCurrentIndex(newIndex);
        setAnimationDirection(direction);
    };

    const showPrev = () => {
        const nextIndex = currentIndex === 0 ? count - 1 : currentIndex - 1;
        swapImages(currentIndex, nextIndex, 'right');
    };

    const showNext = () => {
        const nextIndex = currentIndex === count - 1 ? 0 : currentIndex + 1;
        swapImages(currentIndex, nextIndex, 'left');
    };

    const shouldPreloadImage = (idx) => {
        if (idx === previousIndex) return true; // animation for bullet navigation
        if (Math.abs(idx - currentIndex) <= 1) return true; // preload current and neighbor images
        if (currentIndex === 0 && idx === count - 1) return true; // preload last when the first is the current
        return false;
    };

    return (
        <div className={styles.Gallery} data-testid='Gallery'>
            <div className={styles.GalleryControllers}>
                {count > 1 && (
                    <>
                        <button
                            className={styles.Arrow}
                            data-direction='prev'
                            onClick={() => showPrev()}
                        >
                            <ArrowLeftIcon />
                        </button>
                        <button
                            className={styles.Arrow}
                            data-direction='next'
                            onClick={() => showNext()}
                        >
                            <ArrowRightIcon />
                        </button>
                    </>
                )}
                <nav className={styles.BulletsContainer}>
                    {React.Children.toArray(props.children).map((_, idx) => (
                        <button
                            key={idx}
                            onClick={() => swapImages(currentIndex, idx)}
                            disabled={idx === currentIndex}
                            className={styles.Bullet}
                        >
                            &bull;
                        </button>
                    ))}
                </nav>
            </div>
            {React.Children.map(props.children, (child, idx) => {
                if (!React.isValidElement(child)) return <></>;
                return (
                    <GalleryItem
                        key={idx}
                        selected={idx === currentIndex}
                        previous={idx === previousIndex}
                        direction={animationDirection}
                    >
                        {React.cloneElement(child, {
                            src: shouldPreloadImage(idx) ? child.props.src : '',
                        })}
                    </GalleryItem>
                );
            })}
        </div>
    );
};

Gallery.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Gallery;
