import { useTranslation } from 'react-i18next';
import { useVisibilityEffect } from './useVisibility';

export default function useDocumentTitle(title, pageRef) {
    const { t } = useTranslation();

    useVisibilityEffect(
        (isVisible) => {
            if (isVisible) {
                const documentTitleParts = [];
                if (title) documentTitleParts.push(title);
                documentTitleParts.push(t('generics.documentTitleSuffix'));
                document.title = documentTitleParts.join(' | ');
            }
        },
        [title, t],
        pageRef
    );
}
