import PropTypes from 'prop-types';
import DownloadsList from '../components/Downloads/Downloads.relay';
import DownloadsOverview from '../components/DownloadsOverview/DownloadsOverview.relay';
import { useLocation } from 'react-router-dom';

const DownloadsRoute = ({ params }) => {
    const location = useLocation();
    return location.pathname.startsWith('/downloads') ? (
        params.view === 'list' ? (
            <DownloadsList />
        ) : (
            <DownloadsOverview />
        )
    ) : (
        <></>
    );
};

DownloadsRoute.propTypes = {
    params: PropTypes.shape({
        view: PropTypes.oneOf(['list', 'overview']),
    }),
};

export default DownloadsRoute;
