/**
 * @generated SignedSource<<f1a0a8f07167c86e373a3a3864432550>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dest",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useRouteTerminalConnectionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TerminalConnection",
        "kind": "LinkedField",
        "name": "terminalConnections",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useRouteTerminalConnectionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TerminalConnection",
        "kind": "LinkedField",
        "name": "terminalConnections",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f6d2f48ddf577c0b913987e508f1f96",
    "id": null,
    "metadata": {},
    "name": "useRouteTerminalConnectionsQuery",
    "operationKind": "query",
    "text": "query useRouteTerminalConnectionsQuery {\n  terminalConnections {\n    start\n    dest\n    id\n  }\n}\n"
  }
};
})();

node.hash = "a9c4a7d1e83833d8ba6e90dbb371604c";

module.exports = node;
