import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDynamicNavigate from '../../hooks/useDynamicNavigate';
import Button, { ButtonStyle } from '../Button/Button';
import styles from './Tile.module.scss';

export const TilesContainer = ({ children }) => (
    <div data-testid='TilesContainer' className={styles.TilesContainer}>
        {children}
    </div>
);

const Tile = ({ headline, image = '', text, link = '' }) => {
    const { t } = useTranslation();
    const navigate = useDynamicNavigate();
    return (
        <section className={styles.Tile} data-testid='Tile'>
            <div className={styles.ImageContainer}>{image && <img src={image} alt='' />}</div>
            <div className={styles.TileContent}>
                <h1>{headline}</h1>
                <p>{text}</p>
                {link && (
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('generics.moreInformation')}
                        onClick={() => navigate(link)}
                    />
                )}
            </div>
        </section>
    );
};

Tile.propTypes = {
    headline: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string,
    link: PropTypes.string,
};

export default Tile;
