import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import fetchGraphQL from './fetchGraphQL';

const fetchMockGraphQL = async (...params) => {
    const mockFunction = (await import('./fetchMockGraphQL')).default;
    return mockFunction(...params);
};

const fetchFunction =
    process.env.REACT_APP_USE_MOCK_BACKEND === '1' ? fetchMockGraphQL : fetchGraphQL;

async function fetchRelay(params, variables) {
    if (params.name !== 'UserContext_UserQuery') {
        window.sessionStorage.setItem('lastAction', Date.now().toString());
    }
    return fetchFunction(params.text, variables);
}

const relayEnvironment = new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource(null), null),
});

export default relayEnvironment;
