import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from '../Tag/Tag';
import styles from './Row.module.scss';

export const RowColor = {
    YELLOW: 'yellow',
    BLUE: 'blue',
    RED: 'red',
};

const Row = ({
    isMobileView = false,
    active = false,
    multiline = false,
    meta = '',
    tags = [],
    ...props
}) => {
    return (
        <div
            data-testid='Row'
            className={classNames(styles.Row, {
                [styles.RowClickable]: props.onClick !== null,
                [styles.RowYellow]: props.color === RowColor.YELLOW,
                [styles.RowBlue]: props.color === RowColor.BLUE,
                [styles.RowRed]: props.color === RowColor.RED,
                [styles.RowSingleLine]: !multiline,
            })}
            onClick={props.onClick}
        >
            <div className={styles.RowStatusColumn}>
                {active && <span className={styles.RowStatus} />}
            </div>
            <div className={styles.MainColumn} title={!multiline ? props.title : ''}>
                <div className={styles.RowTitle} title={props.title}>
                    {props.title}
                </div>
                {meta && <div className={styles.RowMeta}>{meta}</div>}
            </div>
            <div className={styles.TagsColumn}>
                {tags.map(({ name, id }, idx) => (
                    <Tag
                        key={idx}
                        isMobileView={isMobileView}
                        className={styles.Tag}
                        text={name}
                        onClick={
                            props.onTagClick &&
                            (() => {
                                props.onTagClick(id);
                            })
                        }
                    />
                ))}
            </div>
            {props.icon && <div className={styles.IconColumn}>{props.icon}</div>}
        </div>
    );
};

Row.propTypes = {
    title: PropTypes.string.isRequired,
    isMobileView: PropTypes.bool,
    meta: PropTypes.string,
    active: PropTypes.bool,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    icon: PropTypes.node,
    onClick: PropTypes.func,
    onTagClick: PropTypes.func,
    color: PropTypes.oneOf(Object.values(RowColor)),
    multiline: PropTypes.bool,
};

export default Row;
