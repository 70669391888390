import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DownloadItem, { DownloadItemProps } from './DownloadItem';
import { DownloadsIcon } from '../Icon/Icon';
import { useTranslation } from 'react-i18next';
import styles from './DownloadsList.module.scss';

const Placeholder = () => {
    const { t } = useTranslation();
    return (
        <>
            <DownloadsIcon className={styles.EmptyIcon} />
            <p className={styles.EmptyWarningTitle}>{t('generics.notAvailable')}</p>
            <p className={styles.EmptyWarningDetails}>{t('generics.willBeDisplayedIfAvailable')}</p>
        </>
    );
};

const DownloadsList = ({ downloads = [], metaField }) => {
    const hasItems = !!downloads.length;
    return (
        <div data-testid='DownloadsList' className={classNames({ [styles.EmptyList]: !hasItems })}>
            {hasItems ? (
                downloads.map((download) => (
                    <DownloadItem key={download.id} metaField={metaField} {...download} />
                ))
            ) : (
                <Placeholder />
            )}
        </div>
    );
};

DownloadsList.propTypes = {
    downloads: PropTypes.arrayOf(DownloadItemProps),
    metaField: PropTypes.string,
};

export default DownloadsList;
