import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { setCookie } from '../../helpers/cookie-manager';
import { viewType } from '../../constants';

import styles from './FooterDesktop.module.scss';

const FooterDesktop = ({ className = '' }) => {
    const { t } = useTranslation();

    return (
        <div className={`${styles.FooterDesktop} ${className}`} data-testid='FooterDesktop'>
            <span>
                © {t('footer.companyName')} – {t('footer.websiteLinkPrefix')}
                {' '}
            </span>
            <a href={t('footer.websiteUrl')} target='_blank' rel='noreferrer'>
                {t('footer.websiteLink')}
            </a>
            <span> / </span>
            <a href={t('footer.privacyPolicyUrl')} target='_blank' rel='noreferrer'>
                {t('footer.privacyPolicyLink')}
            </a>
            <span> / </span>
            <a href={t('footer.imprintUrl')} target='_blank' rel='noreferrer'>
                {t('footer.imprintLink')}
            </a>
            <span> / </span>
            <button
                className={`${styles.FooterDesktopLink}`}
                onClick={() => {
                    setCookie(viewType.COOKIE_NAME, viewType.COOKIE_VALUE_MOBILE);
                    window.location.reload();
                }}
            >
                {t('footer.viewTypeMobileLink')}
            </button>
        </div>
    );
};

FooterDesktop.propTypes = {
    className: PropTypes.string,
};

export default FooterDesktop;
