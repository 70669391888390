import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmailServicesList.module.scss';

import { EnabledIcon, DisabledIcon, InfoIcon } from '../Icon/Icon';
import classNames from 'classnames';

const EmailServicesListItem = ({ isEnabled, text, info, small }) => (
    <li
        className={classNames({
            [styles.EmailServicesListItem]: true,
            [styles.EmailServicesListItemSmall]: small,
        })}
    >
        <div className={styles.IconWrapper}>
            {isEnabled ? (
                <EnabledIcon className={styles.EnabledIcon} />
            ) : (
                <DisabledIcon className={styles.DisabledIcon} />
            )}
        </div>
        <span
            className={classNames({
                [styles.EmailServiceListItemText]: !small && info && info.length > 0,
            })}
        >
            {text}
        </span>
        {!small && info && info.length > 0 ? (
            <div className={styles.EmailServiceGroupIcon}>
                <InfoIcon />
                <div className={styles.EmailServiceTooltipWrapper}>
                    <div className={styles.EmailServiceTooltip}>{info}</div>
                </div>
            </div>
        ) : null}
    </li>
);

const EmailServicesList = ({
    small = false,
    info = '',
    className = '',
    emailServices = [],
    emailServicesConfig = [],
}) => {
    return (
        <ul
            className={[styles.EmailServicesList, className].join(' ').trim()}
            data-testid='EmailServicesList'
        >
            {emailServicesConfig.map((group, groupIndex) =>
                group.display ? (
                    <div key={groupIndex} className={styles.EmailServiceGroup}>
                        <div className={styles.EmailServiceGroupLabel}>
                            <div className={styles.EmailServiceGroupName}>{group.name}</div>
                            <div className={styles.EmailServiceGroupIcon}>
                                <InfoIcon />
                                <div className={styles.EmailServiceTooltipWrapper}>
                                    <div className={styles.EmailServiceTooltip}>
                                        {group.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.EmailServiceGroupServices}>
                            {group.services.map((service) => (
                                <EmailServicesListItem
                                    key={service.emailServiceId}
                                    isEnabled={
                                        emailServices.find(
                                            ({ emailServiceId }) =>
                                                emailServiceId === service.emailServiceId
                                        )?.isEnabled || false
                                    }
                                    text={service.name.length < 1 ? 'Unknown' : service.name}
                                    info={service.description}
                                    small={true}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div key={groupIndex} className={styles.EmailServiceGroup}>
                        {group.services.map((service) => (
                            <EmailServicesListItem
                                key={service.emailServiceId}
                                isEnabled={
                                    emailServices.find(
                                        ({ emailServiceId }) =>
                                            emailServiceId === service.emailServiceId
                                    )?.isEnabled || false
                                }
                                text={service.name.length < 1 ? 'Unknown' : service.name}
                                info={service.description}
                            />
                        ))}
                    </div>
                )
            )}
        </ul>
    );
};

EmailServicesList.propTypes = {
    info: PropTypes.string,
    small: PropTypes.bool,
    className: PropTypes.string,
    emailServices: PropTypes.arrayOf(
        PropTypes.shape({
            emailServiceId: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
        })
    ),
    emailServicesConfig: PropTypes.arrayOf(
        PropTypes.shape({
            display: PropTypes.bool,
            name: PropTypes.string,
            description: PropTypes.string,
            services: PropTypes.arrayOf(
                PropTypes.shape({
                    emailServiceId: PropTypes.string,
                    name: PropTypes.string,
                    description: PropTypes.string,
                })
            ),
        })
    ),
};

export default EmailServicesList;
