import { useEffect } from 'react';

function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
}

function dec2hex(dec) {
    return ('0' + dec.toString(16)).substr(-2);
}

function generateRandomString() {
    const array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join('');
}

async function challenge_from_verifier(v) {
    const hashed = await sha256(v);
    return base64urlencode(hashed);
}

function base64urlencode(a) {
    let str = '';
    const bytes = new Uint8Array(a);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

const PKCEAuthCodeFirstStep = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const state = generateRandomString();
    const code_verifier = generateRandomString();
    const code_challenge = await challenge_from_verifier(code_verifier);
    const authorize_url = new URL(
        (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) + '/oauth/authorize'
    );

    authorize_url.searchParams.set('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID);
    authorize_url.searchParams.set('redirect_uri', process.env.REACT_APP_URL);
    authorize_url.searchParams.set('response_type', 'code');
    authorize_url.searchParams.set('scope', '');
    authorize_url.searchParams.set('state', state);
    authorize_url.searchParams.set('code_challenge', code_challenge);
    authorize_url.searchParams.set('code_challenge_method', 'S256');

    localStorage.setItem('code_verifier', code_verifier);

    return authorize_url;
};

const getAccessToken = (code, code_verifier) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return fetch((apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) + '/oauth/token', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_URL,
            code_verifier: code_verifier,
            code: code,
        }),
    }).then((response) => {
        if (response.status === 200) {
            return response.json().then((data) => {
                return data;
            });
        }
        return response.json().then((data) => {
            const error = new Error(data.message + data.hint);
            error.name = data.error;
            throw error;
        });
    });
};

const Auth = ({ setAccessToken }) => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (!params.has('code')) {
            PKCEAuthCodeFirstStep().then((result) => {
                if (process.env.REACT_APP_USE_MOCK_BACKEND === '1') {
                    // console.debug('[DEV Environment] - Auth skipped');
                    setAccessToken('dummy-dev-token');
                    return;
                }
                localStorage.setItem('lastKnownUrl', window.location.href);
                window.location.replace(result);
            });
        } else {
            getAccessToken(params.get('code'), localStorage.getItem('code_verifier'))
                .then((access_token) => {
                    const accessToken = JSON.stringify(access_token);
                    setAccessToken(accessToken);
                })
                .finally(() => {
                    const lastKnownUrl = localStorage.getItem('lastKnownUrl');
                    if (lastKnownUrl) {
                        localStorage.removeItem('lastKnownUrl');
                    }
                    window.location.replace(lastKnownUrl ?? window.location.pathname);
                });
        }
    }, [setAccessToken]);

    return <>Authenticating...</>;
};

export default Auth;
