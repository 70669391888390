import React, { useCallback } from 'react';

const useTagsContext = () => {
    const [tags, setTagsState] = React.useState([]);

    const setTags = useCallback((newTags) => {
        setTagsState((oldTags) => {
            if (
                newTags.length !== oldTags.length ||
                newTags.some((newTag) => !oldTags.includes(newTag))
            ) {
                return newTags;
            }
            return oldTags;
        });
    }, []);

    const clearTags = useCallback(() => {
        setTagsState([]);
    }, []);

    return {
        tags,
        setTags,
        clearTags,
    };
};

export default useTagsContext;
