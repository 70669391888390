import PropTypes from 'prop-types';
import { ErrorIcon } from '../Icon/Icon';
import styles from './Field.module.scss';

export const ErrorIndicator = ({ className = '' }) => (
    <div className={`${styles.ErrorIndicator} ${className}`}>
        <ErrorIcon className={styles.ErrorIcon} />
        <span className={styles.ErrorArrow} />
    </div>
);

const Field = ({
    block = false,
    className = '',
    error = '',
    label = undefined,
    required = false,
    ...props
}) => {
    const fieldClass =
        styles.Field +
        (block ? ' ' + styles.FieldAsBlock : '') +
        (className ? ' ' + className : '');
    return (
        <label className={fieldClass}>
            <span className={`${styles.Label} ${required ? styles.RequiredLabel : ''}`}>
                {label}
            </span>
            <div className={styles.ControlContainer} data-testid={props['data-testid']}>
                {props.children}
                {error && <div className={styles.ErrorMessage}>{error}</div>}
            </div>
        </label>
    );
};

export const FieldPropTypes = {
    block: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
    required: PropTypes.bool,
};

Field.propTypes = {
    ...FieldPropTypes,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Field;
