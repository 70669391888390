/**
 * @generated SignedSource<<819d62d114ea359522ed62521a0ca5fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "updateEmailServices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailService",
        "kind": "LinkedField",
        "name": "emailServices",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailServiceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailServicesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailServicesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ae755ed444713a296b610c95b554a05b",
    "id": null,
    "metadata": {},
    "name": "EmailServicesMutation",
    "operationKind": "mutation",
    "text": "mutation EmailServicesMutation(\n  $input: UpdateEmailServicesInput!\n) {\n  updateEmailServices(input: $input) {\n    id\n    emailServices {\n      emailServiceId\n      isEnabled\n    }\n  }\n}\n"
  }
};
})();

node.hash = "7c860a8943bf328fd18423bfe5888088";

module.exports = node;
