import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import { EmailIcon, PhoneIcon, SmartphoneIcon } from '../../Icon/Icon';
import Flag from '../../Flag/Flag';
import Tag from '../../Tag/Tag';
import TagsContext from '../../../contexts/TagsContext';

import stylesBookletExport from './BookletExport.module.scss';
import styles from '../../Contact/Contact.module.scss';
import Button from '../../Button/Button';
import DialogType from '../../Dialog/DialogType';
import Dialog from '../../Dialog/Dialog';

export function encodeExportBooklet(booklet) {
    const CODE_TEMPLATE = 'TVLUDFZNEG';
    let chksum = '';
    const template = CODE_TEMPLATE;

    booklet = booklet * 4;
    booklet = booklet.toString();
    const nbs = booklet.split('');

    nbs.forEach((nb) => {
        chksum += template[parseInt(nb, 10)];
    });

    return chksum;
}

const BookletExport = ({ isMobileView = false, tags: propTag = [], languages = [], ...props }) => {
    const [isMouseOverBox1, setIsMouseOverBox1] = useState(false);
    const [isMouseOverBox2, setIsMouseOverBox2] = useState(false);
    const [isMouseOverBox3, setIsMouseOverBox3] = useState(false);
    const [isMouseOverBox4, setIsMouseOverBox4] = useState(false);
    const [textCopied, setTextCopied] = useState(false);

    const { tags } = useContext(TagsContext);

    const avatarUrl = props.avatarUrl ?? (props.type === 'TEAM' ? '/team.svg' : '/avatar.svg');
    const apiUrl = process.env.REACT_APP_API_URL;
    const encodeExportBookletID = encodeExportBooklet(props.internalId);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                setTextCopied(true);
            },
            (err) => {
                console.log('Could not copy text: ', err);
            }
        );
    };

    useEffect(() => {
        if (textCopied) {
            const timer = setTimeout(() => {
                setTextCopied(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [textCopied]);

    return (
        <>
            {textCopied ? (
                <Dialog
                    type={DialogType.INFO}
                    title={t('booklet.textCopiedTitle')}
                    message={t('booklet.textCopied')}
                    onClose={() => setTextCopied(false)}
                />
            ) : null}
            <div
                className={classNames({
                    [styles.Contact]: true,
                    [styles.ContactMobile]: isMobileView,
                })}
                data-testid='Contact'
            >
                <div className={stylesBookletExport.ContentSection}>
                    <p className={stylesBookletExport.ContentText}>
                        {t('bookletExport.exportContent1')}
                    </p>
                    <br />
                    {props.name ? (
                        <p className={stylesBookletExport.ContentText}>
                            {t('bookletExport.exportContent2')} {props.name}
                        </p>
                    ) : null}
                </div>
                {props.name && props.email ? (
                    <div className={stylesBookletExport.ContactSummary}>
                        <img
                            alt={props.name}
                            className={stylesBookletExport.Image}
                            src={avatarUrl}
                        />
                        <div className={stylesBookletExport.Text}>
                            <div className={stylesBookletExport.ContactDetail}>
                                <div>
                                    <div className={styles.Name}>{props.name}</div>
                                    {props.position && (
                                        <div className={styles.Role}>{props.position}</div>
                                    )}
                                </div>

                                <div>
                                    {props.phoneNumber && (
                                        <a
                                            href={`tel:${_toTelUrl(props.phoneNumber)}`}
                                            className={stylesBookletExport.ContactIcons}
                                        >
                                            <PhoneIcon className={styles.DetailIcon} />
                                            {props.phoneNumber}
                                        </a>
                                    )}
                                    {props.mobileNumber && (
                                        <a
                                            href={`tel:${_toTelUrl(props.mobileNumber)}`}
                                            className={stylesBookletExport.ContactIcons}
                                        >
                                            <SmartphoneIcon className={styles.DetailIcon} />
                                            {props.mobileNumber}
                                        </a>
                                    )}
                                    {props.email && (
                                        <div className={stylesBookletExport.ContactIcons}>
                                            <EmailIcon className={styles.DetailIcon} />
                                            <a
                                                href={`mailto:${props.email}`}
                                                className={stylesBookletExport.ContactAnchor}
                                            >
                                                {props.email}
                                            </a>
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <div className={stylesBookletExport.FlagIcon}>
                                        {languages.map((language) => (
                                            <Flag key={language} cc={language} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div>
                                {propTag
                                    .filter((tag) => tags.includes(tag))
                                    .map((tag, idx) => (
                                        <Tag key={idx} text={tag} className={styles.Tag} />
                                    ))}
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className={stylesBookletExport.ContentSection}>
                    <h3 className={stylesBookletExport.LinkTitle}>Links</h3>
                    <div className={stylesBookletExport.LinkSection}>
                        <div
                            className={stylesBookletExport.LinkBox}
                            onClick={() => {
                                copyToClipboard(
                                    (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                        '/export/' +
                                        encodeExportBookletID +
                                        '?output=pdf_book_regular'
                                );
                                setTextCopied(true);
                            }}
                            onMouseEnter={() => setIsMouseOverBox1(true)}
                            onMouseLeave={() => setIsMouseOverBox1(false)}
                        >
                            <h2 className={stylesBookletExport.LinkHeading}>
                                {t('bookletExport.pdfRegularTimetableWithWeeklyPattern')}
                            </h2>
                            <div className={stylesBookletExport.Link}>
                                {(apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                    '/export/' +
                                    encodeExportBookletID +
                                    '?output=pdf_book_regular'}
                                <Button
                                    icon='news'
                                    tooltip={t('bookletExport.copyLinkToClipboard')}
                                    extraClasses={stylesBookletExport.ExportCopyButton}
                                    isMouseOver={isMouseOverBox1}
                                />
                            </div>
                        </div>
                        <div
                            className={stylesBookletExport.LinkBox}
                            onClick={() =>
                                copyToClipboard(
                                    (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                        '/export/' +
                                        encodeExportBookletID +
                                        '?output=pdf_book'
                                )
                            }
                            onMouseEnter={() => setIsMouseOverBox2(true)}
                            onMouseLeave={() => setIsMouseOverBox2(false)}
                        >
                            <h2 className={stylesBookletExport.LinkHeading}>
                                {t('bookletExport.pdfTimetableWithDailyUpdatedInformation')}
                            </h2>
                            <div className={stylesBookletExport.Link}>
                                {(apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                    '/export/' +
                                    encodeExportBookletID +
                                    '?output=pdf_book'}
                                {isMouseOverBox1}
                                <Button
                                    icon='news'
                                    tooltip={t('bookletExport.copyLinkToClipboard')}
                                    extraClasses={stylesBookletExport.ExportCopyButton}
                                    isMouseOver={isMouseOverBox2}
                                />
                            </div>
                        </div>
                        <div
                            className={stylesBookletExport.LinkBox}
                            onClick={() =>
                                copyToClipboard(
                                    (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                        '/export/' +
                                        encodeExportBookletID +
                                        '?output=pdf_overview'
                                )
                            }
                            onMouseEnter={() => setIsMouseOverBox3(true)}
                            onMouseLeave={() => setIsMouseOverBox3(false)}
                        >
                            <h2 className={stylesBookletExport.LinkHeading}>
                                {t('bookletExport.pdfDailyOverview')}
                            </h2>
                            <div className={stylesBookletExport.Link}>
                                {(apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                    '/export/' +
                                    encodeExportBookletID +
                                    '?output=pdf_overview'}
                                <Button
                                    icon='news'
                                    tooltip={t('bookletExport.copyLinkToClipboard')}
                                    extraClasses={stylesBookletExport.ExportCopyButton}
                                    isMouseOver={isMouseOverBox3}
                                />
                            </div>
                        </div>

                        <div
                            className={`${stylesBookletExport.LinkBox} ${stylesBookletExport.LinkBoxLast}`}
                            onClick={() =>
                                copyToClipboard(
                                    (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                        '/export/' +
                                        encodeExportBookletID +
                                        '?output=xml'
                                )
                            }
                            onMouseEnter={() => setIsMouseOverBox4(true)}
                            onMouseLeave={() => setIsMouseOverBox4(false)}
                        >
                            <h2 className={stylesBookletExport.LinkHeading}>XML</h2>
                            <div className={stylesBookletExport.Link}>
                                {(apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                    '/export/' +
                                    encodeExportBookletID +
                                    '?output=xml'}
                                <Button
                                    icon='news'
                                    tooltip={t('bookletExport.copyLinkToClipboard')}
                                    extraClasses={stylesBookletExport.ExportCopyButton}
                                    isMouseOver={isMouseOverBox4}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const BookletExportPropTypes = {
    isMobileView: PropTypes.bool,
    type: PropTypes.string,
    email: PropTypes.string,
    avatarUrl: PropTypes.string,
    phoneNumber: PropTypes.string,
    mobileNumber: PropTypes.string,
    name: PropTypes.string.isRequired,
    position: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    languages: PropTypes.arrayOf(PropTypes.string),
};

BookletExport.propTypes = BookletExportPropTypes;

export const BookletExportProp = PropTypes.shape(BookletExportPropTypes);
export default BookletExport;

// transform string to tel format
// allow only + and nubers
function _toTelUrl(number) {
    return number.replace(/[^0-9+]/g, '');
}
