import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '../Icon/Icon';

import styles from './ContentHeaderMobile.module.scss';

const ContentHeaderMobile = ({ featureName = '', isFeatureInner = false, ...props }) => {
    const navigate = useNavigate();
    const backURL = _getBackURL(featureName, isFeatureInner);

    return (
        <div
            className={classNames({
                [props.className]: true,
                [styles.ContentHeaderMobile]: true,
                [styles.ContentHeaderMobileWithBackButton]: !!backURL,
            })}
            data-testid='ContentHeaderMobile'
        >
            {!!backURL && (
                <div
                    className={styles.ContentHeaderMobileNavIconContainer}
                    onClick={(_) => {
                        navigate(backURL);
                    }}
                >
                    <ArrowLeftIcon className={styles.ContentHeaderMobileNavIcon} />
                </div>
            )}

            <div className={styles.ContentHeaderMobileTitle}>{props.title}</div>
        </div>
    );
};

ContentHeaderMobile.propTypes = {
    title: PropTypes.string,
    featureName: PropTypes.string,
    isFeatureInner: PropTypes.bool,
};

export default ContentHeaderMobile;

function _getBackURL(featureName, isFeatureInner) {
    if (!isFeatureInner) return '';

    switch (featureName) {
        case 'MESSAGES':
            return '/messages';
        default:
            return '';
    }
}
