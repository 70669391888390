import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Features, useAppConfigContext } from '../../contexts/AppConfigContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './ShowBooking.module.scss';

const ShowBooking = () => {
    const pageRef = useRef(null);
    const { features } = useAppConfigContext();
    const { dl } = useParams();
    const deepLink = useRef(dl);

    useDocumentTitle(features[Features.ONLINE_BOOKING].label, pageRef);

    return (
        <div ref={pageRef} className={styles.ShowBooking} data-testid='ShowBooking'>
            <iframe
                name='online-booking'
                title='Online-Buchung'
                className={styles.ShowBookingFrame}
                src={atob(deepLink.current)}
            />
        </div>
    );
};

ShowBooking.propTypes = {};

export default ShowBooking;
