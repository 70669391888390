import graphql from 'babel-plugin-relay/macro';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-relay';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button, { ButtonStyle } from '../Button/Button';
import Select from '../Form/Select/Select';
import Alert, { AlertType } from '../Alert/Alert';

import styles from './NewsletterForm.module.scss';

export const ChangeNewsletterSubscriptionMutation = graphql`
    mutation NewsletterFormMutation($input: UpdateNewsletterSubscriptionInput!) {
        updateNewsletterSubscription(input: $input) {
            id
            consents {
                receive_kombibrief
            }
        }
    }
`;

const NewsletterForm = ({ isSubscribed, onChange }) => {
    const [isReadonly, setIsReadonly] = useState(true);
    const [isActive, setIsActive] = useState(isSubscribed);
    const [validationError, setValidationError] = useState('');
    const [error, setError] = useState('');
    const [updateSubscription, isLoading] = useMutation(ChangeNewsletterSubscriptionMutation);
    const { t } = useTranslation();

    useEffect(() => setIsActive(isSubscribed), [isSubscribed]);

    const submitForm = () => {
        setError('');
        setValidationError('');
        updateSubscription({
            variables: {
                input: { receive_kombibrief: isActive },
            },
            onError: (networkError) => {
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        setValidationError(
                            mutationError.extensions.validation['input.receive_kombibrief']?.[0] ??
                                ''
                        );
                    } else {
                        setError(mutationError.message);
                    }
                } else {
                    onChange();
                    setIsReadonly(true);
                }
            },
        });
    };

    const resetForm = () => {
        setError('');
        setIsActive(isSubscribed);
    };

    return (
        <div className={styles.NewsletterForm} data-testid='NewsletterForm'>
            <p>{t('profile.newsletterSubscriptionDescription')}</p>
            <form>
                <Select
                    label={t('profile.subscriptionLabel')}
                    options={[
                        { label: t('profile.subscriptionActive'), value: true },
                        { label: t('profile.subscriptionInactive'), value: false },
                    ]}
                    value={isActive}
                    onChange={(value) => setIsActive(value)}
                    readonly={isReadonly}
                    error={validationError}
                    required
                />
            </form>
            {error && <Alert type={AlertType.ERROR}>{error}</Alert>}
            <div className={styles.FormButtons}>
                {isReadonly ? (
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('profile.modifySubscription')}
                        onClick={() => setIsReadonly(false)}
                        block
                    />
                ) : (
                    <>
                        <Button
                            style={ButtonStyle.PRIMARY}
                            text={t('profile.saveSubscription')}
                            onClick={() => submitForm()}
                            disabled={isLoading}
                            block
                        />
                        <Button
                            style={ButtonStyle.SECONDARY}
                            text={t('generics.cancelButton')}
                            onClick={() => {
                                resetForm();
                                setIsReadonly(true);
                            }}
                            disabled={isLoading}
                            block
                        />
                    </>
                )}
            </div>
        </div>
    );
};

NewsletterForm.propTypes = {
    isSubscribed: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default NewsletterForm;
