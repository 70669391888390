import { useTranslation } from 'react-i18next';
import { PasswordIcon } from '../Icon/Icon';

import styles from './FeaturePreviewMobile.module.scss';

const FeaturePreviewMobile = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.FeaturePreviewMobile} data-testid='FeaturePreviewMobile'>
            <div className={styles.FeaturePreviewMobileWarning}>
                <div className={styles.FeaturePreviewMobileWarningIconContainer}>
                    <PasswordIcon className={styles.FeaturePreviewMobileWarningIcon} />
                </div>
                <div className={styles.FeaturePreviewMobileWarningBody}>
                    <p>
                        <strong>{t('auth.limitedAccessPage')}</strong>
                    </p>
                    <p>{t('auth.limitedAccessPageMsg')}</p>
                </div>
            </div>
            <div
                className={styles.FeaturePreviewMobileDescription}
                dangerouslySetInnerHTML={{ __html: t('auth.limitedAccessPageDescription') }}
            ></div>
        </div>
    );
};

export default FeaturePreviewMobile;
