import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useBookingMessages(src) {
    const navigate = useNavigate();

    const onMessage = useCallback(
        (event) => {
            // console.debug('Message detected from: ', event.origin);
            // console.debug('Messages are allowed from: ', src);
            if (src.startsWith(event.origin)) {
                // console.debug('Data received from app: ', event.data);
                const { type, payload } = event.data;
                if (!['edit-online-booking', 'open-online-booking'].includes(type)) {
                    // console.debug(`Message type is "${type}", ignored`);
                    return;
                }
                const base64URL = encodeURIComponent(btoa(payload.url));
                const urlEncodedId = encodeURIComponent(payload.id);
                // console.debug('Navigating to: ', `/booking/${urlEncodedId}/${base64URL}`);
                navigate(`/booking/${urlEncodedId}/${base64URL}`);
            }
        },
        [navigate, src]
    );

    useEffect(() => {
        window.addEventListener('message', onMessage);
        return () => window.removeEventListener('message', onMessage);
    }, [onMessage]);
}
