import PropTypes from 'prop-types';
import Input from '../Input/Input';
import styles from './TitlePicker.module.scss';
import Select from '../Select/Select';
import { useTranslation } from 'react-i18next';

export const Titles = {
    DR: 'DR',
    PROF: 'PROF',
    DR_DR: 'DR_DR',
    PROF_DR: 'PROF_DR',
};

export const TitleTranslationKeys = {
    [Titles.DR]: 'profile.titleDr',
    [Titles.PROF]: 'profile.titleProf',
    [Titles.DR_DR]: 'profile.titleDrDr',
    [Titles.PROF_DR]: 'profile.titleProfDr',
};

const TitlePicker = ({
    value,
    error = {
        titleError: '',
        otherTitleError: '',
    },
    ...props
}) => {
    const { title, otherTitle } = value;
    const { titleError, otherTitleError } = error;
    const { t } = useTranslation();

    const onChange = (values) => {
        props.onChange({
            title: values.title,
            otherTitle: values.otherTitle,
        });
    };

    return (
        <div className={`${styles.TitlePicker} ${props.className}`} data-testid='TitlePicker'>
            <Select
                {...props}
                label={t('profile.titleLabel')}
                value={title}
                onChange={(newTitle) => {
                    onChange({
                        title: newTitle,
                        otherTitle,
                    });
                }}
                className={styles.TitleField}
                readonly={props.readonly}
                error={titleError}
                options={[
                    { label: t(TitleTranslationKeys[Titles.DR]), value: Titles.DR },
                    { label: t(TitleTranslationKeys[Titles.PROF]), value: Titles.PROF },
                    { label: t(TitleTranslationKeys[Titles.DR_DR]), value: Titles.DR_DR },
                    { label: t(TitleTranslationKeys[Titles.PROF_DR]), value: Titles.PROF_DR },
                ]}
            />
            <Input
                {...props}
                label={t('profile.otherTitleLabel')}
                className={styles.OtherTitleField}
                placeholder={t('profile.otherTitlePlaceholder')}
                value={otherTitle}
                readonly={props.readonly}
                onChange={(newOtherTitle) => onChange({ title, otherTitle: newOtherTitle })}
                error={otherTitleError}
            />
        </div>
    );
};

TitlePicker.propTypes = {
    value: PropTypes.shape({
        title: PropTypes.string,
        otherTitle: PropTypes.string,
    }).isRequired,
    error: PropTypes.shape({
        titleError: PropTypes.string,
        otherTitleError: PropTypes.string,
    }),
};

export default TitlePicker;
