import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
    ArrowDownIcon,
    ArrowUpIcon,
    CrossIcon,
    ErrorIcon,
    InfoIcon,
    WarningIcon,
} from '../Icon/Icon';
import DialogType from './DialogType';

import styles from './Dialog.module.scss';
import { useTranslation } from 'react-i18next';

const Dialog = ({ type = DialogType.INFO, dark = false, ...props }) => {
    const [collapsed, setCollapsed] = useState(true);

    let icon;
    let boxStyle = styles.Box;
    switch (type) {
        case DialogType.ERROR:
            boxStyle += ' ' + styles.ErrorType;
            icon = <ErrorIcon className={styles.DialogIcon} />;
            break;
        case DialogType.WARNING:
            boxStyle += ' ' + styles.WarningType;
            icon = <WarningIcon className={styles.DialogIcon} />;
            break;
        case DialogType.INFO:
        default:
            boxStyle += ' ' + styles.InfoType;
            icon = <InfoIcon className={styles.DialogIcon} />;
            break;
    }

    if (!collapsed) {
        boxStyle += ' ' + styles.Expanded;
    }

    if (dark) {
        boxStyle += ' ' + styles.Dark;
    }

    if (props.image) {
        boxStyle += ' ' + styles.Imaged;
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const onClickWithoutPropagation = (event, callback) => {
        event.stopPropagation();
        if (callback) {
            callback();
        }
    };

    const Content = ({ tagName, children, ...props }) =>
        React.createElement(tagName, props, children);

    const { t } = useTranslation();

    return (
        <div className={styles.Dialog} data-testid='Dialog'>
            <div className={boxStyle}>
                <CrossIcon
                    className={styles.Close}
                    onClick={(event) => onClickWithoutPropagation(event, props.onClose)}
                />
                <Content
                    tagName={props.link ? 'a' : 'div'}
                    href={props.link}
                    target={props.link ? '_blank' : null}
                    className={styles.Content}
                    style={props.image ? { backgroundImage: "url('" + props.image + "')" } : null}
                >
                    {icon}
                    {props.title ? (
                        <div
                            className={styles.Title}
                            dangerouslySetInnerHTML={{ __html: props.title }}
                        />
                    ) : null}
                    {props.message ? (
                        <div
                            className={styles.Message}
                            dangerouslySetInnerHTML={{ __html: props.message }}
                        />
                    ) : null}

                    {props.more && (
                        <>
                            <div
                                className={styles.More}
                                dangerouslySetInnerHTML={{ __html: props.more }}
                            />
                            <div
                                className={styles.ReadMore}
                                onClick={(event) =>
                                    onClickWithoutPropagation(event, toggleCollapsed)
                                }
                            >
                                {collapsed ? (
                                    <>
                                        <ArrowDownIcon className={styles.ReadMoreIcon} />
                                        {t('dialog.more')}
                                    </>
                                ) : (
                                    <>
                                        <ArrowUpIcon className={styles.ReadMoreIcon} />
                                        {t('dialog.less')}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </Content>
                {props.actions && <div className={styles.Actions}>{props.actions}</div>}
            </div>
        </div>
    );
};

Dialog.propTypes = {
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    more: PropTypes.string,
    type: PropTypes.oneOf(Object.values(DialogType)),
    onClose: PropTypes.func,
    actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    image: PropTypes.string,
    link: PropTypes.string,
    dark: PropTypes.bool,
};

export default Dialog;
