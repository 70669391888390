import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowDownIcon, ArrowRightIcon } from '../Icon/Icon';
import RichText from '../RichText/RichText';

import styles from './Faq.module.scss';

const Faq = (props) => {
    const [expanded, setExpanded] = useState(false);

    const toggleCollapsed = () => {
        setExpanded(!expanded);
    };

    const boxStyle = expanded ? `${styles.Faq} ${styles.Expanded}` : styles.Faq;

    return (
        <div className={boxStyle} data-testid='Faq'>
            <div className={styles.Summary} onClick={toggleCollapsed}>
                {expanded ? (
                    <ArrowDownIcon className={styles.Toggle} />
                ) : (
                    <ArrowRightIcon className={styles.Toggle} />
                )}
                {props.title}
            </div>
            <div className={styles.Detail}>
                <RichText rawHTMLStringContent={props.content} />
            </div>
        </div>
    );
};

const faqProps = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

Faq.propTypes = faqProps;

export const FaqProps = PropTypes.shape(faqProps);
export default Faq;
