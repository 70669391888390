/**
 * @generated SignedSource<<ead7aaa8a1bfb5e2d4e2e2dac72c7db4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "crmId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEnabled",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Timestamp",
  "kind": "LinkedField",
  "name": "lastModified",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "by",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "crmId",
        "variableName": "crmId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Employee",
    "kind": "LinkedField",
    "name": "updateEmployee",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "crmId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Permission",
        "kind": "LinkedField",
        "name": "permissions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readonly",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailService",
        "kind": "LinkedField",
        "name": "emailServices",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailServiceId",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmployeesPermissionsTableUpdateMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmployeesPermissionsTableUpdateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "23351e5afb4357eb71debe863df1ee41",
    "id": null,
    "metadata": {},
    "name": "EmployeesPermissionsTableUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation EmployeesPermissionsTableUpdateMutation(\n  $crmId: String!\n  $input: UpdateEmployeeInput!\n) {\n  updateEmployee(crmId: $crmId, input: $input) {\n    crmId\n    permissions {\n      permissionId\n      name\n      isEnabled\n      lastModified {\n        at\n        by\n      }\n      readonly\n    }\n    emailServices {\n      emailServiceId\n      isEnabled\n    }\n    lastModified {\n      at\n      by\n    }\n  }\n}\n"
  }
};
})();

node.hash = "c139f29c06afd91d8f82ec7d635e5874";

module.exports = node;
