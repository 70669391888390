export const permissions = {
    VERIFIED: 'VERIFIZIERT',
    SUPERVISOR: 'SUPERVISOR',
};

export const viewType = {
    COOKIE_NAME: 'viewtype',
    COOKIE_VALUE_MOBILE: 'm',
    COOKIE_VALUE_DESKTOP: 'd',
};
