import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Radio.module.scss';
import Button, { ButtonStyle } from '../../Button/Button';

const RadioGroup = ({
    name = '',
    value = '',
    className = '',
    onChange = () => undefined,
    options = [],
}) => {
    return (
        <>
            {options.map((option, index) => (
                <div className={styles.RadioGroup} key={index}>
                    <label
                        data-testid='Radio'
                        className={classNames({
                            [styles.Radio]: true,
                            [className]: className,
                        })}
                    >
                        <input
                            checked={value ? option.value === value : option?.default}
                            type='radio'
                            name={name}
                            value={option.value}
                            onChange={({ target }) => {
                                onChange(target.value);
                            }}
                            disabled={!!option.isDisabled}
                        />
                        <span dangerouslySetInnerHTML={{ __html: option.label }} />
                        {option.disabledMessage && option.isDisabled ? (
                            <Button
                                icon='info'
                                style={ButtonStyle.PRIMARY}
                                tooltip={option.disabledMessage}
                                extraClasses={styles.TransparentButton}
                            />
                        ) : null}
                    </label>
                </div>
            ))}
        </>
    );
};

RadioGroup.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
};

export default RadioGroup;
