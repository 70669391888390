import React from 'react';
import styles from './Separator.module.scss';

const Separator = (props) => {
    return (
        <div className={styles.Separator} data-testid='Separator'>
            {props.children && <span className={styles.SeparatorItem}>{props.children}</span>}
        </div>
    );
};

Separator.propTypes = {};

export default Separator;
