import React from 'react';
import PropTypes from 'prop-types';
import Faq, { FaqProps } from './Faq';

const FaqsList = ({ faqs = [] }) => {
    return (
        <div data-testid='FaqsList'>
            {faqs.map((faq) => (
                <Faq key={faq.id} {...faq} />
            ))}
        </div>
    );
};

FaqsList.propTypes = {
    faqs: PropTypes.arrayOf(FaqProps),
};

export default FaqsList;
