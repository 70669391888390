import { useTranslation } from 'react-i18next';
import Button, { ButtonStyle } from '../Button/Button';
import Dialog from '../Dialog/Dialog';
import DialogType from '../Dialog/DialogType';

const ConfirmDeleteRouteDialog = ({ onCancel, onConfirm, routeToDelete }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            title={t('connections.deleteRouteDialogTitle')}
            type={DialogType.WARNING}
            message={t('connections.deleteRouteDialogPleaseConfirm', {
                startTerminalName: routeToDelete.startTerminalName,
                destinationTerminalName: routeToDelete.destinationTerminalName,
            })}
            onClose={onCancel}
            actions={
                <>
                    <Button
                        style={ButtonStyle.PRIMARY}
                        text={t('connections.deleteRouteDialogConfirmButton')}
                        onClick={onConfirm}
                    />
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('generics.cancelButton')}
                        onClick={onCancel}
                    />
                </>
            }
        />
    );
};

export default ConfirmDeleteRouteDialog;
