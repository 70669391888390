/**
 * @generated SignedSource<<73ada4e97217f46c8eb4010e4212bdde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "readStoerer",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppReadStoererMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppReadStoererMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ac4c3c99a613e3ca05cd33761e326da0",
    "id": null,
    "metadata": {},
    "name": "AppReadStoererMutation",
    "operationKind": "mutation",
    "text": "mutation AppReadStoererMutation(\n  $input: ReadStoererInput!\n) {\n  readStoerer(input: $input)\n}\n"
  }
};
})();

node.hash = "229abee170bb1288f1e55af79437fe34";

module.exports = node;
