import { useCallback, useState } from 'react';
import { useOnlineBookingLogout } from '../components/OnlineBooking/OnlineBooking';
import { useOrdersLogout } from '../components/Orders/Orders';

export const useLogout = (onlyFESession = false) => {
    const logoutOnlineBooking = useOnlineBookingLogout();
    const logoutOrders = useOrdersLogout();

    return useCallback(async () => {
        // logout in third party apps (online booking)
        await logoutOnlineBooking();

        // logout in third party apps (orders)
        await logoutOrders();

        sessionStorage.clear();
        localStorage.clear();
        if (!onlyFESession) {
            const apiUrl = process.env.REACT_APP_API_URL;
            const form = document.createElement('form');
            form.method = 'post';
            form.action = (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) + '/logout';
            document.body.appendChild(form);
            form.submit();
        }
    }, [logoutOnlineBooking, logoutOrders]);
};

export const useAuth = () => {
    const [accessToken, setToken] = useState(localStorage.getItem('access_token'));

    const logout = useLogout();

    const setAccessToken = useCallback(
        (newAccessToken) => {
            if (!newAccessToken) {
                logout();
            } else {
                localStorage.setItem('access_token', newAccessToken);
                setToken(newAccessToken);
            }
        },
        [logout]
    );

    return {
        isLoggedIn: !!accessToken,
        setAccessToken,
    };
};
