import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './CompanyInfo.module.scss';

const CompanyInfo = ({ accountCount, companyName, companyUIRR }) => {
    const { t } = useTranslation();
    return (
        <p className={styles.CompanyInfo}>
            <span className={styles.HighlightedText}>{accountCount}</span>
            {` ${t('userManagement.usersOf')} `}
            <span className={styles.HighlightedText}>
                {companyName} (UIRR {companyUIRR})
            </span>
        </p>
    );
};

export const CompanyPropTypes = {
    accountCount: PropTypes.number.isRequired,
    companyName: PropTypes.string.isRequired,
    companyUIRR: PropTypes.number.isRequired,
};

CompanyInfo.propTypes = CompanyPropTypes;

export default CompanyInfo;
