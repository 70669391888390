import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '../Icon/Icon';
import NavigationItem from './NavigationItem';

const NavigationItemGroup = ({
    iconOnly = false,
    active = false,
    open = false,
    restricted = false,
    title,
    icon,
    onClick,
    children,
    ...restProps
}) => {
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        if (iconOnly) {
            setCollapsed(true);
        }
    }, [iconOnly]);

    let rightIcon = null;
    if (!iconOnly) {
        rightIcon = collapsed ? <ArrowDownIcon /> : <ArrowUpIcon />;
    }

    return (
        <NavigationItem
            iconOnly={iconOnly}
            active={active}
            open={open}
            restricted={restricted}
            title={title}
            icon={icon}
            {...restProps}
            rightIcon={rightIcon}
            onClick={() => {
                onClick();
                setCollapsed(!collapsed);
            }}
        >
            {!collapsed && children}
        </NavigationItem>
    );
};

NavigationItemGroup.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconOnly: PropTypes.bool,
    active: PropTypes.bool,
    open: PropTypes.bool,
    restricted: PropTypes.bool,
};

export default NavigationItemGroup;
