import React from 'react';

import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const Header = (props) => {
    var Component = props.isMobileView ? HeaderMobile : HeaderDesktop;

    return <Component {...props} />;
};

Header.propTypes = {};
export default Header;
