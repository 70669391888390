import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PasswordIcon } from '../Icon/Icon';
import styles from './NotAuthorizedErrorBoundary.module.scss';

const decorateWithHooks = (Component) => {
    return (props) => {
        const location = useLocation();
        return <Component location={location} {...props} />;
    };
};

class NotAuthorizedErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        if (error.message === 'NotAuthorized') {
            return { hasError: true };
        }
        throw error;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.key !== prevProps.location.key) {
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div
                    data-testid='NotAuthorizedErrorBoundary'
                    className={styles.NotAuthorizedErrorBoundary}
                >
                    <PasswordIcon className={styles.PasswordIcon} />
                    <p>{this.props.t('generics.unauthorizedError')}</p>
                </div>
            );
        }
        return this.props.children;
    }
}

NotAuthorizedErrorBoundary.propTypes = {
    location: PropTypes.shape({
        key: PropTypes.string.isRequired,
    }).isRequired,
};

export default decorateWithHooks(withTranslation()(NotAuthorizedErrorBoundary));
