import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../Panel/Panel';
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';
import EditContactInformationForm, {
    CompanyInformation,
} from '../EditContactInformationForm/EditContactInformationForm';
import { Gender } from '../Form/Select/GenderSelect';
import ChangeLanguageForm from '../ChangeLanguageForm/ChangeLanguageForm';
import Roles from '../Roles/Roles';
import NewsletterForm from '../NewsletterForm/NewsletterForm';
import PrivacyForm from '../PrivacyForm/PrivacyForm';
import ChangeEmailForm from '../ChangeEmailForm/ChangeEmailForm';
import DeleteUser from '../DeleteUser/DeleteUser';
import { useUserContext } from '../../contexts/UserContext';

import styles from './EditProfile.module.scss';
import { useNavigate } from 'react-router-dom';
import EmailServices from '../EmailServices/EmailServices';

const EditProfile = ({ emailServicesConfig }) => {
    const { refreshUser, me, isSupervisor } = useUserContext();
    const navigate = useNavigate();
    const { t } = useTranslation();

    console.log('EditProfile', me.emailServices);

    return (
        <div className={styles.EditProfile} data-testid='EditProfile'>
            <Panel title={t('profile.editEmail')}>
                <ChangeEmailForm email={me.email} onChange={() => navigate(0)} />
            </Panel>
            <Panel title={t('profile.editPassword')}>
                <ChangePasswordForm />
            </Panel>
            {me.gender === Gender.TECHNICAL ? (
                <Panel title={t('profile.companyData')}>
                    <CompanyInformation user={me} />
                </Panel>
            ) : (
                <Panel title={t('profile.editContactInfo')}>
                    <EditContactInformationForm user={me} onChange={() => refreshUser()} />
                </Panel>
            )}
            <Panel title={t('profile.changeLanguage')}>
                <ChangeLanguageForm language={me.language} onChange={() => navigate(0)} />
            </Panel>
            <Panel title={t('profile.permissions')}>
                <Roles user={me} />
            </Panel>
            <Panel title={t('profile.emailServices')}>
                <EmailServices
                    user={me}
                    onChange={() => refreshUser()}
                    emailServicesConfig={emailServicesConfig}
                />
            </Panel>
            <Panel title={t('profile.newsletterSettings')}>
                <NewsletterForm
                    isSubscribed={me.consents.receive_kombibrief}
                    onChange={() => refreshUser()}
                />
            </Panel>
            <Panel title={t('profile.privacyPolicy')}>
                <PrivacyForm
                    isPollingEnabled={me.consents.receive_polling}
                    isEventInvitesEnabled={me.consents.receive_event_invites}
                    isServiceUpdatesEnabled={me.consents.receive_service_updates}
                    onChange={() => refreshUser}
                />
            </Panel>
            {me.gender !== Gender.TECHNICAL && (
                <Panel title={t('profile.deleteUser')}>
                    <DeleteUser email={me.email} crmId={me.crm_id} isSupervisor={isSupervisor} />
                </Panel>
            )}
        </div>
    );
};

EditProfile.propTypes = {};

export default EditProfile;
