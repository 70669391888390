import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

export const useRouteTerminalConnectionsQuery = graphql`
    query useRouteTerminalConnectionsQuery {
        terminalConnections {
            start
            dest
        }
    }
`;

function useRouteTerminalConnections() {
    const data = useLazyLoadQuery(useRouteTerminalConnectionsQuery);
    return data.terminalConnections;
}

export default useRouteTerminalConnections;
