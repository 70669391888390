/**
 * @generated SignedSource<<b04edbe56e15b2bc650cb8f264bba3f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EmailServiceGroup",
    "kind": "LinkedField",
    "name": "emailServices",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "display",
        "storageKey": null
      },
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailServiceDefinition",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailServiceId",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useEmailServicesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useEmailServicesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4c7b61c0de3f2548676559ff1a1d79eb",
    "id": null,
    "metadata": {},
    "name": "useEmailServicesQuery",
    "operationKind": "query",
    "text": "query useEmailServicesQuery {\n  emailServices {\n    display\n    name\n    description\n    services {\n      emailServiceId\n      name\n      description\n    }\n  }\n}\n"
  }
};
})();

node.hash = "91a1d0fe4be94e9f86184b7f9041963c";

module.exports = node;
