/**
 * @generated SignedSource<<2e9485d9a91a9107e5004a080cd62f55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "routes",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertRouteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Booklet",
        "kind": "LinkedField",
        "name": "upsertBookletRoute",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertRouteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Booklet",
        "kind": "LinkedField",
        "name": "upsertBookletRoute",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88720c9bc6ef18e101d29aca9eda2ae9",
    "id": null,
    "metadata": {},
    "name": "UpsertRouteMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertRouteMutation(\n  $id: ID\n  $input: UpsertBookletRouteInput!\n) {\n  upsertBookletRoute(id: $id, input: $input) {\n    routes\n    id\n  }\n}\n"
  }
};
})();

node.hash = "949ec964228a71fc1136d72e91473b2f";

module.exports = node;
