import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

export const BookletQuery = graphql`
    query useEditBookletQuery($id: Int!) {
        booklet(id: $id) {
            id
            internal_id
            title
            type
            include_other_information
            include_general_information
            timeframe
            from
            to
            group_returns
            routes
        }
    }
`;

function useEditBooklet() {
    const { id } = useParams();
    if (id) {
        const bookletId = parseInt(id);
        const data = useLazyLoadQuery(BookletQuery, { id: bookletId });
        return bookletMapper(data.booklet);
    }
    return null;
}

export const bookletMapper = (booklet) => {
    const routes = booklet.routes ? JSON.parse(booklet.routes) : [];
    return {
        id: booklet.id,
        internal_id: booklet.internal_id,
        title: booklet.title,
        type: booklet.type,
        include_other_information: booklet.include_other_information,
        include_general_information: booklet.include_general_information,
        timeframe: booklet.timeframe,
        from:
            booklet.type === 'default' ||
            (booklet.type === 'daily' && booklet.timeframe === 'currentPeriod')
                ? null
                : booklet.from,
        to:
            booklet.type === 'default' ||
            (booklet.type === 'daily' && booklet.timeframe === 'currentPeriod')
                ? null
                : booklet.to,
        group_returns: booklet.group_returns,
        routes: routes.map((route) => bookletRoutesMapper(route)),
    };
};

export const bookletRoutesMapper = (route) => ({
    id: route.id,
    startTerminal: parseInt(route.startTerminal),
    destinationTerminal: parseInt(route.destinationTerminal),
    preferredRoute: route.preferredRoute,
    viaTerminals: route.viaTerminals.map((viaTerminal) => mapViaNotViaTerminals(viaTerminal)),
    notViaTerminals: route.notViaTerminals.map((notViaTerminal) =>
        mapViaNotViaTerminals(notViaTerminal)
    ),
    includeReturnDirection: route.includeReturnDirection,
});

export const mapViaNotViaTerminals = (terminal) => ({
    id: terminal.id,
    value: terminal.value ? parseInt(terminal.value) : 0,
});

export default useEditBooklet;
