import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Features, useAppConfigContext } from '../../contexts/AppConfigContext';
import useBookingMessages from '../../hooks/useBookingMessages';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './Orders.module.scss';

export const useOrdersLogout = () => {
    const { features } = useAppConfigContext();
    return useCallback(async () => {
        if (!features[Features.ORDERS]) {
            return false;
        }

        const ordersUrl = new URL(features[Features.ORDERS]?.getOption('url'));
        ordersUrl.pathname = '/logout';
        ordersUrl.search = '';

        return await fetch(ordersUrl, {
            method: 'POST',
            credentials: 'include',
            redirect: 'manual',
        })
            .then((response) => {
                if (!response.ok && !response.redirected) {
                    console.error(
                        `Logout of orders failed with status code ${response.statusCode}.`
                    );
                    return false;
                }
                return true;
            })
            .catch(console.error);
    }, [features]);
};

const Orders = (props) => {
    const pageRef = useRef(null);
    const { features, isMobileView } = useAppConfigContext();
    const src = features[Features.ORDERS]?.getOption('url');
    const [loggedOut, setLoggedOut] = useState(false);
    const logoutOrders = useOrdersLogout();

    useEffect(() => {
        logoutOrders().then(() => {
            setLoggedOut(true);
        });
    }, [logoutOrders]);

    useBookingMessages(src);
    useDocumentTitle(features[Features.ORDERS].label, pageRef);

    return (
        <div
            ref={pageRef}
            className={classNames({
                [styles.Orders]: true,
                [styles.OrdersMobile]: isMobileView,
            })}
            data-testid='Orders'
        >
            {loggedOut ? (
                <iframe
                    name='orders'
                    title='Auftragsübersicht'
                    className={styles.OrdersFrame}
                    src={src}
                />
            ) : null}
        </div>
    );
};

Orders.propTypes = {};

export default Orders;
