import { useCallback, useState } from 'react';

const useComponentCacheContext = (initialCache = {}) => {
    const [cachedComponents, setCachedComponents] = useState(initialCache);

    const activateComponent = useCallback((id, Component, props) => {
        setCachedComponents((oldCachedComponents) => {
            if (oldCachedComponents[id]?.isActive) {
                return oldCachedComponents;
            }
            return {
                ...Object.entries(oldCachedComponents).reduce(
                    (carry, [currentId, values]) => ({
                        ...carry,
                        [currentId]: {
                            ...values,
                            isActive: false,
                        },
                    }),
                    {}
                ),
                [id]: {
                    Component,
                    props,
                    isActive: true,
                },
            };
        });
    }, []);

    const deactivateComponent = useCallback((id) => {
        setCachedComponents((oldCachedComponents) => {
            if (!oldCachedComponents[id]?.isActive) {
                return oldCachedComponents;
            }
            return {
                ...oldCachedComponents,
                [id]: {
                    ...oldCachedComponents[id],
                    isActive: false,
                },
            };
        });
    }, []);

    const disposeComponent = useCallback((id) => {
        setCachedComponents((oldCachedComponents) => {
            if (!oldCachedComponents[id]) {
                return oldCachedComponents;
            }
            const { [id]: disposed, ...newCache } = oldCachedComponents;
            return newCache;
        });
    }, []);

    return {
        cachedComponents,
        activateComponent,
        deactivateComponent,
        disposeComponent,
    };
};

export default useComponentCacheContext;
