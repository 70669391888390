/**
 * @generated SignedSource<<dac6e2121a0b655b163ab526bc88f455>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "crmId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Timestamp",
  "kind": "LinkedField",
  "name": "lastModified",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "by",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEnabled",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Permission",
  "kind": "LinkedField",
  "name": "permissions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissionId",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readonly",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailServiceId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "EmailService",
  "kind": "LinkedField",
  "name": "emailServices",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserManagement",
    "kind": "LinkedField",
    "name": "userManagement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyUIRR",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceEmails",
        "kind": "LinkedField",
        "name": "emails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pdf",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "xml",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Employee",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "forename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "surname",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceAddress",
        "kind": "LinkedField",
        "name": "serviceAddresses",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "EmailServiceGroup",
    "kind": "LinkedField",
    "name": "emailServices",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "display",
        "storageKey": null
      },
      (v3/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailServiceDefinition",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          (v3/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserManagementQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserManagementQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "54d30de5b526917bdc2de649f1299e22",
    "id": null,
    "metadata": {},
    "name": "UserManagementQuery",
    "operationKind": "query",
    "text": "query UserManagementQuery {\n  userManagement {\n    companyName\n    companyUIRR\n    emails {\n      pdf\n      xml\n    }\n    users {\n      crmId\n      forename\n      surname\n      email\n      lastModified {\n        at\n        by\n      }\n      permissions {\n        permissionId\n        name\n        isEnabled\n        lastModified {\n          at\n          by\n        }\n        readonly\n      }\n      emailServices {\n        emailServiceId\n        isEnabled\n      }\n    }\n    serviceAddresses {\n      crmId\n      email\n      lastModified {\n        at\n        by\n      }\n      permissions {\n        permissionId\n        name\n        isEnabled\n        lastModified {\n          at\n          by\n        }\n        readonly\n      }\n      emailServices {\n        emailServiceId\n        isEnabled\n      }\n    }\n  }\n  emailServices {\n    display\n    name\n    description\n    services {\n      emailServiceId\n      name\n      description\n    }\n  }\n}\n"
  }
};
})();

node.hash = "661c5b043439135cf706587dc47a1758";

module.exports = node;
