import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from './Select';

export const Gender = {
    OTHER: 'OTHER',
    FEMALE: 'FEMALE',
    MALE: 'MALE',
    TECHNICAL: 'TECHNICAL',
    COMPANY: 'COMPANY',
};

const GenderSelect = ({
    clearable = true,
    defaultValue = undefined,
    onChange = () => undefined,
    readonly = false,
    required = false,
    error = '',
    value = undefined,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <Select
            placeholder={t('generics.pleaseSelect')}
            label={t('profile.genderLabel')}
            clearable={clearable}
            defaultValue={defaultValue}
            onChange={onChange}
            readonly={readonly}
            required={required}
            error={error}
            value={value}
            {...props}
            options={[
                { label: t('profile.genderOther'), value: Gender.OTHER },
                { label: t('profile.genderFemale'), value: Gender.FEMALE },
                { label: t('profile.genderMale'), value: Gender.MALE },
                { label: t('profile.genderTechnical'), value: Gender.TECHNICAL, isDisabled: true },
                { label: t('profile.genderCompany'), value: Gender.COMPANY, isDisabled: true },
            ]}
        />
    );
};

GenderSelect.propTypes = {
    clearable: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default GenderSelect;
