import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import TagsContext from '../../contexts/TagsContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useVisibilityEffect } from '../../hooks/useVisibility';

import styles from './Page.module.scss';
import AppStateContext from '../../contexts/AppStateContext';

const Page = ({ tags = [], title = '', children = <></>, ...props }) => {
    const pageRef = useRef(null);
    const { setTags, clearTags } = useContext(TagsContext);
    const { setShowHotline } = useContext(AppStateContext);

    if (props.showHotline === 'show') {
        setShowHotline(true);
    } else if (props.showHotline === 'hide') {
        setShowHotline(false);
    } else {
        // we do nothing to inherit the global setting
    }

    useDocumentTitle(title, pageRef);

    useVisibilityEffect(
        (isVisible) => {
            if (isVisible) {
                setTags(tags);
            }
        },
        [tags, setTags, clearTags],
        pageRef
    );

    useVisibilityEffect(
        (isVisible) => {
            if (isVisible) {
                return clearTags;
            }
        },
        [clearTags],
        pageRef
    );

    return (
        <article ref={pageRef} className={styles.Page} data-testid='Page'>
            <h1 className={styles.PageTitle}>{title}</h1>
            {children}
        </article>
    );
};

Page.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    showHotline: PropTypes.string,
};

export default Page;
