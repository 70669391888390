import React from 'react';
import PropTypes from 'prop-types';
import styles from './PermissionsList.module.scss';

import { EnabledIcon, DisabledIcon } from '../Icon/Icon';

const PermissionListItem = ({ isEnabled, text }) => (
    <li className={styles.ListItem}>
        <div className={styles.IconWrapper}>
            {isEnabled ? (
                <EnabledIcon className={styles.EnabledIcon} />
            ) : (
                <DisabledIcon className={styles.DisabledIcon} />
            )}
        </div>
        {text}
    </li>
);

const PermissionsList = ({ className = '', permissions = [] }) => {
    return (
        <ul
            className={[styles.PermissionsList, className].join(' ').trim()}
            data-testid='PermissionsList'
        >
            {permissions.map((permission) => (
                <PermissionListItem
                    key={permission.permissionId}
                    isEnabled={permission.isEnabled}
                    text={permission.name}
                />
            ))}
        </ul>
    );
};

PermissionsList.propTypes = {
    className: PropTypes.string,
    permissions: PropTypes.arrayOf(
        PropTypes.shape({
            permissionId: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            lastModifiedAt: PropTypes.string,
            lastModifiedBy: PropTypes.string,
        })
    ),
};

export default PermissionsList;
