import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ErrorIcon } from '../Icon/Icon';
import styles from './LocalErrorBoundary.module.scss';
import { AuthError } from '../Auth/Error';
import { BackendError } from '../../fetchGraphQL';

const decorateWithHooks = (Component) => {
    return (props) => {
        const location = useLocation();
        return <Component location={location} {...props} />;
    };
};

class LocalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }

    static getDerivedStateFromError(error) {
        if (!(error instanceof AuthError)) {
            return { error };
        }
        throw error;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.key !== prevProps.location.key) {
            this.setState({ error: null });
        }
    }

    render() {
        const { className = '', row = false } = this.props;
        if (this.state.error) {
            return (
                <div
                    data-testid='LocalErrorBoundary'
                    className={classNames({
                        [styles.LocalErrorBoundary]: true,
                        [styles.LocalErrorBoundaryMultiline]: !row,
                        [className]: !!className,
                    })}
                >
                    <ErrorIcon className={styles.ErrorIcon} />
                    <span className={styles.ErrorMessage}>
                        {this.state.error instanceof BackendError
                            ? this.state.error.message
                            : this.props.t('generics.unexpectedError')}
                    </span>
                </div>
            );
        }
        return this.props.children;
    }
}

LocalErrorBoundary.propTypes = {
    location: PropTypes.shape({
        key: PropTypes.string.isRequired,
    }).isRequired,
    row: PropTypes.bool,
    className: PropTypes.string,
};

export default decorateWithHooks(withTranslation()(LocalErrorBoundary));
