import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styles from './Video.module.scss';
import defaultPoster from './default-poster.png';

const Video = (props) => {
    const [showControls, setShowControls] = useState();
    const videoEl = useRef();

    const poster = props.poster || defaultPoster;

    return (
        <div data-testid='Video'>
            <div className={styles.Container} data-image-container>
                <div className={styles.Blur} style={{ backgroundImage: `url(${poster})` }} />
                <video
                    ref={videoEl}
                    className={styles.Video}
                    poster={poster}
                    controls={showControls}
                    onPlay={() => setShowControls(true)}
                >
                    {props.src && <source src={props.src} type='video/mp4' />}
                </video>
                {!showControls && (
                    <button className={styles.PlayButton} onClick={() => videoEl.current.play()}>
                        <span className={styles.PlayTriangle} />
                    </button>
                )}
            </div>
            {props.title && (
                <div data-title className={styles.Title}>
                    {props.title}
                </div>
            )}
            {props.description && (
                <div data-description className={styles.Description}>
                    {props.description}
                </div>
            )}
        </div>
    );
};

Video.propTypes = {
    src: PropTypes.string.isRequired,
    poster: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default Video;
