import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

export const ContactsQuery = graphql`
    query useContactsQuery($tags: [String!], $first: Int = 99) {
        contacts(tags: $tags, first: $first) {
            edges {
                node {
                    id
                    type
                    avatar_url
                    name
                    position
                    phone
                    mobile
                    email
                    languages
                    tags(first: $first) {
                        edges {
                            node {
                                name
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
`;

function useContacts(tags) {
    const data = useLazyLoadQuery(ContactsQuery, { tags });

    return data.contacts.edges.map(({ node: contact }) => ({
        id: contact.id,
        type: contact.type,
        name: contact.name,
        avatarUrl: contact.avatar_url || undefined,
        position: contact.position,
        tags: contact.tags.edges.map(({ node }) => node.name),
        phoneNumber: contact.phone,
        mobileNumber: contact.mobile,
        email: contact.email,
        languages: contact.languages,
    }));
}

export default useContacts;
