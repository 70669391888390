import graphql from 'babel-plugin-relay/macro';
import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from '../Table/Table';
import LastModifiedCell, { lastModifiedSortType } from './Cells/LastModifiedCell';
import PermissionsCell from './Cells/PermissionsCell';
import MainCell from './Cells/MainCell';
import Dialog from '../Dialog/Dialog';
import DialogType from '../Dialog/DialogType';
import Button, { ButtonStyle } from '../Button/Button';

import styles from './PermissionsTable.module.scss';
import EmailServicesCell from './Cells/EmailServicesCell';

export const UpdateEmployeeMutation = graphql`
    mutation EmployeesPermissionsTableUpdateMutation(
        $crmId: String!
        $input: UpdateEmployeeInput!
    ) {
        updateEmployee(crmId: $crmId, input: $input) {
            crmId
            permissions {
                permissionId
                name
                isEnabled
                lastModified {
                    at
                    by
                }
                readonly
            }
            emailServices {
                emailServiceId
                isEnabled
            }
            lastModified {
                at
                by
            }
        }
    }
`;

export const DeleteEmployeeMutation = graphql`
    mutation EmployeesPermissionsTableDeleteMutation($crmId: String!) {
        deleteEmployee(crmId: $crmId) {
            crmId
        }
    }
`;

const EmployeesPermissionsTable = ({ employees = [], emailServicesConfig }) => {
    const { t } = useTranslation();
    const [deletedEmployee, setDeletedEmployee] = useState(null);

    const updateEmployeeUpdater = useCallback((store, employeeId) => {
        const userRecord = store
            .getRoot()
            .getLinkedRecord('userManagement')
            .getLinkedRecords('users')
            .find((record) => record.getValue('crmId') === employeeId);

        if (!userRecord) {
            return;
        }
        if (!store.getRootField('updateEmployee')) {
            return;
        }

        const permissionsRecord = store
            .getRootField('updateEmployee')
            .getLinkedRecords('permissions');
        userRecord.setLinkedRecords(permissionsRecord, 'permissions');

        const emailServicesRecord = store
            .getRootField('updateEmployee')
            .getLinkedRecords('emailServices');
        userRecord.setLinkedRecords(emailServicesRecord, 'emailServices');

        const lastModifiedRecord = store
            .getRootField('updateEmployee')
            .getLinkedRecord('lastModified');
        userRecord.setLinkedRecord(lastModifiedRecord, 'lastModified');
    }, []);

    const deleteEmployeeUpdater = useCallback((store) => {
        try {
            const crmId = store.getRootField('deleteEmployee').getValue('crmId');
            const userManagementRecord = store.getRoot().getLinkedRecord('userManagement');
            userManagementRecord.setLinkedRecords(
                userManagementRecord
                    .getLinkedRecords('users')
                    .filter((record) => record.getValue('crmId') !== crmId),
                'users'
            );
        } catch (err) {
            console.log(err);
        }
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: t('userManagement.nameColumn'),
                accessor: 'name',
                cellClass: `${styles.NameCell} ${styles.MainCell}`,
                sortable: true,
                Cell: (props) => (
                    <MainCell
                        {...props}
                        canDelete={true}
                        deleteMutation={DeleteEmployeeMutation}
                        deleteMutationUpdater={deleteEmployeeUpdater}
                        updateMutation={UpdateEmployeeMutation}
                        updateMutationUpdater={updateEmployeeUpdater}
                        onUserDeleted={(user) => {
                            setDeletedEmployee(user);
                        }}
                    >
                        <div className={styles.CellBreak} title={props.row.original.surname}>
                            {props.row.original.surname},
                        </div>
                        <div className={styles.CellBreak} title={props.row.original.forename}>
                            {props.row.original.forename}
                        </div>
                    </MainCell>
                ),
                sortType: (
                    { original: { forename: forename1, surname: surname1 } },
                    { original: { forename: forename2, surname: surname2 } }
                ) => {
                    return `${surname1}${forename1}`.localeCompare(`${surname2}${forename2}`);
                },
            },
            {
                Header: t('userManagement.emailColumn'),
                accessor: 'email',
                cellClass: styles.EmailCell,
                sortable: true,
                Cell: (props) => (
                    <div {...props}>
                        <div className={styles.CellBreak} title={props.row.original.email}>
                            {props.row.original.email}
                        </div>
                    </div>
                ),
            },
            {
                Header: t('userManagement.permissionsColumn'),
                accessor: 'permissions',
                filterable: true,
                cellClass: `${styles.MainCell} ${styles.PermissionsCell}`,
                Cell: PermissionsCell,
            },
            {
                Header: t('userManagement.emailServicesColumn'),
                accessor: 'emailServices',
                filterable: true,
                Cell: (props) => (
                    <EmailServicesCell
                        {...props}
                        emailServicesConfig={emailServicesConfig}
                        readonly={true}
                    />
                ),
            },
            {
                Header: t('userManagement.lastModifiedColumn'),
                accessor: 'lastModified',
                sortable: true,
                cellClass: styles.LastModifiedCell,
                Cell: LastModifiedCell,
                sortType: lastModifiedSortType,
            },
        ],
        [t, updateEmployeeUpdater, deleteEmployeeUpdater]
    );

    return (
        <>
            <Table columns={columns} data={employees} />
            {deletedEmployee && (
                <Dialog
                    title={t('userManagement.userDeletedTitle')}
                    message={t('userManagement.userDeletedContent', {
                        email: deletedEmployee.email,
                    })}
                    type={DialogType.INFO}
                    onClose={() => setDeletedEmployee(null)}
                    actions={
                        <Button
                            style={ButtonStyle.PRIMARY}
                            text={t('generics.close')}
                            onClick={() => setDeletedEmployee(null)}
                        />
                    }
                />
            )}
        </>
    );
};

const employeeProps = {
    forename: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(
        PropTypes.shape({
            permissionId: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
            lasModified: PropTypes.shape({
                at: PropTypes.string,
                by: PropTypes.string,
            }),
        })
    ),
    emailServices: PropTypes.arrayOf(
        PropTypes.shape({
            emailServiceId: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
        })
    ),
    lastModified: PropTypes.shape({
        at: PropTypes.string,
        by: PropTypes.string,
    }),
};

EmployeesPermissionsTable.propTypes = {
    employees: PropTypes.arrayOf(PropTypes.shape(employeeProps)),
};

export const EmployeesProps = PropTypes.arrayOf(PropTypes.shape(employeeProps));
export default EmployeesPermissionsTable;
