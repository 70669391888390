import { t } from 'i18next';

export class AuthError extends Error {}
export class NotAuthorizedError extends AuthError {}
export class MaintenanceError extends Error {}

export class TOTPError extends AuthError {
    constructor(secret = null, qrCode = null) {
        super(t('userManagement.multiFactorAuthenticationRequired'));
        this.secret = secret;
        this.qrCode = qrCode;
    }
}
