import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';
import { ArrowRightIcon, ArrowDownIcon } from '../Icon/Icon';

import styles from './Card.module.scss';

const Card = ({
    wide = false,
    isCollapsible = false,
    footer = null,
    isMobileView,
    title,
    children,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(isCollapsible);

    return (
        <div
            className={classNames({
                [styles.Card]: true,
                [styles.CardMobile]: isMobileView,
                [styles.CardCollapsible]: isCollapsible,
                [styles.CardCollapsed]: isCollapsed,
            })}
            data-testid='Card'
        >
            {title && (
                <div
                    className={styles.CardTitleContainer}
                    onClick={
                        !isCollapsible
                            ? () => {}
                            : () => {
                                  setIsCollapsed(!isCollapsed);
                              }
                    }
                >
                    <ArrowRightIcon className={styles.CardCollapsibleClosedIcon} />
                    <ArrowDownIcon className={styles.CardCollapsibleOpenedIcon} />
                    <div className={styles.CardTitle}>{title}</div>
                </div>
            )}
            <div className={classNames(styles.CardContent, { [styles.CardContentWide]: wide })}>
                <LocalErrorBoundary>{children}</LocalErrorBoundary>
            </div>
            {footer && (
                <div className={styles.CardFooter}>
                    <LocalErrorBoundary row>{footer}</LocalErrorBoundary>
                </div>
            )}
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.node.isRequired,
    wide: PropTypes.bool,
    isCollapsible: PropTypes.bool,
    footer: PropTypes.node,
};

export default Card;
