import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Button, { ButtonStyle } from '../Button/Button';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import { useLogout } from '../../hooks/useAuth';
import styles from './GlobalErrorBoundary.module.scss';

const decorateWithHooks = (Component) => {
    return (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        const logout = useLogout();
        return <Component location={location} navigate={navigate} logout={logout} {...props} />;
    };
};

class GlobalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: '',
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            isAuthError: error === 'AuthenticationRequired',
            isMaintenanceError: error === 'MaintenanceIsActive',
        };
    }

    componentDidCatch(error, errorInfo) {
        if (error.message === 'AuthenticationRequired') {
            this.props.logout();
        } else if (error.message === 'MaintenanceIsActive') {
            this.props.logout();
        } else {
            console.error(error, errorInfo);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.key !== prevProps.location.key) {
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.isAuthError) {
            return null;
        }
        if (this.state.isMaintenanceError) {
            return null;
        }
        if (this.state.hasError) {
            return (
                <div data-testid='GlobalErrorBoundary' className={styles.GlobalErrorBoundary}>
                    <CompanyLogo className={styles.Logo} />
                    <p>{this.props.t('generics.unexpectedError')}</p>
                    <div className={styles.Buttons}>
                        <Button
                            style={ButtonStyle.PRIMARY}
                            block
                            onClick={() => this.props.navigate(0)}
                            text={this.props.t('generics.reloadApp')}
                        />
                        <Button
                            style={ButtonStyle.PRIMARY}
                            block
                            onClick={() => this.props.navigate(-1)}
                            text={this.props.t('generics.goBack')}
                        />
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

GlobalErrorBoundary.propTypes = {
    navigate: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    location: PropTypes.shape({
        key: PropTypes.string.isRequired,
    }).isRequired,
};

export default decorateWithHooks(withTranslation()(GlobalErrorBoundary));
