import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CacheComponent } from '../ComponentCache/CacheComponent';
import { useTabsContext } from '../../contexts/TabsContext';
import ComponentCacheContext from '../../contexts/ComponentCacheContext';

const Tab = ({ id, title, component }) => {
    const { addTab } = useTabsContext();
    const { disposeComponent } = useContext(ComponentCacheContext);
    const { pathname: currentPath } = useLocation();
    const { tabSuffix } = useParams();

    const tabId = tabSuffix ? `${id}-${tabSuffix}` : id;
    const tabTitle = typeof title === 'function' ? title(tabSuffix) : title;

    useEffect(() => {
        addTab(tabId, tabTitle, currentPath, () => disposeComponent(tabId));
    }, [tabId, tabTitle, currentPath, addTab, disposeComponent]);

    return <CacheComponent id={tabId} component={component} />;
};

Tab.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    component: PropTypes.func,
};

export default Tab;
