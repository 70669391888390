import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { useEffect, useState, useCallback } from 'react';
import Component from './UserManagement';
import blurredBackgroundImgSrc from './um-background.png';
import MultiFactorErrorBoundary from '../Auth/MultiFactorErrorBoundary';

import styles from './UserManagement.module.scss';

export const UserManagementQuery = graphql`
    query UserManagementQuery {
        userManagement {
            companyName
            companyUIRR
            emails {
                pdf
                xml
            }
            users {
                crmId
                forename
                surname
                email
                lastModified {
                    at
                    by
                }
                permissions {
                    permissionId
                    name
                    isEnabled
                    lastModified {
                        at
                        by
                    }
                    readonly
                }
                emailServices {
                    emailServiceId
                    isEnabled
                }
            }
            serviceAddresses {
                crmId
                email
                lastModified {
                    at
                    by
                }
                permissions {
                    permissionId
                    name
                    isEnabled
                    lastModified {
                        at
                        by
                    }
                    readonly
                }
                emailServices {
                    emailServiceId
                    isEnabled
                }
            }
        }
        emailServices {
            display
            name
            description
            services {
                emailServiceId
                name
                description
            }
        }
    }
`;

const UserManagement = ({ fetchKey }) => {
    const { userManagement: data, emailServices: emailServicesConfig } = useLazyLoadQuery(
        UserManagementQuery,
        {},
        {
            fetchPolicy: 'network-only',
            fetchKey,
        }
    );

    return (
        <Component
            companyInfo={{
                companyName: data.companyName,
                companyUIRR: data.companyUIRR,
                accountCount: data.users.length + data.serviceAddresses.length,
            }}
            employees={data.users}
            serviceAddresses={data.serviceAddresses}
            emails={{
                pdf: data.emails.pdf,
                xml: data.emails.xml,
            }}
            emailServicesConfig={emailServicesConfig}
        />
    );
};

// @TODO hack to increment the fetchKey between mounts
let persistedFetchKey = 0;

const SecuredUserManagement = () => {
    const [fetchKey, setFetchKey] = useState(persistedFetchKey);

    const updateFetchKey = useCallback((newFetchKey) => {
        persistedFetchKey = newFetchKey;
        setFetchKey(newFetchKey);
    }, []);

    useEffect(() => {
        updateFetchKey(persistedFetchKey + 1);
    }, [updateFetchKey]);

    return (
        <MultiFactorErrorBoundary
            backgroundImg={blurredBackgroundImgSrc}
            className={styles.TOTPWall}
            onRetry={() => {
                updateFetchKey(persistedFetchKey + 1);
            }}
        >
            <UserManagement fetchKey={fetchKey} />
        </MultiFactorErrorBoundary>
    );
};

UserManagement.propTypes = {};

export default SecuredUserManagement;
