import PropTypes from 'prop-types';
import React from 'react';
import styles from './Image.module.scss';

const Image = (props) => {
    return (
        <div className={styles.Wrapper} data-testid='Image'>
            <div className={styles.Container} data-image-container>
                <div className={styles.Blur} style={{ backgroundImage: `url(${props.src})` }} />
                <img className={styles.Image} src={props.src} alt={props.alt || props.title} />
            </div>
            {props.title && (
                <div data-title className={styles.Title}>
                    {props.title}
                </div>
            )}
            {props.description && (
                <div data-description className={styles.Description}>
                    {props.description}
                </div>
            )}
        </div>
    );
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default Image;
