import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Features, useAppConfigContext } from '../../contexts/AppConfigContext';
import useBookingMessages from '../../hooks/useBookingMessages';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './OnlineBooking.module.scss';

export const useOnlineBookingLogout = () => {
    const { features } = useAppConfigContext();
    return useCallback(async () => {
        if (!features[Features.ONLINE_BOOKING]) {
            return false;
        }

        const onlineBookingUrl = new URL(features[Features.ONLINE_BOOKING]?.getOption('url'));
        onlineBookingUrl.pathname = '/logout';
        onlineBookingUrl.search = '';

        return await fetch(onlineBookingUrl, {
            method: 'POST',
            credentials: 'include',
            redirect: 'manual',
        })
            .then((response) => {
                if (!response.ok && !response.redirected) {
                    console.error(
                        `Logout of online booking failed with status code ${response.statusCode}.`
                    );
                    return false;
                }
                return true;
            })
            .catch(console.error);
    }, [features]);
};

const OnlineBooking = (props) => {
    const pageRef = useRef(null);
    const { features, isMobileView } = useAppConfigContext();
    const src = new URL(features[Features.ONLINE_BOOKING]?.getOption('url'));

    if (props.params && props.params.tabSuffix && props.params.tabSuffix.length === 13) {
        src.pathname = 'booking';
    }

    const [loggedOut, setLoggedOut] = useState(false);
    const logoutOnlineBooking = useOnlineBookingLogout();

    useEffect(() => {
        logoutOnlineBooking().then(() => {
            setLoggedOut(true);
        });
    }, [logoutOnlineBooking]);

    useBookingMessages(src.toString());
    useDocumentTitle(features[Features.ONLINE_BOOKING].label, pageRef);

    return (
        <div
            ref={pageRef}
            className={classNames({
                [styles.OnlineBooking]: true,
                [styles.OnlineBookingMobile]: isMobileView,
            })}
            data-testid='OnlineBooking'
        >
            {loggedOut ? (
                <iframe
                    name='online-booking'
                    title='Online-Buchung'
                    className={styles.OnlineBookingFrame}
                    src={src.toString()}
                />
            ) : null}
        </div>
    );
};

OnlineBooking.propTypes = {};

export default OnlineBooking;
