import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';
import { ArrowDownIcon, ArrowRightIcon } from '../Icon/Icon';
import styles from './Panel.module.scss';

const Panel = ({ collapsed: propCollapsed = true, ...props }) => {
    const [collapsed, setCollapsed] = useState(propCollapsed);

    const toggleCollapseState = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div
            className={classNames(styles.Panel, { [styles.Collapsed]: collapsed })}
            data-testid='Panel'
        >
            <div className={styles.Title} onClick={toggleCollapseState}>
                <span className={styles.IconContainer}>
                    {collapsed ? (
                        <ArrowRightIcon className={styles.ArrowIcon} />
                    ) : (
                        <ArrowDownIcon className={styles.ArrowIcon} />
                    )}
                </span>
                <span>{props.title}</span>
            </div>
            {!collapsed && (
                <div className={styles.Content}>
                    <LocalErrorBoundary>{props.children}</LocalErrorBoundary>
                </div>
            )}
        </div>
    );
};

Panel.propTypes = {
    collapsed: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
export default Panel;
