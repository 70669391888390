import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Card from '../Card/Card';
import DownloadsList from '../DownloadsList/DownloadsList';
import Grid from '../Grid/Grid';
import { ArrowRightBigIcon } from '../Icon/Icon';
import styles from './DownloadsOverview.module.scss';

const DownloadsOverview = ({ cards = [] }) => {
    const overviewRef = useRef();
    const { t } = useTranslation();

    useDocumentTitle(t('navigation.downloadsOverviewTitle'), overviewRef);

    return (
        <div ref={overviewRef} className={styles.DownloadsOverview} data-testid='DownloadsOverview'>
            <h1 className={styles.PageTitle}>{t('navigation.downloadsOverviewTitle')}</h1>
            <Grid columns={3} columnWidth={'420px'}>
                {cards.map(({ name, metaField, downloads }, index) => (
                    <Card key={index} wide title={name}>
                        <DownloadsList downloads={downloads} metaField={metaField} />
                    </Card>
                ))}
                <div className={styles.GoToListCard}>
                    <Card wide>
                        <Link to='/downloads/list' style={{ textDecoration: 'none' }}>
                            <div className={styles.GoToListLink}>
                                <ArrowRightBigIcon className={styles.GoToIcon} />
                                {t('navigation.allDownloadsTitle')}
                            </div>
                        </Link>
                    </Card>
                </div>
            </Grid>
        </div>
    );
};

DownloadsOverview.propTypes = {
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            metaField: PropTypes.string,
        })
    ),
};
export default DownloadsOverview;
