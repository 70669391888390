import graphql from 'babel-plugin-relay/macro';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-relay';
import Alert, { AlertType } from '../Alert/Alert';
import Button, { ButtonStyle } from '../Button/Button';
import styles from './DeleteUser.module.scss';

export const DeleteProfileMutation = graphql`
    mutation DeleteUserDeleteMutation($crmId: String!) {
        deleteProfile(crmId: $crmId)
    }
`;

const DeleteUser = ({ email, crmId, isSupervisor = false }) => {
    const [showConfirmAlert, setShowConfirmAlert] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();
    const [deleteProfileMutation, isLoading] = useMutation(DeleteProfileMutation);

    const deleteProfile = () => {
        deleteProfileMutation({
            variables: { crmId },
            onError: (networkError) => {
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (response, errors) => {
                if (errors?.length) {
                    const [mutationError] = errors;
                    setError(mutationError.message);
                } else {
                    localStorage.clear();
                    window.location.href = response.deleteProfile;
                }
            },
        });
    };

    return (
        <div className={styles.DeleteUser} data-testid='DeleteUser'>
            {!showConfirmAlert ? (
                <>
                    <p
                        className={styles.MainContent}
                        dangerouslySetInnerHTML={{ __html: t('profile.deleteProfile', { email }) }}
                    />
                    <div className={styles.ButtonContainer}>
                        <Button
                            style={ButtonStyle.SECONDARY}
                            text={t('profile.confirmDeleteProfile')}
                            onClick={() => setShowConfirmAlert(true)}
                            block
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.MainContent}>
                        <Alert type={AlertType.WARNING} className={styles.Alert}>
                            <p className={styles.AlertTitle}>
                                {t('profile.deleteProfileAlertTitle')}
                            </p>
                            <div
                                className={styles.AlertContent}
                                dangerouslySetInnerHTML={{
                                    __html: t('profile.deleteProfileAlertContent'),
                                }}
                            />
                        </Alert>
                        {isSupervisor && (
                            <Alert type={AlertType.WARNING} className={styles.Alert}>
                                <p className={styles.AlertTitle}>
                                    {t('profile.deleteSupervisorAlertTitle')}
                                </p>
                                <div
                                    className={styles.AlertContent}
                                    dangerouslySetInnerHTML={{
                                        __html: t('profile.deleteSupervisorAlertContent'),
                                    }}
                                />
                            </Alert>
                        )}
                        {error && (
                            <Alert type={AlertType.ERROR} className={styles.Alert}>
                                {error}
                            </Alert>
                        )}
                    </div>
                    <div className={styles.ButtonContainer}>
                        <Button
                            style={ButtonStyle.SECONDARY}
                            type='submit'
                            text={t('profile.confirmDeleteProfile')}
                            onClick={() => deleteProfile()}
                            disabled={isLoading}
                            block
                        />
                        <Button
                            style={ButtonStyle.SECONDARY}
                            type='button'
                            text={t('generics.cancelButton')}
                            disabled={isLoading}
                            onClick={() => setShowConfirmAlert(false)}
                            block
                        />
                    </div>
                </>
            )}
        </div>
    );
};

DeleteUser.propTypes = {
    email: PropTypes.string.isRequired,
    crmId: PropTypes.string.isRequired,
    isSupervisor: PropTypes.bool,
};

export default DeleteUser;
