import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CompanyLogo from '../CompanyLogo/CompanyLogo';

import styles from './AppLoadingIndicator.module.scss';

import { ReactComponent as Spinner } from './spinner.svg';

const AppLoadingIndicator = ({ scope = 'global' }) => {
    const { t } = useTranslation();

    const scopeConfig = {
        main: {
            text: t('loading.content'),
            className: styles.AppContent,
        },
        global: {
            text: t('loading.app'),
            className: styles.Global,
        },
    };

    const { text, className } = scopeConfig[scope];

    return (
        <div
            className={`${styles.AppLoadingIndicator} ${className}`}
            data-testid='AppLoadingIndicator'
        >
            <div className={styles.Container}>
                {scope === 'global' && <CompanyLogo className={styles.Logo} />}
                <p>{text}</p>
                <div>
                    <Spinner />
                </div>
            </div>
        </div>
    );
};

AppLoadingIndicator.propTypes = {
    scope: PropTypes.oneOf(['global', 'main']),
};

export default AppLoadingIndicator;
