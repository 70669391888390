import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import useDynamicNavigate from '../../hooks/useDynamicNavigate';
import styles from './RichText.module.scss';

const RichText = (props) => {
    const navigate = useDynamicNavigate();
    const navigateOnAnchorClick = useCallback(
        (ev) => {
            const { target } = ev;
            if (target instanceof HTMLAnchorElement && target.getAttribute('target') !== '_blank') {
                ev.preventDefault();
                navigate(target.getAttribute('href'));
            }
        },
        [navigate]
    );

    return props.rawHTMLStringContent ? (
        <div
            data-testid='RichText'
            className={styles.RichText}
            dangerouslySetInnerHTML={{ __html: props.rawHTMLStringContent }}
            onClick={navigateOnAnchorClick}
        />
    ) : (
        <div className={styles.RichText} data-testid='RichText'>
            {props.children}
        </div>
    );
};

RichText.propTypes = {
    children: PropTypes.node,
    rawHTMLStringContent: PropTypes.string,
};

export default RichText;
