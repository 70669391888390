import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ComponentCacheContext from '../../contexts/ComponentCacheContext';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';

import styles from './ComponentCache.module.scss';

export const CachedComponents = () => {
    const { cachedComponents } = useContext(ComponentCacheContext);

    return (
        <>
            {Object.entries(cachedComponents).map(
                ([
                    id,
                    {
                        Component,
                        isActive,
                        props: { params },
                    },
                ]) => (
                    <div key={id} className={isActive ? styles.Container : styles.Hidden}>
                        <LocalErrorBoundary>
                            <Component params={params} />
                        </LocalErrorBoundary>
                    </div>
                )
            )}
        </>
    );
};

export const CacheComponent = ({ id, component }) => {
    const { activateComponent, deactivateComponent } = useContext(ComponentCacheContext);
    const params = useParams();

    useEffect(() => {
        activateComponent(id, component, { params });
        return () => {
            deactivateComponent(id);
        };
    }, [id, component, activateComponent, deactivateComponent, params]);

    return <></>;
};
