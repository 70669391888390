import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom';

import useRoutes from './useRoutes';
import App from '../components/App/App';
import NotFound from '../components/NotFound/NotFound';
import GlobalErrorBoundary from '../components/ErrorBoundary/GlobalErrorBoundary';
import { useAppConfigContext } from '../contexts/AppConfigContext';

const AppRoutes = () => {
    const { features } = useAppConfigContext();
    const { routes } = useRoutes(features);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<App />} errorElement={GlobalErrorBoundary}>
                {Object.entries(routes).map(
                    ([path, { component: Component, isIndex = false, handle = null }]) => {
                        return (
                            <Route
                                key={path}
                                index={isIndex}
                                path={path}
                                element={Component}
                                handle={handle}
                            />
                        );
                    }
                )}
                <Route path='*' element={<NotFound />} />
            </Route>
        )
    );

    return <RouterProvider router={router} />;
};

AppRoutes.propTypes = {};
export default AppRoutes;
