import React from 'react';
import PropTypes from 'prop-types';
import Contact, { ContactProps } from './Contact';

const ContactsList = ({ isMobileView = false, contacts = [] }) => {
    return (
        <div data-testid='ContactsList'>
            {contacts.map((contact) => (
                <Contact key={contact.id} isMobileView={isMobileView} {...contact} />
            ))}
        </div>
    );
};

ContactsList.propTypes = {
    isMobileView: PropTypes.bool,
    contacts: PropTypes.arrayOf(ContactProps),
};

export default ContactsList;
