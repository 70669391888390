import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../contexts/UserContext';
import Alert, { AlertType } from '../Alert/Alert';
import { Gender } from '../Form/Select/GenderSelect';
import { TitleTranslationKeys } from '../Form/TitlePicker/TitlePicker';
import styles from './ContactInformation.module.scss';

const EmployeeInfo = () => {
    const { t } = useTranslation();
    const {
        me: {
            company,
            gender,
            position,
            email,
            forename,
            surname,
            title,
            title_custom,
            phone,
            mobile,
        },
    } = useUserContext();

    const GENDER_KEYS = {
        [Gender.FEMALE]: 'generics.namePrefixFemale',
        [Gender.MALE]: 'generics.namePrefixMale',
        [Gender.OTHER]: 'generics.namePrefixOther',
    };

    const fullDisplayName = [
        t(GENDER_KEYS[gender]),
        Object.prototype.hasOwnProperty.call(TitleTranslationKeys, title)
            ? t(TitleTranslationKeys[title])
            : null,
        title_custom,
        forename,
        surname,
    ].join(' ');

    return (
        <>
            <div className={styles.InfoSection}>
                <p>
                    {company.name} ({t('profile.uirrNumber', { number: company.uirr })})
                </p>
            </div>
            <div className={styles.InfoSection}>
                <p>{fullDisplayName}</p>
                {position && <p>{position}</p>}
            </div>
            <div className={styles.InfoSection}>
                <p>{email}</p>
                <p>{phone.composed}</p>
                {mobile.composed && <p>{mobile.composed}</p>}
            </div>
            <Alert type={AlertType.INFO} className={styles.InfoAlert}>
                {t('enquiry.personalInfoAlert')}
            </Alert>
        </>
    );
};

const ServiceAddressInfo = () => {
    const {
        me: { company, email },
    } = useUserContext();
    return (
        <div className={styles.InfoSection}>
            <p>
                {company.name} (UIRR {company.uirr})
            </p>
            <p>{email}</p>
        </div>
    );
};

const ContactInformation = () => {
    const { t } = useTranslation();
    const {
        me: { gender },
    } = useUserContext();

    return (
        <div className={styles.ContactInformation} data-testid='ContactInformation'>
            <span className={styles.SubTitle}>{t('enquiry.personalInfoTitle')}</span>
            {[Gender.TECHNICAL, Gender.COMPANY].includes(gender) ? (
                <ServiceAddressInfo />
            ) : (
                <EmployeeInfo />
            )}
        </div>
    );
};

ContactInformation.propTypes = {};

export default ContactInformation;
