import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';
import DynamicContent from '../DynamicContent/DynamicContent';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';
import Component from '../Page/Page';

export const PageQuery = graphql`
    query PageQuery($slug: String!, $state: String) {
        page(slug: $slug, state: $state) {
            id
            title
            slug
            content
            settings_hotline_show
            tags(first: 20) {
                edges {
                    node {
                        name
                        slug
                    }
                }
            }
        }
    }
`;

const Page = (props) => {
    const { page } = usePreloadedQuery(PageQuery, props.preloadedQuery);
    const content = useMemo(() => JSON.parse(page.content), [page]);
    const tags = useMemo(() => page.tags.edges.map(({ node }) => node.name), [page]);

    return (
        <Component title={page.title} tags={tags} showHotline={page.settings_hotline_show}>
            <LocalErrorBoundary>
                <DynamicContent blocks={content} />
            </LocalErrorBoundary>
        </Component>
    );
};

Page.propTypes = {
    preloadedQuery: PropTypes.object,
};

export default Page;
