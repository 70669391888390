import graphql from 'babel-plugin-relay/macro';
import { useState, useEffect } from 'react';
import { useMutation } from 'react-relay';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button, { ButtonStyle } from '../Button/Button';
import CheckBox from '../Form/CheckBox/CheckBox';
import Separator from '../Separator/Separator';
import Alert, { AlertType } from '../Alert/Alert';

import styles from './PrivacyForm.module.scss';

export const ChangePrivacySettingsMutation = graphql`
    mutation PrivacyFormMutation($input: UpdatePrivacySubscriptionInput!) {
        updatePrivacySubscription(input: $input) {
            id
            consents {
                receive_service_updates
                receive_event_invites
                receive_polling
            }
        }
    }
`;

const PrivacyForm = ({
    isPollingEnabled,
    isEventInvitesEnabled,
    isServiceUpdatesEnabled,
    onChange,
}) => {
    const [isReadonly, setIsReadonly] = useState(true);
    const [error, setError] = useState('');
    const [isServiceUpdatesChecked, setIsServiceUpdatesChecked] = useState(isServiceUpdatesEnabled);
    const [isEventInvitesChecked, setIsEventInvitesChecked] = useState(isEventInvitesEnabled);
    const [isPollingChecked, setIsPollingChecked] = useState(isPollingEnabled);
    const [updateSettings, isLoading] = useMutation(ChangePrivacySettingsMutation);
    const { t } = useTranslation();

    useEffect(() => setIsPollingChecked(isPollingEnabled), [isPollingEnabled]);
    useEffect(() => setIsServiceUpdatesChecked(isServiceUpdatesEnabled), [isServiceUpdatesEnabled]);
    useEffect(() => setIsEventInvitesChecked(isEventInvitesEnabled), [isEventInvitesEnabled]);

    const submitForm = () => {
        setError('');
        updateSettings({
            variables: {
                input: {
                    receive_service_updates: isServiceUpdatesChecked,
                    receive_event_invites: isEventInvitesChecked,
                    receive_polling: isPollingChecked,
                },
            },
            onError: (networkError) => {
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        setError(
                            mutationError.extensions.validation[
                                'input.receive_service_updates'
                            ]?.[0] ?? ''
                        );
                        setError(
                            mutationError.extensions.validation[
                                'input.receive_event_invites'
                            ]?.[0] ?? ''
                        );
                        setError(
                            mutationError.extensions.validation['input.receive_polling']?.[0] ?? ''
                        );
                    } else {
                        setError(mutationError.message);
                    }
                } else {
                    onChange();
                    setIsReadonly(true);
                }
            },
        });
    };

    const resetForm = () => {
        setIsPollingChecked(isPollingEnabled);
        setIsServiceUpdatesChecked(isServiceUpdatesEnabled);
        setIsEventInvitesChecked(isEventInvitesEnabled);
    };

    return (
        <div className={styles.PrivacyForm} data-testid='PrivacyForm'>
            <h2>{t('profile.privacyPolicyTitleGeneral')}</h2>
            <CheckBox
                label={t('profile.privacyPolicyLabelGeneralConsent')}
                checked
                required
                readonly
            />
            <Separator />
            <form className={styles.SettingsForm}>
                <h2>{t('profile.privacyPolicyTitleAdditional')}</h2>
                <p className='multiline-translation'>
                    {t('profile.privacyPolicyDescriptionAdditional')}
                </p>
                <CheckBox
                    className={styles.CheckBox}
                    label={t('profile.privacyPolicyLabelServiceUpdatesConsent')}
                    checked={isServiceUpdatesChecked}
                    onChange={(isChecked) => setIsServiceUpdatesChecked(isChecked)}
                    readonly={isReadonly}
                />
                <CheckBox
                    className={styles.CheckBox}
                    label={t('profile.privacyPolicyLabelEventInvitesConsent')}
                    checked={isEventInvitesChecked}
                    onChange={(isChecked) => setIsEventInvitesChecked(isChecked)}
                    readonly={isReadonly}
                />
                <CheckBox
                    className={styles.CheckBox}
                    label={t('profile.privacyPolicyLabelPollingConsent')}
                    checked={isPollingChecked}
                    onChange={(isChecked) => setIsPollingChecked(isChecked)}
                    readonly={isReadonly}
                />
                <p
                    className={styles.Note}
                    dangerouslySetInnerHTML={{ __html: t('profile.privacyPolicyNote') }}
                />
            </form>
            {error && <Alert type={AlertType.ERROR}>{error}</Alert>}
            <div className={styles.FormButtons}>
                {isReadonly ? (
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('profile.modifyPrivacySettings')}
                        onClick={() => setIsReadonly(false)}
                        block
                    />
                ) : (
                    <>
                        <Button
                            style={ButtonStyle.PRIMARY}
                            text={t('profile.savePrivacySettings')}
                            onClick={() => submitForm()}
                            disabled={isLoading}
                            block
                        />
                        <Button
                            style={ButtonStyle.SECONDARY}
                            text={t('generics.cancelButton')}
                            onClick={() => {
                                resetForm();
                                setIsReadonly(true);
                            }}
                            disabled={isLoading}
                            block
                        />
                    </>
                )}
            </div>
        </div>
    );
};

PrivacyForm.propTypes = {
    isPollingEnabled: PropTypes.bool.isRequired,
    isEventInvitesEnabled: PropTypes.bool.isRequired,
    isServiceUpdatesEnabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default PrivacyForm;
