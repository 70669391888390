import { useEffect } from 'react';
import { useQueryLoader } from 'react-relay';
import Page, { PageQuery } from '../components/Page/Page.relay';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

const PageRoute = ({ params }) => {
    const location = useLocation();
    const { slug, state } = useParams();
    const [preloadedQuery, loadPageQuery] = useQueryLoader(PageQuery);

    useEffect(() => {
        // todo: this is a workaround. Route should never try to render until it matches
        if (location.pathname.startsWith('/pages')) {
            loadPageQuery({ slug, state });
        }
    }, [loadPageQuery, slug, state, location]);

    return preloadedQuery ? <Page preloadedQuery={preloadedQuery} /> : null;
};

PageRoute.propTypes = {
    params: PropTypes.shape({
        slug: PropTypes.string,
        state: PropTypes.string,
    }),
};

export default PageRoute;
