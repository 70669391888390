import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { useLazyLoadQuery } from 'react-relay';

export const EmailServicesQuery = graphql`
    query useEmailServicesQuery {
        emailServices {
            display
            name
            description
            services {
                emailServiceId
                name
                description
            }
        }
    }
`;

function useEmailServices(tags = null, first = 1000) {
    const data = useLazyLoadQuery(EmailServicesQuery);
    return data.emailServices.map((group) => emailServicesMapper(group));
}

export const emailServicesMapper = (group) => ({
    display: group.display,
    name: group.name,
    description: group.description,
    services: group.services,
});

export default useEmailServices;
