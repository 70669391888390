import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay';
import Component from '../DownloadsByCategory/DownloadsByCategory';

export const DownloadsByCategoryQuery = graphql`
    query DownloadsByCategoryQuery($ids: [Int!]) {
        downloadsByCategory(ids: $ids) {
            id
            name
            url
            updated_at
            main_category {
                id
                name
            }
        }
    }
`;

const DownloadsByCategory = (props) => {
    const { downloadsByCategory } = usePreloadedQuery(
        DownloadsByCategoryQuery,
        props.preloadedQuery
    );
    return <Component downloads={downloadsByCategory} />;
};

DownloadsByCategory.propTypes = {
    preloadedQuery: PropTypes.object,
};

export default DownloadsByCategory;

export const DownloadsByCategoryBy = (props) => {
    const [preloadedQuery, loadCategoryDownloadsQuery] = useQueryLoader(DownloadsByCategoryQuery);
    useEffect(() => {
        loadCategoryDownloadsQuery({ ids: props.ids }, {});
    }, [DownloadsByCategoryQuery, loadCategoryDownloadsQuery]);

    return preloadedQuery ? <DownloadsByCategory preloadedQuery={preloadedQuery} /> : null;
};
