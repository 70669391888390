/**
 * @generated SignedSource<<196ed4d6466e70408e41dae5e9e7fccb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "country"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "country",
        "variableName": "country"
      }
    ],
    "concreteType": "Terminal",
    "kind": "LinkedField",
    "name": "terminals",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "country",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TerminalPickerTerminalsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TerminalPickerTerminalsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "d59725c7e57f7a4a638a314c82c7ef62",
    "id": null,
    "metadata": {},
    "name": "TerminalPickerTerminalsQuery",
    "operationKind": "query",
    "text": "query TerminalPickerTerminalsQuery(\n  $country: String\n) {\n  terminals(country: $country) {\n    id\n    name\n    country {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

node.hash = "32b9cda367d67e8f5ac6d02a523d8eb1";

module.exports = node;
