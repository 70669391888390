import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryLoader } from 'react-relay';
import Message, { MessageQuery } from '../components/Message/Message.relay';

const MessageRoute = ({ params }) => {
    const location = useLocation();
    const { slug, state } = useParams();
    const [preloadedQuery, loadMessageQuery] = useQueryLoader(MessageQuery);

    useEffect(() => {
        // todo: this is a workaround. Route should never try to render until it matches
        if (location.pathname.startsWith('/messages')) {
            loadMessageQuery({ slug, state });
        }
    }, [loadMessageQuery, slug, state, location]);

    return preloadedQuery ? <Message preloadedQuery={preloadedQuery} /> : <></>;
};

MessageRoute.propTypes = {
    params: PropTypes.shape({
        slug: PropTypes.string,
        state: PropTypes.string,
    }),
};

export default MessageRoute;
