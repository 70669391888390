import React, { Suspense, useState, useRef } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import useDateFormatter from '../../hooks/useDateFormatter';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { Features, useAppConfigContext } from '../../contexts/AppConfigContext';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';
import Card from '../Card/Card';
import Grid from '../Grid/Grid';
import Pagination from '../Pagination/Pagination';
import Row, { RowColor } from '../Row/Row';
import { ReactComponent as Spinner } from '../AppLoadingIndicator/spinner.svg';

import styles from './Dashboard.module.scss';

const DashboardMessage = (props) => {
    const navigate = useNavigate();
    const { toDateWithDayString } = useDateFormatter();
    const data = useFragment(
        graphql`
            fragment DashboardMessage_message on Message {
                priority
                title
                slug
                release_date
                main_category {
                    id
                    name
                }
            }
        `,
        props.message
    );

    const getRowColor = (priority) => {
        switch (priority) {
            case 'INFO':
                return RowColor.BLUE;
            case 'WARNING':
                return RowColor.YELLOW;
            case 'ERROR':
                return RowColor.RED;
            default:
                return undefined;
        }
    };

    return (
        <Row
            isMobileView={props.isMobileView}
            title={data.title}
            meta={toDateWithDayString(data.release_date)}
            tags={data.main_category ? [data.main_category] : []}
            color={getRowColor(data.priority)}
            onClick={() => {
                navigate(`/messages/${data.slug}`);
            }}
            multiline
        />
    );
};

const DashboardCard = (props) => {
    const [page, setPage] = useState(1);
    const data = useLazyLoadQuery(
        graphql`
            query DashboardCardQuery($page: Int!, $section: Int!) {
                messages(first: 4, page: $page, section: $section) {
                    paginatorInfo {
                        count
                        currentPage
                        firstItem
                        hasMorePages
                        lastItem
                        lastPage
                        perPage
                        total
                    }
                    data {
                        ...DashboardMessage_message
                    }
                }
            }
        `,
        { page, section: props.section.internal_id }
    );

    const pagination =
        data.messages.paginatorInfo.lastPage > 1 ? (
            <Pagination
                currentPage={data.messages.paginatorInfo.currentPage}
                pageCount={data.messages.paginatorInfo.lastPage}
                isMobileView={props.isMobileView}
                onChange={(page) => setPage(page)}
            />
        ) : null;

    const title = props.section.name;

    return (
        <Card
            wide
            isCollapsible={props.isMobileView}
            isMobileView={props.isMobileView}
            title={title}
            footer={pagination}
        >
            <Suspense fallback={'Loading'}>
                {data.messages.data.map((messageRef, key) => (
                    <DashboardMessage
                        key={key}
                        message={messageRef}
                        isMobileView={props.isMobileView}
                    />
                ))}
            </Suspense>
        </Card>
    );
};

const Dashboard = () => {
    const { features, isMobileView } = useAppConfigContext();
    const pageRef = useRef(null);
    const { t } = useTranslation();
    useDocumentTitle(features[Features.MESSAGES]?.label, pageRef);
    const data = useLazyLoadQuery(graphql`
        query DashboardQuery {
            messageSections(first: 6) {
                edges {
                    node {
                        id
                        internal_id
                        name
                    }
                }
            }
        }
    `);

    const LoadingPlaceholder = () => (
        <Card wide title={t('loading.content')}>
            <Spinner className={styles.CardSpinner} />
        </Card>
    );

    return (
        <div
            ref={pageRef}
            className={classNames({
                [styles.Dashboard]: true,
            })}
            data-testid='Dashboard'
        >
            <Grid columns={3} columnWidth={'420px'} isMobileView={isMobileView}>
                {data.messageSections.edges.map((edge, key) => (
                    <Suspense key={key} fallback={<LoadingPlaceholder />}>
                        <LocalErrorBoundary>
                            <DashboardCard section={edge.node} isMobileView={isMobileView} />
                        </LocalErrorBoundary>
                    </Suspense>
                ))}
            </Grid>
        </div>
    );
};

export default Dashboard;
