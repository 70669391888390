import React from 'react';
import PropTypes from 'prop-types';
import TOTPWall from '../TOTPWall/TOTPWall';
import { TOTPError } from './Error';

class MultiFactorErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            secret: null,
            qrCode: null,
        };
    }

    static getDerivedStateFromError(error) {
        if (error instanceof TOTPError) {
            return {
                hasError: true,
                secret: error.secret,
                qrCode: error.qrCode,
            };
        }
        throw error;
    }

    componentDidCatch(error) {
        console.log(error);
    }

    render() {
        const { className = '' } = this.props;
        if (this.state.hasError) {
            return (
                <TOTPWall
                    backgroundImg={this.props.backgroundImg}
                    className={className}
                    secret={this.state.secret}
                    qrCode={this.state.qrCode}
                    onSuccess={() => {
                        this.props.onRetry();
                        this.setState({ hasError: false });
                    }}
                />
            );
        }
        return this.props.children;
    }
}

MultiFactorErrorBoundary.propTypes = {
    backgroundImg: PropTypes.string.isRequired,
    className: PropTypes.string,
    onRetry: PropTypes.func.isRequired,
};

export default MultiFactorErrorBoundary;
