import React, { Suspense } from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import Component from './CountryPhoneCodeSelect';
import { useLazyLoadQuery } from 'react-relay';

export const PhoneCodeQuery = graphql`
    query CountryPhoneCodeSelectQuery {
        countries {
            name
            phone
        }
    }
`;

const CountryPhoneCodeSelectLoader = (props) => {
    const data = useLazyLoadQuery(PhoneCodeQuery);

    const codes = data.countries.map(({ name, phone }) => ({
        label: `${name} (${phone})`,
        value: phone,
    }));

    return <Component options={codes} {...props} />;
};

const CountryPhoneCodeSelect = ({
    clearable = true,
    defaultValue = undefined,
    label = 'Ländervorwahl',
    onChange = () => undefined,
    placeholder = 'Bitte wählen',
    readonly = false,
    required = false,
    error = '',
    value = undefined,
    ...props
}) => (
    <Suspense fallback={<Component options={[]} placeholder='' />}>
        <CountryPhoneCodeSelectLoader
            clearable={clearable}
            defaultValue={defaultValue}
            label={label}
            onChange={onChange}
            placeholder={placeholder}
            readonly={readonly}
            required={required}
            error={error}
            value={value}
            {...props}
        />
    </Suspense>
);

CountryPhoneCodeSelect.propTypes = {
    clearable: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CountryPhoneCodeSelect;
