import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button, { ButtonSize, ButtonStyle } from '../Button/Button';

import styles from './Pagination.module.scss';

const Pagination = ({
    currentPage = 1,
    showCurrentPage = false,
    onChange = () => undefined,
    pageCount,
    ...props
}) => {
    const { t } = useTranslation();

    const labelHTML = _getLabelHTML(t, currentPage, pageCount);

    return (
        <div
            className={classNames({
                [styles.Pagination]: true,
                [styles.PaginationMobile]: props.isMobileView,
            })}
            data-testid='Pagination'
        >
            <Button
                style={ButtonStyle.SECONDARY}
                size={props.isMobileView ? ButtonSize.MEDIUM : ButtonSize.SMALL}
                disabled={currentPage === 1}
                icon='arrow-left'
                onClick={() => onChange(currentPage - 1)}
            />
            {showCurrentPage && (
                <Button style={ButtonStyle.SECONDARY} size={ButtonSize.SMALL} text={currentPage} />
            )}
            {props.isMobileView ? labelHTML : null}
            <Button
                style={ButtonStyle.SECONDARY}
                size={props.isMobileView ? ButtonSize.MEDIUM : ButtonSize.SMALL}
                disabled={currentPage === pageCount}
                icon='arrow-right'
                onClick={() => onChange(currentPage + 1)}
            />
            {!props.isMobileView ? labelHTML : null}
        </div>
    );
};

function _getLabelHTML(t, currentPage, pageCount) {
    return (
        <span className={styles.PaginationLabel}>
            {t('pagination.currentPosition', {
                current: currentPage,
                total: pageCount,
            })}
        </span>
    );
}

Pagination.propTypes = {
    currentPage: PropTypes.number,
    pageCount: PropTypes.number.isRequired,
    showCurrentPage: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Pagination;
