import graphql from 'babel-plugin-relay/macro';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-relay';
import EnquiryContextWrapper, { useEnquiryContext } from '../../contexts/EnquiryContext';
import Alert, { AlertType } from '../Alert/Alert';
import Button, { ButtonStyle } from '../Button/Button';
import { CheckmarkIcon } from '../Icon/Icon';
import Steps from '../Steps/Steps';
import Connection, { useConnectionFormValidator } from './Connection';
import ContactInformation from './ContactInformation';

import styles from './EnquiryWizard.module.scss';

export const EnquiryMutation = graphql`
    mutation EnquiryWizardMutation($input: EnquiryInput!) {
        enquiry(input: $input)
    }
`;

const SubmitEnquiryButton = ({ isLoading, submitEnquiry }) => {
    const enquiryContext = useEnquiryContext();
    const { t } = useTranslation();

    return (
        <Button
            style={ButtonStyle.PRIMARY}
            text={t('generics.next')}
            onClick={() =>
                submitEnquiry({
                    connection_from_terminal: enquiryContext.connectionFromTerminal || undefined,
                    connection_from_city: enquiryContext.connectionFromCity || undefined,
                    connection_to_terminal: enquiryContext.connectionToTerminal || undefined,
                    connection_to_city: enquiryContext.connectionToCity || undefined,
                    transport_unit_type: enquiryContext.transportUnitType,
                    transport_unit_amount: enquiryContext.transportUnitAmount,
                    transport_dangerous_goods_class:
                        enquiryContext.transportDangerousGoodsClass || undefined,
                    transport_frequency: enquiryContext.transportFrequency,
                    transport_weight: enquiryContext.transportWeight || undefined,
                    transport_date: enquiryContext.transportDate || undefined,
                    message: enquiryContext.message || undefined,
                    cc: enquiryContext.cc || undefined,
                })
            }
            disabled={isLoading}
            block
        />
    );
};

const QuotationRequested = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.ReadyStage}>
            <span className={styles.CheckMarkIconContainer}>
                <CheckmarkIcon className={styles.CheckMarkIcon} />
            </span>
            <span className={styles.ConfirmationText}>{t('enquiry.enquirySaved')}</span>
        </div>
    );
};

const EnquiryWizard = () => {
    const { t } = useTranslation();
    const [currentStage, setCurrentStage] = useState(0);
    const [error, setError] = useState('');
    const [commitEnquiry, isLoading] = useMutation(EnquiryMutation);
    const isFormValid = useConnectionFormValidator();
    const { setFields } = useEnquiryContext();

    const createEnquiry = useCallback(
        (input) => {
            commitEnquiry({
                variables: { input },
                onError: (networkError) => {
                    const message =
                        networkError instanceof Error
                            ? networkError.message
                            : t('generics.unexpectedError');
                    setError(message);
                },
                onCompleted: (_, errors) => {
                    if (errors?.length) {
                        const [mutationError] = errors;
                        if (mutationError.extensions.validation) {
                            const { validation } = mutationError.extensions;
                            setFields({
                                connectionFromTerminalError:
                                    validation['input.connection_from_terminal']?.[0] ?? '',
                                connectionFromCityError:
                                    validation['input.connection_from_city']?.[0] ?? '',
                                connectionToTerminalError:
                                    validation['input.connection_to_terminal']?.[0] ?? '',
                                connectionToCityError:
                                    validation['input.connection_to_city']?.[0] ?? '',
                                transportUnitTypeError:
                                    validation['input.transport_unit_type']?.[0] ?? '',
                                transportUnitAmountError:
                                    validation['input.transport_unit_amount']?.[0] ?? '',
                                transportDangerousGoodsClassError:
                                    validation['input.transport_dangerous_goods_class']?.[0] ?? '',
                                transportFrequencyError:
                                    validation['input.transport_frequency']?.[0] ?? '',
                                transportWeightError:
                                    validation['input.transport_weight']?.[0] ?? '',
                                transportDateError: validation['input.transport_date']?.[0] ?? '',
                                ccError: validation['input.cc']?.[0] ?? '',
                            });
                            if (Object.keys(validation).length === 1 && validation['input.cc']) {
                                setCurrentStage(1);
                            } else {
                                setCurrentStage(0);
                            }
                        } else {
                            setError(mutationError.message ?? t('generics.unexpectedError'));
                        }
                    } else {
                        setCurrentStage((current) => current + 1);
                    }
                },
            });
        },
        [commitEnquiry, t, setFields]
    );

    const stages = useMemo(
        () => [
            {
                component: <Connection />,
                buttons: [
                    <Button
                        style={ButtonStyle.PRIMARY}
                        text={t('generics.next')}
                        onClick={() => setCurrentStage((current) => current + 1)}
                        disabled={!isFormValid()}
                        block
                    />,
                ],
            },
            {
                component: <ContactInformation />,
                buttons: [
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('generics.back')}
                        onClick={() => setCurrentStage((current) => current - 1)}
                        block
                    />,
                    <SubmitEnquiryButton isLoading={isLoading} submitEnquiry={createEnquiry} />,
                ],
            },
            {
                component: <QuotationRequested />,
                buttons: [],
            },
        ],
        [createEnquiry, t, isFormValid, isLoading]
    );

    return (
        <div data-testid='EnquiryWizard'>
            {currentStage < 2 && (
                <Steps
                    labels={[t('enquiry.connection'), t('enquiry.contactInfo')]}
                    activeStep={currentStage}
                />
            )}
            {stages[currentStage].component}
            <div className={styles.ButtonsContainer}>
                {stages[currentStage].buttons.map((button, idx) => (
                    <div key={`${currentStage}-${idx}`} className={styles.ButtonWrapper}>
                        {button}
                    </div>
                ))}
            </div>
            {error && (
                <Alert className={styles.ErrorMessage} type={AlertType.ERROR}>
                    {error}
                </Alert>
            )}
        </div>
    );
};

const withContext = (Component) => (props) =>
    (
        <EnquiryContextWrapper>
            <Component {...props} />
        </EnquiryContextWrapper>
    );

EnquiryWizard.propTypes = {};

export default withContext(EnquiryWizard);
