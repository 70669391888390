import classNames from 'classnames';
import React, { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useRoutes, { useTabbedRoutes } from '../../routes/useRoutes';
import Button from '../Button/Button';
import { CrossIcon, ArrowLeftIcon, ArrowRightIcon } from '../Icon/Icon';
import NavigationItem, { NavItemVariant } from './NavigationItem';
import { TooltipPosition } from '../Tooltip/Tooltip';
import NavigationItemGroup from './NavigationItemGroup';
import { Features, useAppConfigContext } from '../../contexts/AppConfigContext';
import IconWithTooltip from './IconWithTooltip';
import { useLogout } from '../../hooks/useAuth';

import styles from './Navigation.module.scss';

const Navigation = (props) => {
    const { navigation, navigationBottom, getNavigationItemByFeature, features } =
        useAppConfigContext();
    const { isTabOpen } = useTabbedRoutes();
    const { getFeaturePath } = useRoutes(features);
    const logout = useLogout();
    const location = useLocation();
    const navigate = useNavigate();
    const [isClosed, setIsClosed] = useState(false);
    const [isTempOpen, setIsTempOpen] = useState(false);
    const { t } = useTranslation();
    const [tempCloseTimeoutId, setTempCloseTimeoutId] = useState(undefined);

    const collapsed = props.isMobileView ? !props.isMobileNavOpen : isClosed && !isTempOpen;

    const openTemporary = useCallback(() => {
        if (collapsed) {
            setIsTempOpen(true);
        }
    }, [collapsed]);

    const open = useCallback(() => {
        clearTimeout(tempCloseTimeoutId);
        setIsTempOpen(false);
        setIsClosed(false);
    }, [tempCloseTimeoutId]);

    const cancelDelayedClose = useCallback(() => {
        clearTimeout(tempCloseTimeoutId);
        setTempCloseTimeoutId(undefined);
    }, [tempCloseTimeoutId]);

    const onAction = props.onAction;

    const close = useCallback(() => {
        cancelDelayedClose();
        setIsTempOpen(false);
        setIsClosed(true);
        onAction && onAction();
    }, [cancelDelayedClose, onAction]);

    const scheduleDelayedClose = useCallback(() => {
        const timeoutId = setTimeout(close, 1500);
        setTempCloseTimeoutId(timeoutId);
    }, [close]);

    const mapIcon = useCallback((icon) => {
        const knownIcons = {
            INFO: 'info',
            MESSAGE: 'newspaper',
            TOOL: 'toolbox',
            PACKAGE: 'package',
            USERS: 'users',
            DOWNLOAD: 'downloads',
            INTERNAL: 'intern',
        };

        return knownIcons[icon];
    }, []);

    const mapNavItemVariant = useCallback((navItem) => {
        if (navItem.icon === 'INTERNAL') {
            return NavItemVariant.LIGHT;
        }
        return undefined;
    }, []);

    const renderNavigationItem = (item) => {
        if (item.children) {
            return item.children.length ? (
                <NavigationItemGroup
                    key={item.label}
                    onClick={() => openTemporary()}
                    iconOnly={collapsed}
                    icon={mapIcon(item.icon)}
                    title={item.label}
                    isMobileView={props.isMobileView}
                >
                    {item.children.map(renderNavigationItem)}
                </NavigationItemGroup>
            ) : null;
        } else {
            const path = getFeaturePath(item);
            return (
                <NavigationItem
                    key={path}
                    active={location.pathname === path}
                    open={isTabOpen(item)}
                    iconOnly={collapsed}
                    icon={mapIcon(item.icon)}
                    title={item.label}
                    isMobileView={props.isMobileView}
                    onClick={() => {
                        navigate(path);
                        onAction && onAction();
                    }}
                    rightIcon={
                        item.tooltip?.icon && (
                            <IconWithTooltip
                                icon={item.tooltip.icon}
                                message={item.tooltip.message}
                                isMobileView={props.isMobileView}
                            />
                        )
                    }
                    variant={mapNavItemVariant(item)}
                />
            );
        }
    };

    const onlineBookingItem = getNavigationItemByFeature(Features.ONLINE_BOOKING);

    return (
        <div
            className={classNames(props.className, styles.Navigation, {
                [styles.Collapsed]: collapsed,
                [styles.NavigationMobile]: props.isMobileView,
            })}
            data-testid='Navigation'
            onMouseLeave={() => {
                if (isTempOpen) {
                    scheduleDelayedClose();
                }
            }}
            onMouseEnter={() => {
                if (isTempOpen) {
                    cancelDelayedClose();
                }
            }}
        >
            {!!props.isMobileView && (
                <div className={classNames(styles.NavigationMobileCloseContainer)}>
                    <CrossIcon
                        className={classNames(styles.NavigationMobileClose)}
                        onClick={() => {
                            close();
                        }}
                    />
                </div>
            )}
            <div className={styles.ScrollableArea}>
                <div
                    className={classNames({
                        [styles.Button]: true,
                        [styles.ButtonWithIcon]: !!onlineBookingItem.tooltip?.icon,
                        [styles.ButtonRestricted]: !!onlineBookingItem.restricted,
                    })}
                >
                    <Button
                        icon='add'
                        text={collapsed ? null : t('navigation.newBooking')}
                        onClick={() => {
                            navigate(`/new-booking/${Date.now()}`);
                            onAction && onAction();
                        }}
                        block
                    />
                    {onlineBookingItem.tooltip?.icon && (
                        <IconWithTooltip
                            className={
                                props.isMobileView
                                    ? styles.NavigationBookingIconMobile
                                    : styles.NavigationBookingIcon
                            }
                            icon={onlineBookingItem.tooltip.icon}
                            message={onlineBookingItem.tooltip.message}
                            isMobileView={props.isMobileView}
                            tooltipPosition={TooltipPosition.RIGHT}
                        />
                    )}
                </div>
                <div className={styles.NavigationItems}>{navigation.map(renderNavigationItem)}</div>
            </div>
            <div className={styles.Bottom}>
                {navigationBottom.map(renderNavigationItem)}
                <NavigationItem
                    iconOnly={collapsed}
                    icon='logout'
                    title={t('navigation.logout')}
                    isMobileView={props.isMobileView}
                    onClick={() => logout()}
                />
                {!props.isMobileView && (
                    <div className={styles.Toggle} onClick={() => (collapsed ? open() : close())}>
                        {collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                    </div>
                )}
            </div>
        </div>
    );
};

Navigation.propTypes = {
    isMobileView: PropTypes.bool,
    isMobileNavOpen: PropTypes.bool,
};
export default Navigation;
