import React from 'react';

const ComponentCacheContext = React.createContext({
    cachedComponents: {},
    activateComponent: (id, Component) => undefined,
    deactivateComponent: (id) => undefined,
    disposeComponent: (id) => undefined,
});

export default ComponentCacheContext;
