import React, { useContext } from 'react';
import Modal from './Modal';
import ModalsContext from '../../contexts/ModalsContext';

const ModalManager = () => {
    const { modal, closeModal } = useContext(ModalsContext);

    return (
        <Modal
            isOpen={modal !== undefined}
            title={modal?.title}
            size={modal?.size}
            onClose={closeModal}
        >
            {modal?.content}
        </Modal>
    );
};

ModalManager.propTypes = {};

export default ModalManager;
