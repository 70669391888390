import graphql from 'babel-plugin-relay/macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchQuery, useMutation, useRelayEnvironment } from 'react-relay';
import Alert, { AlertType } from '../Alert/Alert';
import Button, { ButtonStyle } from '../Button/Button';
import Input from '../Form/Input/Input';
import styles from './UpsertBookletForm.module.scss';
import Select from '../Form/Select/Select';
import CheckBox from '../Form/CheckBox/CheckBox';
import { Link } from 'react-router-dom';
import Connections from '../Connections/Connections';
import { bookletMapper, BookletQuery } from './useEditBooklet';
import { useNavigate } from 'react-router-dom';
import { encodeExportBooklet } from '../Booklets/BookletExport/BookletExport';

export const UpsertBookletMutation = graphql`
    mutation UpsertBookletFormMutation($id: ID, $input: UpsertBookletInput!) {
        upsertBooklet(id: $id, input: $input) {
            title
            type
            include_other_information
            include_general_information
            timeframe
            from
            to
            group_returns
            routes
            internal_id
        }
    }
`;

const formatDate = (dateString) => {
    if (dateString && dateString !== '') {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    return '';
};

const UpsertBookletForm = (props) => {
    const navigate = useNavigate();
    const environment = useRelayEnvironment();
    const { t } = useTranslation();
    const [bookletToEdit, setBookletToEdit] = useState(props.booklet ?? undefined);

    const [success, setSuccess] = useState(false);
    const [bookletId, setBookletId] = useState('');
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [includeOtherInformation, setIncludeOtherInformation] = useState(false);
    const [includeGeneralInformation, setIncludeGeneralInformation] = useState(false);
    const [timeframe, setTimeframe] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [groupReturns, setGroupReturns] = useState(false);
    const [isConnectionAddDisabled, setIsConnectionAddDisabled] = useState(true);

    const [error, setError] = useState('');
    const [fromError, setFromError] = useState('');
    const [toError, setToError] = useState('');
    const [titleError, setTitleError] = useState('');

    const [upsertBooklet, isLoading] = useMutation(UpsertBookletMutation);
    const apiUrl = process.env.REACT_APP_API_URL;

    //set or reset fields if edit case
    useEffect(() => {
        if (bookletToEdit) {
            setBookletId(bookletToEdit.internal_id);
            setTitle(bookletToEdit.title);
            setType(bookletToEdit.type);
            setIncludeOtherInformation(bookletToEdit.include_other_information);
            setIncludeGeneralInformation(bookletToEdit.include_general_information);
            setTimeframe(bookletToEdit.timeframe);
            setFrom(bookletToEdit.from);
            setTo(bookletToEdit.to);
            setGroupReturns(bookletToEdit.group_returns);
            setIsConnectionAddDisabled(!bookletToEdit.internal_id);
        }
    }, [bookletToEdit]);

    useEffect(() => {
        if (type === 'default') {
            setTimeframe('');
            setFrom('');
            setTo('');
            setGroupReturns(false);
        } else if (type === 'daily') {
            setGroupReturns(false);
        } else if (type === 'dailyOverview') {
            setTimeframe('');
            setIncludeOtherInformation(false);
            setIncludeGeneralInformation(false);
        }
    }, [type]);

    useEffect(() => {
        if (type === 'daily' && timeframe === 'currentPeriod') {
            setFrom('');
            setTo('');
        }
    }, [timeframe, type]);

    useEffect(() => {
        let booklet = bookletToEdit;
        if (booklet && booklet.internal_id) {
            if (
                booklet.type !== type ||
                booklet.title !== title ||
                (booklet.timeframe !== timeframe && booklet.timeframe !== null) ||
                booklet.include_other_information !== includeOtherInformation ||
                booklet.include_general_information !== includeGeneralInformation ||
                (booklet.from !== from && booklet.from !== null) ||
                (booklet.to !== to && booklet.to !== null) ||
                booklet.group_returns !== groupReturns
            ) {
                setIsConnectionAddDisabled(true);
            } else {
                setIsConnectionAddDisabled(false);
            }
        }
    }, [
        title,
        type,
        timeframe,
        includeOtherInformation,
        includeGeneralInformation,
        from,
        to,
        groupReturns,
        bookletToEdit,
    ]);

    const isDisabled = (): boolean => {
        return (
            title === '' ||
            type === '' ||
            (type === 'daily' &&
                (timeframe === '' || (timeframe === 'custom' && (from === '' || to === '')))) ||
            (type === 'dailyOverview' && (from === '' || to === ''))
        );
    };

    const isEditDisabled = (): boolean => {
        let booklet = bookletToEdit;
        if (booklet && booklet.internal_id) {
            return !(
                booklet.type !== type ||
                booklet.title !== title ||
                (booklet.timeframe !== timeframe && booklet.timeframe !== null) ||
                booklet.include_other_information !== includeOtherInformation ||
                booklet.include_general_information !== includeGeneralInformation ||
                (booklet.from !== from && booklet.from !== null) ||
                (booklet.to !== to && booklet.to !== null) ||
                booklet.group_returns !== groupReturns
            );
        }
        return false;
    };

    const navigateAfterSuccess = useCallback(
        (internalId) => {
            navigate(`booklet/edit/${internalId}`);
        },
        [navigate]
    );

    const reFetchData = useCallback(() => {
        if (bookletId) {
            fetchQuery(environment, BookletQuery, { id: bookletId })
                .toPromise()
                .then((response) => {
                    setBookletToEdit(bookletMapper(response.booklet));
                })
                .catch((error) => {
                    console.error('Error reFetching data: ', error);
                });
        }
    }, [environment, bookletId]);

    const submitForm = useCallback(() => {
        setSuccess('');
        setError('');
        setFromError('');
        setToError('');
        setTitleError('');
        upsertBooklet({
            variables: {
                id: bookletToEdit?.internal_id,
                input: {
                    title,
                    type,
                    include_other_information: includeOtherInformation,
                    include_general_information: includeGeneralInformation,
                    timeframe,
                    from: formatDate(from),
                    to: formatDate(to),
                    group_returns: groupReturns,
                },
            },
            onError: (error) => {
                let errors = error.source.errors;
                if (errors?.length) {
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        let errorMessage = mutationError.extensions.validation;
                        let errorsString = '';

                        let titleErrors = errorMessage['input.title'];
                        if (titleErrors) {
                            titleErrors.forEach((titleError) => {
                                if (
                                    titleError ===
                                    'The title may have a maximum length of 35 characters'
                                ) {
                                    errorsString +=
                                        t('booklet.theTitleMayHaveAMaximumLengthOf35Characters') +
                                        '<br>';
                                }
                                if (
                                    titleError ===
                                    'The title may only contain the following characters in addition to whitespaces: A-Z, a-z, 0-9'
                                ) {
                                    errorsString +=
                                        t('booklet.theTitleMayOnlyContainTheFollowingCharacters') +
                                        '<br>';
                                }
                            });
                        }

                        let toErrors = errorMessage['input.to'];
                        if (toErrors) {
                            toErrors.forEach((toError) => {
                                if (toError === 'Invalid combination Date from/to') {
                                    errorsString +=
                                        t('booklet.InvalidCombinationDateFromTo') + '<br>';
                                }
                                if (toError === 'The timeframe cannot exceed 35 days') {
                                    errorsString += t('booklet.timeframeWithIn35Days') + '<br>';
                                }
                            });
                        }

                        if (errorsString === '') {
                            errorsString = t('booklet.genericInputError');
                        }

                        setError(errorsString);
                    } else {
                        setError(mutationError.message ?? t('generics.unexpectedError'));
                    }
                } else {
                    const message =
                        error instanceof Error ? error.message : t('generics.unexpectedError');
                    setError(message);
                }
            },
            onCompleted: (response, errors) => {
                if (bookletId) {
                    setSuccess(true);
                    reFetchData();
                } else {
                    if (response?.upsertBooklet) {
                        const internalId = response.upsertBooklet?.internal_id;
                        navigateAfterSuccess(internalId);
                    }
                }
            },
        });
    }, [
        bookletToEdit,
        title,
        type,
        includeOtherInformation,
        includeGeneralInformation,
        timeframe,
        from,
        to,
        groupReturns,
        t,
        bookletId,
        navigateAfterSuccess,
        upsertBooklet,
        reFetchData,
    ]);

    return (
        <div className={styles.Container}>
            <h1 className={styles.PageTitle}>{t('navigation.personalTimetableBooklets')}</h1>
            <div className={styles.Section}>
                <Link to='/booklets'>
                    <Button style={ButtonStyle.SECONDARY} text={t('booklet.backToOverview')} />
                </Link>
                {success && (
                    <div className={styles.AlertSection}>
                        <Alert type={AlertType.INFO}>
                            {props.booklet?.internal_id
                                ? t('booklet.bookletUpdated')
                                : t('booklet.bookletCreated')}
                        </Alert>
                    </div>
                )}
                <form className={styles.UpsertBookletForm} data-testid='UpsertBookletForm'>
                    <h1>
                        {bookletToEdit?.internal_id
                            ? t('booklet.editTimetableBooklet')
                            : t('booklet.addNewTimetableBooklet')}
                    </h1>
                    <p className={styles.InfoMessage}>{t('booklet.fieldsMandatory')}</p>
                    <h3 className={styles.Heading}>
                        {bookletToEdit?.internal_id
                            ? t('booklet.editTimetable')
                            : t('booklet.basicInformation')}
                    </h3>
                    <div className={styles.TwoSections}>
                        <div
                            className={`${styles.FormRow} ${
                                type === 'daily'
                                    ? styles.FormRowGrid3
                                    : type === 'default'
                                    ? styles.FormRowGrid3
                                    : styles.FormRowGrid3
                            }`}
                        >
                            <Input
                                className={styles.Child1}
                                label={t('booklet.title')}
                                value={title}
                                onChange={(value) => setTitle(value)}
                                error={titleError}
                                required
                            />
                            <Select
                                className={styles.Child2}
                                placeholder={t('generics.pleaseSelect')}
                                label={t('booklet.type')}
                                value={type}
                                onChange={(value) => setType(value)}
                                options={[
                                    { label: t('booklet.typeOptions.default'), value: 'default' },
                                    { label: t('booklet.typeOptions.daily'), value: 'daily' },
                                    {
                                        label: t('booklet.typeOptions.dailyOverview'),
                                        value: 'dailyOverview',
                                    },
                                ]}
                                required
                            />

                            {type === 'daily' ? (
                                <Select
                                    className={styles.Child3}
                                    placeholder={t('generics.pleaseSelect')}
                                    label={t('booklet.timeframe')}
                                    onChange={(value) => setTimeframe(value)}
                                    value={timeframe}
                                    options={[
                                        {
                                            label: t('booklet.timeframeOptions.custom'),
                                            value: 'custom',
                                        },
                                        {
                                            label: t('booklet.timeframeOptions.currentPeriod'),
                                            value: 'currentPeriod',
                                        },
                                    ]}
                                    required
                                />
                            ) : null}

                            {type === 'dailyOverview' ||
                            (type === 'daily' && timeframe === 'custom') ? (
                                <div
                                    className={`${
                                        type === 'daily' ? styles.Child6 : styles.Child4
                                    }`}
                                >
                                    <Input
                                        className={styles.FromStyle}
                                        label={t('booklet.from')}
                                        value={from}
                                        onChange={(value) => setFrom(value)}
                                        error={fromError}
                                        type={'date'}
                                        required
                                    />

                                    <Input
                                        className={styles.ToStyle}
                                        label={t('booklet.to')}
                                        value={to}
                                        onChange={(value) => setTo(value)}
                                        error={toError}
                                        type={'date'}
                                        required
                                    />
                                </div>
                            ) : null}
                            {type === 'default' ? <div className={styles.Child3}></div> : null}
                            {type === 'default' || type === 'daily' ? (
                                <>
                                    <CheckBox
                                        className={`${
                                            type === 'daily' ? styles.Child4 : styles.Child4
                                        }`}
                                        checked={includeOtherInformation}
                                        label={t('booklet.includeOtherInformation')}
                                        onChange={(value) =>
                                            setIncludeOtherInformation(!includeOtherInformation)
                                        }
                                        readonly={false}
                                    />

                                    <CheckBox
                                        className={`${
                                            type === 'daily' ? styles.Child5 : styles.Child5
                                        }`}
                                        checked={includeGeneralInformation}
                                        label={t('booklet.includeGeneralInformation')}
                                        onChange={(value) =>
                                            setIncludeGeneralInformation(!includeGeneralInformation)
                                        }
                                        readonly={false}
                                    />
                                </>
                            ) : null}
                            {type === 'dailyOverview' ? (
                                <CheckBox
                                    className={styles.Child6}
                                    checked={groupReturns}
                                    label={t('booklet.groupReturns')}
                                    onChange={(value) => setGroupReturns(!groupReturns)}
                                    readonly={false}
                                />
                            ) : null}
                        </div>

                        <div className={styles.DivBookletButtons}>
                            <Button
                                style={ButtonStyle.SECONDARY}
                                text={t('booklet.saveData')}
                                onClick={() => submitForm()}
                                disabled={
                                    isDisabled() ||
                                    isLoading ||
                                    (success && !props.booklet?.internal_id) ||
                                    isEditDisabled()
                                }
                            />
                            <Link
                                to={`${
                                    apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl
                                }/export/${encodeExportBooklet(props.booklet?.internal_id)}`}
                                className={styles.CreatePDF}
                            >
                                <Button
                                    style={ButtonStyle.PRIMARY}
                                    text={t('booklet.createPDF')}
                                    disabled={
                                        isDisabled() ||
                                        isLoading ||
                                        !props.booklet?.internal_id ||
                                        !bookletToEdit?.routes ||
                                        bookletToEdit?.routes.length < 1
                                    }
                                />
                            </Link>
                        </div>
                    </div>
                    {error && (
                        <Alert type={AlertType.ERROR}>
                            <div dangerouslySetInnerHTML={{ __html: error }}></div>
                        </Alert>
                    )}
                </form>
                {bookletToEdit ? (
                    <Connections
                        isConnectionAddDisabled={isConnectionAddDisabled}
                        bookletRoutes={bookletToEdit?.routes}
                        booklet_id={bookletToEdit?.internal_id}
                        reloadData={reFetchData}
                    />
                ) : null}
            </div>
            <div></div>
        </div>
    );
};

UpsertBookletForm.propTypes = {};

export default UpsertBookletForm;
