import useEditBooklet from './useEditBooklet';
import Component from './UpsertBookletForm';

const EditBooklets = () => {
    const booklet = useEditBooklet();
    return <Component booklet={booklet} />;
};

EditBooklets.propTypes = {};

export default EditBooklets;
