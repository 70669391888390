import React, { useCallback, useContext, useState } from 'react';

const EnquiryContextWrapper = ({ children }) => {
    const [contextValue, setContextValue] = useState({
        connectionFromTerminal: undefined,
        connectionFromTerminalError: '',
        connectionFromCity: '',
        connectionFromCityError: '',
        connectionToTerminal: undefined,
        connectionToTerminalError: '',
        connectionToCity: '',
        connectionToCityError: '',
        // required
        transportUnitType: null,
        transportUnitTypeError: '',
        // required
        transportUnitAmount: null,
        transportUnitAmountError: '',
        transportDangerousGoodsClass: '',
        transportDangerousGoodsClassError: '',
        // required
        transportFrequency: null,
        transportFrequencyError: '',
        transportWeight: null,
        transportWeightError: '',
        transportDate: '',
        transportDateError: '',
        message: '',
        cc: '',
        ccError: '',
    });

    const setFields = useCallback(
        (newValues) =>
            setContextValue((currentValue) => ({
                ...currentValue,
                ...newValues,
            })),
        []
    );

    return (
        <EnquiryContext.Provider value={{ ...contextValue, setFields }}>
            {children}
        </EnquiryContext.Provider>
    );
};

export const EnquiryContext = React.createContext({
    connectionFromTerminal: null,
    connectionFromCity: '',
    connectionToTerminal: null,
    connectionToCity: '',
    transportUnitType: null,
    transportUnitAmount: null,
    transportDangerousGoodsClass: '',
    transportFrequency: null,
    transportWeight: null,
    transportDate: null,
    message: '',
    cc: '',
    setFields: () => undefined,
});

export const useEnquiryContext = () => {
    return useContext(EnquiryContext);
};

export default EnquiryContextWrapper;
