import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay';
import Component from '../Terminals/Terminals';

export const TerminalsQuery = graphql`
    query TerminalsQuery($periods: [Int!], $hasActiveStorageConditions: Boolean) {
        terminals(periods: $periods, hasActiveStorageConditions: $hasActiveStorageConditions) {
            id
            name
            hafasId
            country {
                name
            }
        }
    }
`;

const Terminals = (props) => {
    const { terminals } = usePreloadedQuery(TerminalsQuery, props.preloadedQuery);
    return <Component terminals={terminals} />;
};

Terminals.propTypes = {
    preloadedQuery: PropTypes.object,
};

export default Terminals;

export const TerminalsBy = (props) => {
    const [preloadedQuery, loadTerminalsQuery] = useQueryLoader(TerminalsQuery);

    useEffect(() => {
        loadTerminalsQuery({ periods: props.periods, hasActiveStorageConditions: true }, {});
    }, [loadTerminalsQuery, props.periods]);

    return preloadedQuery ? <Terminals preloadedQuery={preloadedQuery} /> : null;
};
