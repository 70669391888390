import { useTranslation } from 'react-i18next';
import Button, { ButtonStyle } from '../Button/Button';
import Dialog from '../Dialog/Dialog';
import DialogType from '../Dialog/DialogType';

const ConfirmDeleteBookletDialog = ({ onCancel, onConfirm, bookletTitle }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            title={t('booklet.deleteBookletDialogTitle')}
            type={DialogType.WARNING}
            message={t('booklet.deleteBookletDialogPleaseConfirm', {
                bookletTitle: bookletTitle,
            })}
            onClose={onCancel}
            actions={
                <>
                    <Button
                        style={ButtonStyle.PRIMARY}
                        text={t('booklet.deleteBookletDialogConfirmButton')}
                        onClick={onConfirm}
                    />
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('generics.cancelButton')}
                        onClick={onCancel}
                    />
                </>
            }
        />
    );
};

export default ConfirmDeleteBookletDialog;
