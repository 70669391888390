import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import useSnakeToCamelCase from '../../hooks/useSnakeToCamelCase';
import { downloadMapper } from '../Downloads/useDownloads';
import Component from './DownloadsOverview';

export const DownloadsOverviewQuery = graphql`
    query DownloadsOverviewQuery {
        downloadCards {
            name
            meta_column
            downloads {
                id
                name
                url
                type
                booklet_id
                file_name
                file_type
                file_size
                updated_at
                updated_at_diff
                main_category {
                    id
                    name
                }
                categoryAssignments(first: 20) {
                    edges {
                        node {
                            category {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DownloadsOverview = () => {
    const convertSnakeCase = useSnakeToCamelCase();
    const data = useLazyLoadQuery(DownloadsOverviewQuery);
    const cards = data.downloadCards.map(({ name, meta_column, downloads }) => ({
        name,
        metaField: convertSnakeCase(meta_column),
        downloads: downloads.map(downloadMapper),
    }));

    return <Component cards={cards} />;
};

DownloadsOverview.propTypes = {};

export default DownloadsOverview;
