import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styles from './CheckBox.module.scss';

const CheckBox = ({
    checked = false,
    className = '',
    label = '',
    onChange = () => undefined,
    readonly = false,
}) => {
    const [isChecked, setIsChecked] = useState(Boolean(checked));

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleChange = ({ target }) => {
        if (readonly) return false;
        setIsChecked(target.checked);
        onChange(target.checked);
    };

    return (
        <label
            data-testid='CheckBox'
            className={classNames({
                [styles.CheckBox]: true,
                [className]: className,
                [styles.ReadOnly]: readonly,
            })}
        >
            <input type='checkbox' checked={isChecked} onChange={handleChange} />
            <span dangerouslySetInnerHTML={{ __html: label }} />
        </label>
    );
};

CheckBox.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    readonly: PropTypes.bool,
};

export default CheckBox;
