import { useTranslation } from 'react-i18next';
import { useAppConfigContext } from '../../contexts/AppConfigContext';
import { useEnquiryContext } from '../../contexts/EnquiryContext';
import Input from '../Form/Input/Input';
import Select from '../Form/Select/Select';

import styles from './TransportDetailsForm.module.scss';

const TransportDetailsForm = (props) => {
    const { t } = useTranslation();
    const {
        transportUnitType,
        transportUnitTypeError,
        transportUnitAmount,
        transportUnitAmountError,
        transportDangerousGoodsClass,
        transportDangerousGoodsClassError,
        transportFrequency,
        transportFrequencyError,
        transportWeight,
        transportWeightError,
        transportDate,
        transportDateError,
        setFields,
    } = useEnquiryContext();

    const {
        options: {
            transportFrequencies,
            transportUnitAmounts,
            transportUnitTypes,
            transportWeights,
        },
    } = useAppConfigContext();

    return (
        <div className={styles.TransportDetailsForm} data-testid='TransportDetailsForm'>
            <Select
                label={t('enquiry.loadingUnitLabel')}
                options={transportUnitTypes}
                value={transportUnitType}
                onChange={(value) => setFields({ transportUnitType: value })}
                error={transportUnitTypeError}
                required
            />
            <Input
                label={t('enquiry.hazardClassLabel')}
                value={transportDangerousGoodsClass}
                onChange={(value) => setFields({ transportDangerousGoodsClass: value })}
                error={transportDangerousGoodsClassError}
            />
            <Select
                label={t('enquiry.amountLabel')}
                options={transportUnitAmounts}
                value={transportUnitAmount}
                onChange={(value) => setFields({ transportUnitAmount: value })}
                error={transportUnitAmountError}
                required
            />
            <Select
                label={t('enquiry.frequencyLabel')}
                options={transportFrequencies}
                value={transportFrequency}
                onChange={(value) => setFields({ transportFrequency: value })}
                error={transportFrequencyError}
                required
            />
            <Select
                label={t('enquiry.estimatedWeightLabel')}
                options={transportWeights}
                value={transportWeight}
                onChange={(value) => setFields({ transportWeight: value })}
                error={transportWeightError}
            />
            <Input
                className={styles.ShippingDateInput}
                label={t('enquiry.shippingDateLabel')}
                type='date'
                value={transportDate}
                onChange={(value) => setFields({ transportDate: value })}
                error={transportDateError}
            />
        </div>
    );
};

TransportDetailsForm.propTypes = {};
export default TransportDetailsForm;
