import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import styles from './Button.module.scss';

export const ButtonStyle = Object.freeze({
    PRIMARY: 1,
    SECONDARY: 2,
});

export const ButtonSize = Object.freeze({
    SMALL: 1,
    MEDIUM: 2,
});

const Button = ({
    style = ButtonStyle.PRIMARY,
    size = ButtonSize.MEDIUM,
    disabled = false,
    block = false,
    multiline = false,
    type = 'button',
    icon = '',
    text = '',
    extraClasses = '',
    isMouseOver = false,
    ...props
}) => {
    const [isMouseOverComputed, setIsMouseOverComputed] = useState(isMouseOver);

    useEffect(() => {
        setIsMouseOverComputed(isMouseOver);
    }, [isMouseOver]);

    const computedIcon = icon
        ? React.createElement(Icon[icon], {
              className: styles.ButtonIcon,
          })
        : null;

    return (
        <div
            data-testid='Button'
            className={classNames(styles.ButtonContainer, { [styles.Block]: block })}
            onMouseEnter={() => setIsMouseOverComputed(true)}
            onMouseLeave={() => setIsMouseOverComputed(false)}
        >
            <button
                className={classNames(styles.Button, extraClasses, {
                    [styles.Primary]: style === ButtonStyle.PRIMARY,
                    [styles.Secondary]: style === ButtonStyle.SECONDARY,
                    [styles.Medium]: size === ButtonSize.MEDIUM,
                    [styles.Small]: size === ButtonSize.SMALL,
                    [styles.Multiline]: multiline,
                })}
                disabled={disabled}
                onClick={props.onClick}
                type={type}
            >
                {computedIcon} {text}
            </button>
            {props.tooltip && isMouseOverComputed && (
                <div className={styles.Tooltip}>{props.tooltip}</div>
            )}
        </div>
    );
};

Button.propTypes = {
    style: PropTypes.oneOf(Object.values(ButtonStyle)),
    size: PropTypes.oneOf(Object.values(ButtonSize)),
    text: PropTypes.string,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    icon: PropTypes.string,
    tooltip: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    multiline: PropTypes.bool,
    extraClasses: PropTypes.string,
    isMouseOver: PropTypes.bool,
};

export default Button;
