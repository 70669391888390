import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../Input/Input';
import CountryPhoneCodeSelect from '../Select/CountryPhoneCodeSelect.relay';

import styles from './PhoneNumberPicker.module.scss';

const PhoneNumberPicker = ({
    composed = '',
    className = '',
    prefix = '',
    prefixError = '',
    countryCode = '',
    countryCodeError = '',
    extension = '',
    extensionError = '',
    phoneNumber = '',
    phoneNumberError = '',
    onChange: propOnChange = () => undefined,
    hasExtension = false,
    required = false,
    ...props
}) => {
    const { t } = useTranslation();
    const onChange = (part) => {
        propOnChange({
            countryCode: countryCode,
            prefix: prefix,
            phoneNumber: phoneNumber,
            extension: extension,
            ...part,
        });
    };

    return props.readonly ? (
        <Input
            label={props.label}
            type='text'
            value={composed}
            required={required}
            className={className}
            readonly
        />
    ) : (
        <div className={`${styles.PhoneNumberPicker} ${className}`} data-testid='PhoneNumberPicker'>
            <div>
                <CountryPhoneCodeSelect
                    value={countryCode}
                    onChange={(countryCode) => onChange({ countryCode: countryCode ?? null })}
                    error={countryCodeError}
                    required={required}
                    label={t('profile.countryPhoneCodeSelectionLabel')}
                />
                <Input
                    label={props.prefixLabel}
                    value={prefix}
                    onChange={(prefix) => onChange({ prefix })}
                    placeholder='6084'
                    error={prefixError}
                    required={required}
                />
            </div>
            <div>
                <Input
                    label={t('profile.phoneNumberLabel')}
                    value={phoneNumber}
                    onChange={(phoneNumber) => onChange({ phoneNumber })}
                    placeholder='12345'
                    error={props.phoneNumberError}
                    required={required}
                />
                {hasExtension && (
                    <>
                        <span className={styles.Separator}>-</span>
                        <Input
                            label={t('profile.phoneExtensionLabel')}
                            value={extension}
                            onChange={(extension) => onChange({ extension })}
                            error={extensionError}
                            placeholder='12'
                        />
                    </>
                )}
            </div>
        </div>
    );
};

PhoneNumberPicker.propTypes = {
    composed: PropTypes.string,
    className: PropTypes.string,
    prefix: PropTypes.string,
    prefixError: PropTypes.string,
    prefixLabel: PropTypes.string.isRequired,
    countryCode: PropTypes.string,
    countryCodeError: PropTypes.string,
    extension: PropTypes.string,
    extensionError: PropTypes.string,
    label: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    phoneNumberError: PropTypes.string,
    onChange: PropTypes.func,
    hasExtension: PropTypes.bool,
    required: PropTypes.bool,
};

export default PhoneNumberPicker;
