import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrowDownIcon, ArrowUpIcon, EmailIcon, PhoneIcon, SmartphoneIcon } from '../Icon/Icon';
import Flag from '../Flag/Flag';
import Tag from '../Tag/Tag';
import TagsContext from '../../contexts/TagsContext';

import styles from './Contact.module.scss';

const Contact = (props) => {
    const [expanded, setExpanded] = useState(false);
    const { tags } = useContext(TagsContext);

    const avatarUrl = props.avatarUrl ?? (props.type === 'TEAM' ? '/team.svg' : '/avatar.svg');

    return (
        <div
            className={classNames({
                [styles.Contact]: true,
                [styles.ContactMobile]: props.isMobileView,
                [styles.Expanded]: !!expanded,
            })}
            data-testid='Contact'
        >
            <div className={styles.Summary} onClick={() => setExpanded(!expanded)}>
                <img alt={props.name} className={styles.Image} src={avatarUrl} />
                <div className={styles.Text}>
                    <div className={styles.Name}>{props.name}</div>
                    {props.position && <div className={styles.Role}>{props.position}</div>}
                    {props.tags
                        .filter((tag) => tags.includes(tag))
                        .map((tag, idx) => (
                            <Tag key={idx} text={tag} className={styles.Tag} />
                        ))}
                </div>
                {expanded ? (
                    <ArrowUpIcon className={styles.ArrowIcon} />
                ) : (
                    <ArrowDownIcon className={styles.ArrowIcon} />
                )}
            </div>
            <div className={styles.Detail}>
                {props.phoneNumber && (
                    <a href={`tel:${_toTelUrl(props.phoneNumber)}`}>
                        <PhoneIcon className={styles.DetailIcon} />
                        {props.phoneNumber}
                    </a>
                )}
                {props.mobileNumber && (
                    <a href={`tel:${_toTelUrl(props.mobileNumber)}`}>
                        <SmartphoneIcon className={styles.DetailIcon} />
                        {props.mobileNumber}
                    </a>
                )}
                {props.email && (
                    <div>
                        <EmailIcon className={styles.DetailIcon} />
                        <a href={`mailto:${props.email}`}>{props.email}</a>
                    </div>
                )}
                <div>
                    {props.languages.map((language) => (
                        <Flag key={language} cc={language} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const contactProps = {
    isMobileView: PropTypes.bool,
    type: PropTypes.string,
    email: PropTypes.string,
    avatarUrl: PropTypes.string,
    phoneNumber: PropTypes.string,
    mobileNumber: PropTypes.string,
    name: PropTypes.string.isRequired,
    position: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    languages: PropTypes.arrayOf(PropTypes.string),
};

Contact.propTypes = contactProps;
export const ContactProps = PropTypes.shape(contactProps);
export default Contact;

// transform string to tel format
// allow only + and nubers
function _toTelUrl(number) {
    return number.replace(/[^0-9+]/g, '');
}
