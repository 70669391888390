import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useDynamicNavigate = () => {
    const navigate = useNavigate();

    const isInternal = useCallback((target) => !!target.match(/^\/[^/]/), []);

    return useCallback(
        (target) => {
            if (isInternal(target)) {
                navigate(target);
            } else {
                window.location.href = target;
            }
        },
        [isInternal, navigate]
    );
};

export default useDynamicNavigate;
