import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Alert, { AlertStyle, AlertType } from '../Alert/Alert';
import { ErrorIcon, InfoIcon, PasswordIcon, WarningIcon } from '../Icon/Icon';
import Tooltip, { TooltipPosition } from '../Tooltip/Tooltip';

import styles from './IconWithTooltip.module.scss';
import classNames from 'classnames';

const iconConfig = {
    LOCK: {
        icon: <PasswordIcon className={styles.Icon} />,
        color: 'transparent',
        tooltipType: AlertType.INFO,
    },
    INFO: {
        icon: <InfoIcon className={styles.Icon} />,
        color: 'blue',
        tooltipType: AlertType.INFO,
    },
    WARNING: {
        icon: <WarningIcon className={styles.Icon} />,
        color: 'yellow',
        tooltipType: AlertType.WARNING,
    },
    ERROR: {
        icon: <ErrorIcon className={styles.Icon} />,
        color: 'red',
        tooltipType: AlertType.ERROR,
    },
    RESTRICTED: {
        icon: <PasswordIcon className={styles.Icon} />,
        color: 'yellow',
        tooltipType: AlertType.WARNING,
    },
};

const IconWithTooltip = ({ className = '', tooltipPosition = TooltipPosition.RIGHT, ...props }) => {
    const iconEl = useRef();
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const { icon, color, tooltipType } = iconConfig[props.icon];

    return (
        <span
            className={classNames({
                [className]: true,
                [styles.IconContainer]: true,
                [styles.IconContainerMobile]: props.isMobileView,
                [styles.IconContainerTransparent]: color === 'transparent',
                [styles.IconContainerRed]: color === 'red',
                [styles.IconContainerYellow]: color === 'yellow',
                [styles.IconContainerBlue]: color === 'blue',
            })}
            data-testid='RestrictionIcon'
            onMouseEnter={props.isMobileView ? null : () => setIsTooltipVisible(true)}
            onMouseLeave={props.isMobileView ? null : () => setIsTooltipVisible(false)}
            ref={iconEl}
        >
            {icon}
            {props.message && (
                <Tooltip
                    element={iconEl.current}
                    visible={isTooltipVisible}
                    className={styles.Tooltip}
                    position={tooltipPosition}
                >
                    <Alert type={tooltipType} style={AlertStyle.TWO_COLOR} icon={icon}>
                        {props.message}
                    </Alert>
                </Tooltip>
            )}
        </span>
    );
};

IconWithTooltip.propTypes = {
    className: PropTypes.string,
    tooltipPosition: PropTypes.oneOf(Object.values(TooltipPosition)),
};

export default IconWithTooltip;
