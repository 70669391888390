import graphql from 'babel-plugin-relay/macro';
import { useState, useEffect } from 'react';
import { useMutation } from 'react-relay';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Alert, { AlertType } from '../Alert/Alert';
import Button, { ButtonStyle } from '../Button/Button';
import Input from '../Form/Input/Input';

import styles from './ChangeEmailForm.module.scss';

export const ChangeEmailMutation = graphql`
    mutation ChangeEmailFormMutation($input: UpdateEmailInput!) {
        updateEmail(input: $input) {
            crm_id
        }
    }
`;

const ChangeEmailForm = ({ email, onChange }) => {
    const { t } = useTranslation();
    const [isReadonly, setIsReadonly] = useState(true);
    const [newEmail, setNewEmail] = useState(email);
    const [newEmailConfirmation, setNewEmailConfirmation] = useState('');
    const [emailError, setEmailError] = useState('');
    const [emailConfirmationError, setEmailConfirmationError] = useState('');
    const [error, setError] = useState('');
    const [updateEmail, isLoading] = useMutation(ChangeEmailMutation);

    useEffect(() => setNewEmail(email), [email]);

    const submitForm = () => {
        setError('');
        setEmailError('');
        setEmailConfirmationError('');

        updateEmail({
            variables: {
                input: { email: newEmail },
            },
            onError: (networkError) => {
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        setEmailError(mutationError.extensions.validation['input.email'][0]);
                    } else {
                        setError(mutationError.message);
                    }
                } else {
                    onChange();
                    setIsReadonly(true);
                }
            },
        });
    };

    const resetForm = () => {
        setNewEmail(email);
        setNewEmailConfirmation('');
        setError('');
        setEmailError('');
        setEmailConfirmationError('');
    };

    return (
        <div className={styles.ChangeEmailForm} data-testid='ChangeEmailForm'>
            <form>
                <Input
                    label={isReadonly ? t('profile.emailLabel') : t('profile.newEmailLabel')}
                    value={isReadonly ? email : newEmail}
                    onChange={(value) => setNewEmail(value)}
                    type='email'
                    autocomplete='email'
                    readonly={isReadonly}
                    error={emailError}
                    required
                />
                {!isReadonly && (
                    <Input
                        label={t('profile.confirmNewEmailLabel')}
                        value={newEmailConfirmation}
                        onChange={(value) => setNewEmailConfirmation(value)}
                        type='email'
                        autocomplete='email'
                        placeholder={t('profile.confirmNewEmailPlaceholder')}
                        error={emailConfirmationError}
                        required
                    />
                )}
            </form>
            <Alert type={AlertType.WARNING}>{t('profile.editEmailAlert')}</Alert>
            {error && <Alert type={AlertType.ERROR}>{error}</Alert>}
            <div className={styles.FormButtons}>
                {isReadonly ? (
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('profile.editEmail')}
                        onClick={() => setIsReadonly(false)}
                        block
                    />
                ) : (
                    <>
                        <Button
                            style={ButtonStyle.PRIMARY}
                            text={t('profile.saveEmail')}
                            onClick={() => submitForm()}
                            disabled={newEmailConfirmation !== newEmail || isLoading}
                            block
                        />
                        <Button
                            style={ButtonStyle.SECONDARY}
                            text={t('generics.cancelButton')}
                            disabled={isLoading}
                            onClick={() => {
                                resetForm();
                                setIsReadonly(true);
                            }}
                            block
                        />
                    </>
                )}
            </div>
        </div>
    );
};

ChangeEmailForm.propTypes = {
    email: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ChangeEmailForm;
