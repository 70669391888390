import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { DownloadsIcon } from '../Icon/Icon';
import { useTransforms } from '../Downloads/useDownloads';
import Row from '../Row/Row';

import styles from './DownloadItem.module.scss';
import { t } from 'i18next';

const DownloadItem = ({ metaField = null, ...props }) => {
    const { bytesToString } = useTransforms();
    const navigate = useNavigate();

    const fileInfo =
        props.type === 'booklet'
            ? props.fileType === 'xml'
                ? t('downloads.xml')
                : t('downloads.pdf')
            : `${props.fileExtension} (${bytesToString(props.fileSize)})`;
    const meta = metaField ? `${props[metaField]} – ${fileInfo}` : fileInfo;
    return (
        <a href={props.url} className={styles.DownloadLink}>
            <Row
                title={props.name}
                meta={meta}
                tags={props.mainCategory ? [props.mainCategory] : []}
                icon={<DownloadsIcon className={styles.DownloadIcon} />}
                onTagClick={(tagId) => {
                    navigate(`/downloads/list?category=${tagId}`);
                }}
            />
        </a>
    );
};

const downloadItemProps = {
    name: PropTypes.string.isRequired,
    metaField: PropTypes.string,
    url: PropTypes.string.isRequired,
    booklet_id: PropTypes.number,
    type: PropTypes.string.isRequired,
    fileSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fileType: PropTypes.string.isRequired,
    updatedAtDiff: PropTypes.string,
    mainCategory: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    }),
};

DownloadItem.propTypes = downloadItemProps;

export const DownloadItemProps = PropTypes.shape(downloadItemProps);
export default DownloadItem;
