import { useTranslation } from 'react-i18next';
import Button, { ButtonStyle } from '../Button/Button';
import Dialog from '../Dialog/Dialog';
import DialogType from '../Dialog/DialogType';

const ConfirmDeleteUserDialog = ({ email, onCancel, onConfirm }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            title={t('userManagement.deleteUserDialogTitle')}
            type={DialogType.WARNING}
            message={`<p>${t(
                'userManagement.deleteUserDialogPleaseConfirm'
            )} <span style="font-weight: bold">${email}</span>.</p><p>${t(
                'userManagement.deleteUserDialogDescription'
            )}</p></>`}
            onClose={onCancel}
            actions={
                <>
                    <Button
                        style={ButtonStyle.PRIMARY}
                        text={t('userManagement.deleteUserDialogConfirmButton')}
                        onClick={onConfirm}
                    />
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('generics.cancelButton')}
                        onClick={onCancel}
                    />
                </>
            }
        />
    );
};

export default ConfirmDeleteUserDialog;
