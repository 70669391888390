import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MessageRoute from './message';
import DownloadsRoute from './downloads';
import PageRoute from './page';
import Dashboard from '../components/Dashboard/Dashboard';
import OnlineBooking from '../components/OnlineBooking/OnlineBooking';
import Orders from '../components/Orders/Orders';
import Tab from '../components/Tab/Tab';
import TimeTable from '../components/TimeTable/TimeTable';
import UserManagement from '../components/UserManagement/UserManagement.relay';
import { Features } from '../contexts/AppConfigContext';
import FeaturePreview from '../components/FeaturePreview/FeaturePreview';
import { useTabsContext } from '../contexts/TabsContext';
import ShowBooking from '../components/ShowBooking/ShowBooking';
import { Navigate } from 'react-router-dom';
import Booklets from '../components/Booklets/Booklets';
import UpsertBookletForm from '../components/UpsertBookletForm/UpsertBookletForm';
import EditBooklets from '../components/UpsertBookletForm/EditBooklets.relay';

const useRoutes = (features) => {
    const { t } = useTranslation();

    const ordersAccess =
        features[Features.ORDERS] && features[Features.ORDERS].restricted === false;
    const messagesAccess =
        features[Features.MESSAGES] && features[Features.MESSAGES].restricted === false;
    const indexFeature = ordersAccess
        ? Features.ORDERS
        : messagesAccess
        ? Features.MESSAGES
        : Features.TIMETABLE;

    const routes = useMemo(
        () => ({
            '/': {
                isIndex: true,
                component: (
                    <Navigate
                        to={ordersAccess ? '/orders' : messagesAccess ? '/messages' : '/time-table'}
                    />
                ),
                handle: {
                    feature: indexFeature,
                    showHotline: features[indexFeature]?.getOption('hotline_show') === 'always',
                },
            },
            '/pages/:slug/:state?': {
                component: features[Features.PAGES]?.restricted ? (
                    <FeaturePreview />
                ) : (
                    <PageRoute />
                ),
                handle: {
                    feature: Features.PAGES,
                    showHotline: features[Features.PAGES]?.getOption('hotline_show') === 'always',
                },
            },
            '/messages': {
                component: features[Features.MESSAGES]?.restricted ? (
                    <FeaturePreview feature={'messages'} />
                ) : (
                    <Dashboard />
                ),
                handle: {
                    feature: Features.MESSAGES,
                    showHotline:
                        features[Features.MESSAGES]?.getOption('hotline_show') === 'always',
                },
            },
            '/messages/:slug/:state?': {
                component: features[Features.MESSAGES]?.restricted ? (
                    <FeaturePreview feature={'messages'} />
                ) : (
                    <Tab
                        id={Features.MESSAGES}
                        title={features[Features.MESSAGES]?.label}
                        component={MessageRoute}
                    />
                ),
                handle: {
                    feature: Features.MESSAGES,
                    showHotline:
                        features[Features.MESSAGES]?.getOption('hotline_show') === 'always',
                },
            },
            '/downloads/:view': {
                component: features[Features.DOWNLOADS]?.restricted ? (
                    <FeaturePreview />
                ) : (
                    <Tab
                        id={Features.DOWNLOADS}
                        title={t('navigation.downloadsTabTitle')}
                        component={DownloadsRoute}
                    />
                ),
                handle: {
                    feature: Features.DOWNLOADS,
                    showHotline:
                        features[Features.DOWNLOADS]?.getOption('hotline_show') === 'always',
                },
            },
            '/new-booking/:tabSuffix': {
                component: features[Features.ONLINE_BOOKING]?.restricted ? (
                    <FeaturePreview feature={'bookings'} />
                ) : (
                    <Tab
                        id='new-booking'
                        title={t('navigation.newBookingTabTitle')}
                        component={OnlineBooking}
                    />
                ),
                handle: {
                    feature: Features.ONLINE_BOOKING,
                    showHotline:
                        features[Features.ONLINE_BOOKING]?.getOption('hotline_show') === 'always',
                },
            },
            '/online-booking': {
                component: features[Features.ONLINE_BOOKING]?.restricted ? (
                    <FeaturePreview feature={'bookings'} />
                ) : (
                    <Tab
                        id={Features.ONLINE_BOOKING}
                        title={features[Features.ONLINE_BOOKING]?.label}
                        component={OnlineBooking}
                    />
                ),
                handle: {
                    feature: Features.ONLINE_BOOKING,
                    showHotline:
                        features[Features.ONLINE_BOOKING]?.getOption('hotline_show') === 'always',
                },
            },
            '/booklets': {
                component: features[Features.BOOKLETS]?.restricted ? (
                    <FeaturePreview />
                ) : (
                    <Tab
                        id={Features.BOOKLETS}
                        title={features[Features.BOOKLETS]?.label}
                        component={Booklets}
                    />
                ),
                handle: {
                    feature: Features.BOOKLETS,
                    showHotline:
                        features[Features.BOOKLETS]?.getOption('hotline_show') === 'always',
                },
            },
            '/booklet/create': {
                component: features[Features.BOOKLETS]?.restricted ? (
                    <FeaturePreview />
                ) : (
                    <Tab
                        id={Features.BOOKLETS}
                        title={features[Features.BOOKLETS]?.label}
                        component={UpsertBookletForm}
                    />
                ),
                handle: {
                    feature: Features.BOOKLETS,
                    showHotline:
                        features[Features.BOOKLETS]?.getOption('hotline_show') === 'always',
                },
            },
            '/booklet/edit/:id': {
                component: features[Features.BOOKLETS]?.restricted ? (
                    <FeaturePreview />
                ) : (
                    <Tab
                        id={Features.BOOKLETS}
                        title={features[Features.BOOKLETS]?.label}
                        component={EditBooklets}
                    />
                ),
                handle: {
                    feature: Features.BOOKLETS,
                    showHotline:
                        features[Features.BOOKLETS]?.getOption('hotline_show') === 'always',
                },
            },
            '/booking/:tabSuffix/:dl': {
                component: features[Features.ONLINE_BOOKING]?.restricted ? (
                    <FeaturePreview feature={'bookings'} />
                ) : (
                    <Tab id='booking' title={(tabSuffix) => tabSuffix} component={ShowBooking} />
                ),
                handle: {
                    feature: Features.ONLINE_BOOKING,
                    showHotline:
                        features[Features.ONLINE_BOOKING]?.getOption('hotline_show') === 'always',
                },
            },
            '/orders': {
                component: features[Features.ORDERS]?.restricted ? (
                    <FeaturePreview feature={'orders'} />
                ) : (
                    <Tab
                        id={Features.ORDERS}
                        title={features[Features.ORDERS]?.label}
                        component={Orders}
                    />
                ),
                handle: {
                    feature: Features.ORDERS,
                    showHotline: features[Features.ORDERS]?.getOption('hotline_show') === 'always',
                },
            },
            '/time-table': {
                component: features[Features.TIMETABLE]?.restricted ? (
                    <FeaturePreview />
                ) : (
                    <Tab
                        id={Features.TIMETABLE}
                        title={features[Features.TIMETABLE]?.label}
                        component={TimeTable}
                    />
                ),
                handle: {
                    feature: Features.TIMETABLE,
                    showHotline:
                        features[Features.TIMETABLE]?.getOption('hotline_show') === 'always',
                },
            },
            '/user-management': {
                component:
                    features[Features.USER_MANAGEMENT]?.restricted === false ? (
                        <UserManagement />
                    ) : (
                        <FeaturePreview />
                    ),
                handle: {
                    feature: Features.USER_MANAGEMENT,
                    showHotline:
                        features[Features.USER_MANAGEMENT]?.getOption('hotline_show') === 'always',
                },
            },
        }),
        [features, t]
    );

    const getFeaturePath = useCallback(({ feature, featureOptions = [] }) => {
        switch (feature) {
            case Features.PAGES: {
                const slug = featureOptions.find(({ key }) => key === 'slug')?.value || '';
                return `/pages/${slug}`;
            }
            case Features.DOWNLOADS: {
                const view = featureOptions.find(({ key }) => key === 'view')?.value || '';
                return `/downloads/${view}`;
            }
            case Features.MESSAGES:
                return '/messages';
            case Features.ONLINE_BOOKING:
                return '/online-booking';
            case Features.BOOKLETS:
                return '/booklets';
            case Features.ORDERS:
                return '/orders';
            case Features.TIMETABLE:
                return '/time-table';
            case Features.USER_MANAGEMENT:
                return '/user-management';
            default:
                return '';
        }
    }, []);

    return {
        routes,
        getFeaturePath,
    };
};

export const useTabbedRoutes = () => {
    const { tabs } = useTabsContext();
    const isTabOpen = useCallback(
        ({ feature, featureOptions = [] }) => {
            switch (feature) {
                case Features.MESSAGES:
                    return false;
                case Features.DOWNLOADS: {
                    const view = featureOptions.find(({ key }) => key === 'view')?.value;
                    return tabs[Features.DOWNLOADS]?.path.endsWith(view) ?? false;
                }
                default:
                    return feature in tabs;
            }
        },
        [tabs]
    );

    return { isTabOpen };
};

export default useRoutes;
