import classNames from 'classnames';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTabsContext } from '../../contexts/TabsContext';
import ModalsContext from '../../contexts/ModalsContext';
import { CrossIcon } from '../Icon/Icon';
import EditProfile from '../EditProfile/EditProfile';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import { useUserContext } from '../../contexts/UserContext';

import styles from './HeaderDesktop.module.scss';
import useEmailServices from '../EmailServices/useEmailServices';

const HeaderDesktop = (props) => {
    const emailServicesConfig = useEmailServices();
    const { tabs, removeTab } = useTabsContext();
    const { showModal } = useContext(ModalsContext);
    const { me } = useUserContext();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div
            className={classNames(props.className, styles.HeaderDesktop)}
            data-testid='HeaderDesktop'
        >
            <div className={styles.LogoContainer}>
                <CompanyLogo className={styles.Logo} />
            </div>

            <div className={styles.Tabs}>
                {Object.entries(tabs).map(([key, { path, title }]) => {
                    return (
                        <div
                            key={key}
                            className={classNames(styles.Tab, {
                                [styles.TabActive]: location.pathname === path,
                            })}
                            onClick={() => {
                                navigate(path);
                            }}
                        >
                            {title}
                            <CrossIcon
                                className={styles.TabIcon}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    removeTab(key);
                                    if (path === location.pathname) {
                                        navigate('/');
                                    }
                                }}
                            />
                        </div>
                    );
                })}
            </div>

            {/*
        <div className={styles.Search}>
            <div>
                Bitte geben Sie Ihren Suchbegriff ein...
            </div>
            <SearchIcon className={styles.SearchIcon}/>
        </div>
        */}
            {/*
        <div className={styles.Notifications}>
            <BellIcon className={styles.BellIcon}/>
            <span className={styles.BellPile}>4</span>
        </div>
            */}
            <img
                alt={t('profile.myProfile')}
                title={`${me.forename} ${me.surname} (${me.company?.name})`}
                className={styles.ProfileIcon}
                src={me.avatarUrl}
                onClick={() =>
                    showModal(
                        t('profile.profileSettings'),
                        <EditProfile emailServicesConfig={emailServicesConfig} />
                    )
                }
            />
        </div>
    );
};

HeaderDesktop.propTypes = {};

export default HeaderDesktop;
