import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Icon from '../../Icon/Icon';
import Field, { ErrorIndicator, FieldPropTypes } from '../Field';
import styles from './Input.module.scss';

export const IconPosition = Object.freeze({
    START: 1,
    END: 2,
});

const Input = ({
    autocomplete = 'off',
    disabled = false,
    readonly = false,
    type = 'text',
    icon = undefined,
    iconPosition = IconPosition.START,
    onIconClick = () => undefined,
    onChange = () => undefined,
    placeholder = undefined,
    value = undefined,
    ...props
}) => {
    const [valueInput, setValueInput] = useState(value);

    useEffect(() => {
        setValueInput(value);
    }, [value]);

    const computedIcon = icon
        ? React.createElement(Icon[icon], {
              className: styles.Icon,
              onClick: onIconClick,
          })
        : null;

    let iconClass = '';
    if (computedIcon) {
        iconClass =
            iconPosition === IconPosition.START
                ? styles.InputWithStartIcon
                : styles.InputWithEndIcon;
    }

    const inputClassName = [styles.Input, props.error ? styles.InputWithError : '', iconClass]
        .join(' ')
        .trim();

    return (
        <Field
            className={props.className}
            label={props.label}
            required={!readonly && props.required}
            block={props.block}
            error={props.error}
            data-testid='Input'
        >
            <input
                autoComplete={autocomplete}
                className={inputClassName}
                placeholder={placeholder}
                disabled={disabled}
                onChange={(event) => {
                    setValueInput(event.target.value);
                    onChange(event.target.value);
                }}
                readOnly={readonly}
                required={props.required}
                type={type}
                value={valueInput}
            />
            {computedIcon}
            {props.error && <ErrorIndicator className={styles.ErrorIndicator} />}
        </Field>
    );
};

Input.propTypes = {
    ...FieldPropTypes,
    disabled: PropTypes.bool,
    icon: PropTypes.oneOf(Object.keys(Icon)),
    iconPosition: PropTypes.oneOf(Object.values(IconPosition)),
    onIconClick: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    value: PropTypes.string,
    type: PropTypes.string,
    autocomplete: PropTypes.string,
};

export default Input;
