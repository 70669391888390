import ReactDOM from 'react-dom';
import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './Tooltip.module.scss';

export const TooltipPosition = {
    LEFT: 'left',
    RIGHT: 'right',
    TOP: 'top',
    BOTTOM: 'bottom',
};

const Tooltip = ({
    visible = false,
    children,
    element,
    position = TooltipPosition.RIGHT,
    className = '',
}) => {
    const [coords, setCoords] = useState(undefined);

    const getElementBox = useCallback((elem) => {
        const box = elem.getBoundingClientRect();

        const body = document.body;
        const docEl = document.documentElement;

        const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        const clientTop = docEl.clientTop || body.clientTop || 0;
        const clientLeft = docEl.clientLeft || body.clientLeft || 0;

        const top = box.top + scrollTop - clientTop;
        const left = box.left + scrollLeft - clientLeft;

        return {
            top: Math.round(top),
            left: Math.round(left),
            width: box.width,
            height: box.height,
        };
    }, []);

    const getTooltipCoords = useCallback(
        (elem, tooltipPosition) => {
            const { top, left, width, height } = getElementBox(elem);
            switch (tooltipPosition) {
                case TooltipPosition.LEFT:
                    return {
                        top: `${top + height / 2}px`,
                        transform: 'translate(-100%, -50%)',
                        left: `${left}px`,
                        paddingRight: '1rem',
                    };
                case TooltipPosition.RIGHT:
                    return {
                        top: `${top + height / 2}px`,
                        transform: 'translateY(-50%)',
                        left: `${left + width}px`,
                        paddingLeft: '1rem',
                    };
                case TooltipPosition.TOP:
                    return {
                        top: `${top}px`,
                        transform: 'translate(-50%, -100%)',
                        left: `${left + width / 2}px`,
                        paddingBottom: '1rem',
                    };
                case TooltipPosition.BOTTOM:
                    return {
                        top: `${top + height}px`,
                        transform: 'translateX(-50%)',
                        left: `${left + width / 2}px`,
                        paddingTop: '1rem',
                    };
                default: {
                    return {
                        top: 0,
                        left: 0,
                    };
                }
            }
        },
        [getElementBox]
    );

    useEffect(() => {
        if (visible && element) {
            const newCoords = getTooltipCoords(element, position);
            setCoords(newCoords);
        }
        if (!visible) {
            setCoords(undefined);
        }
    }, [visible, element, position, getTooltipCoords]);

    return coords
        ? ReactDOM.createPortal(
              <div data-testid='Tooltip' className={styles.Tooltip} style={{ ...coords }}>
                  <div className={className}>{children}</div>
              </div>,
              document.body
          )
        : null;
};

Tooltip.propTypes = {
    visible: PropTypes.bool,
    position: PropTypes.oneOf(Object.values(TooltipPosition)),
    className: PropTypes.string,
    //children: '',
    element: PropTypes.instanceOf(HTMLElement),
};
export default Tooltip;
