import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { BurgerIcon } from '../Icon/Icon';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
//import { useUserContext } from '../../contexts/UserContext';

import styles from './HeaderMobile.module.scss';

const HeaderMobile = (props) => {
    //const { me } = useUserContext();
    const navigate = useNavigate();

    return (
        <div
            className={classNames(props.className, styles.HeaderMobile)}
            data-testid='HeaderMobile'
        >
            <div
                className={styles.HeaderMobileLogoContainer}
                onClick={() => {
                    navigate('/');
                }}
            >
                <CompanyLogo className={styles.HeaderMobileLogo} />
            </div>

            <BurgerIcon
                className={styles.HeaderMobileNavIcon}
                onClick={(_) => {
                    if (props.onOpenMobileNav) props.onOpenMobileNav();
                }}
            />
        </div>
    );
};

HeaderMobile.propTypes = {};

export default HeaderMobile;
