import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckBox from '../Form/CheckBox/CheckBox';
import useDateFormatter from '../../hooks/useDateFormatter';

import styles from './PermissionsList.module.scss';

const PermissionsListEditor = ({
    className = '',
    isEditing = false,
    permissions = [],
    ...props
}) => {
    const { t } = useTranslation();
    const { toDateTimeString } = useDateFormatter();

    return (
        <form
            className={[styles.PermissionsList, className].join(' ').trim()}
            data-testid='PermissionsListEditor'
        >
            {permissions.map((permission) => (
                <div key={permission.permissionId} className={styles.PermissionContainer}>
                    <CheckBox
                        className={styles.PermissionCheckBox}
                        checked={permission.isEnabled}
                        label={permission.name}
                        onChange={(value) => props.onChange(permission.permissionId, value)}
                        readonly={permission.readonly}
                    />
                    {(permission.lastModified?.by || permission.lastModified?.at) && (
                        <p className={styles.LastModifiedNote}>
                            {`${t('permissionsList.lastModified')}: `}
                            {permission.lastModified.by && `${permission.lastModified.by}, `}
                            {permission.lastModified.at &&
                                toDateTimeString(permission.lastModified.at)}
                        </p>
                    )}
                </div>
            ))}
        </form>
    );
};

PermissionsListEditor.propTypes = {
    className: PropTypes.string,
    isEditing: PropTypes.bool,
    permissions: PropTypes.arrayOf(
        PropTypes.shape({
            permissionId: PropTypes.string.isRequired,
            readonly: PropTypes.bool.isRequired,
            isEnabled: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            lastModified: PropTypes.shape({
                at: PropTypes.string,
                by: PropTypes.string,
            }),
        })
    ),
};

export default PermissionsListEditor;
