import classNames from 'classnames';
import AddIconSrc from './svgs/add.svg';
import ArrowDownIconSrc from './svgs/arrow-down.svg';
import ArrowLeftIconSrc from './svgs/arrow-left.svg';
import ArrowRightBigIconSrc from './svgs/arrow-right-big.svg';
import ArrowRightIconSrc from './svgs/arrow-right.svg';
import ArrowUpIconSrc from './svgs/arrow-up.svg';
import BellIconSrc from './svgs/bell.svg';
import BurgerIconSrc from './svgs/burger.svg';
import CheckmarkIconSrc from './svgs/checkmark.svg';
import ClipboardIconSrc from './svgs/clipboard.svg';
import CrossIconSrc from './svgs/cross.svg';
import DateIconSrc from './svgs/date.svg';
import DisabledIconSrc from './svgs/disabled.svg';
import DotIconSrc from './svgs/dot.svg';
import DownloadsIconSrc from './svgs/downloads.svg';
import EditIconSrc from './svgs/edit.svg';
import EmailIconSrc from './svgs/email.svg';
import EnabledIconSrc from './svgs/enabled.svg';
import EyeIconSrc from './svgs/eye.svg';
import ErrorIconSrc from './svgs/error.svg';
import ExternalLinkIconSrc from './svgs/external-link.svg';
import HeadphoneIconSrc from './svgs/headphone.svg';
import HelpIconSrc from './svgs/help.svg';
import HomeIconSrc from './svgs/home.svg';
import InfoIconSrc from './svgs/info.svg';
import InternIconSrc from './svgs/intern.svg';
import LoginIconSrc from './svgs/login.svg';
import LogoutIconSrc from './svgs/logout.svg';
import NewsIconSrc from './svgs/news.svg';
import NewspaperIconSrc from './svgs/newspaper.svg';
import PackageIconSrc from './svgs/package.svg';
import PasswordIconSrc from './svgs/password.svg';
import PhoneIconSrc from './svgs/phone.svg';
import RequestQuotationIconSrc from './svgs/request-quotation.svg';
import SaveIconSrc from './svgs/save.svg';
import SearchIconSrc from './svgs/search.svg';
import SettingsIconSrc from './svgs/settings.svg';
import SmartphoneIconSrc from './svgs/smartphone.svg';
import SortIconSrc from './svgs/sort.svg';
import SortAscIconSrc from './svgs/sort-asc.svg';
import SortDescIconSrc from './svgs/sort-desc.svg';
import SupportStickyIconSrc from './svgs/support-sticky.svg';
import ToolboxIconSrc from './svgs/toolbox.svg';
import TrashIconSrc from './svgs/trash.svg';
import UserIconSrc from './svgs/user.svg';
import UsersIconSrc from './svgs/users.svg';
import WarningIconSrc from './svgs/warning.svg';
import WikiIconSrc from './svgs/wiki.svg';
import ExportIconSrc from './svgs/export-icon.svg';
import DuplicateIconSrc from './svgs/duplicate.svg';

import styles from './Icon.module.scss';

const IconWrapper = ({ src, className = '', ...props }) => (
    <span
        {...props}
        data-testid='Icon'
        className={classNames({
            [styles.Icon]: true,
            [className]: !!className,
        })}
        style={{
            WebkitMaskImage: `url(${src})`,
            maskImage: `url(${src})`,
        }}
    />
);

export const AddIcon = (props) => <IconWrapper {...props} src={AddIconSrc} />;
export const ArrowDownIcon = (props) => <IconWrapper {...props} src={ArrowDownIconSrc} />;
export const ArrowLeftIcon = (props) => <IconWrapper {...props} src={ArrowLeftIconSrc} />;
export const ArrowRightIcon = (props) => <IconWrapper {...props} src={ArrowRightIconSrc} />;
export const ArrowRightBigIcon = (props) => <IconWrapper {...props} src={ArrowRightBigIconSrc} />;
export const ArrowUpIcon = (props) => <IconWrapper {...props} src={ArrowUpIconSrc} />;
export const BellIcon = (props) => <IconWrapper {...props} src={BellIconSrc} />;
export const BurgerIcon = (props) => <IconWrapper {...props} src={BurgerIconSrc} />;
export const CheckmarkIcon = (props) => <IconWrapper {...props} src={CheckmarkIconSrc} />;
export const ClipboardIcon = (props) => <IconWrapper {...props} src={ClipboardIconSrc} />;
export const CrossIcon = (props) => <IconWrapper {...props} src={CrossIconSrc} />;
export const DateIcon = (props) => <IconWrapper {...props} src={DateIconSrc} />;
export const DisabledIcon = (props) => <IconWrapper {...props} src={DisabledIconSrc} />;
export const DotIcon = (props) => <IconWrapper {...props} src={DotIconSrc} />;
export const DownloadsIcon = (props) => <IconWrapper {...props} src={DownloadsIconSrc} />;
export const EditIcon = (props) => <IconWrapper {...props} src={EditIconSrc} />;
export const EmailIcon = (props) => <IconWrapper {...props} src={EmailIconSrc} />;
export const EnabledIcon = (props) => <IconWrapper {...props} src={EnabledIconSrc} />;
export const ErrorIcon = (props) => <IconWrapper {...props} src={ErrorIconSrc} />;
export const ExternalLinkIcon = (props) => <IconWrapper {...props} src={ExternalLinkIconSrc} />;
export const EyeIcon = (props) => <IconWrapper {...props} src={EyeIconSrc} />;
export const HeadphoneIcon = (props) => <IconWrapper {...props} src={HeadphoneIconSrc} />;
export const HelpIcon = (props) => <IconWrapper {...props} src={HelpIconSrc} />;
export const HomeIcon = (props) => <IconWrapper {...props} src={HomeIconSrc} />;
export const InfoIcon = (props) => <IconWrapper {...props} src={InfoIconSrc} />;
export const InternIcon = (props) => <IconWrapper {...props} src={InternIconSrc} />;
export const LoginIcon = (props) => <IconWrapper {...props} src={LoginIconSrc} />;
export const LogoutIcon = (props) => <IconWrapper {...props} src={LogoutIconSrc} />;
export const NewsIcon = (props) => <IconWrapper {...props} src={NewsIconSrc} />;
export const NewspaperIcon = (props) => <IconWrapper {...props} src={NewspaperIconSrc} />;
export const PackageIcon = (props) => <IconWrapper {...props} src={PackageIconSrc} />;
export const PasswordIcon = (props) => <IconWrapper {...props} src={PasswordIconSrc} />;
export const PhoneIcon = (props) => <IconWrapper {...props} src={PhoneIconSrc} />;
export const RequestQuotationIcon = (props) => (
    <IconWrapper {...props} src={RequestQuotationIconSrc} />
);
export const SaveIcon = (props) => <IconWrapper {...props} src={SaveIconSrc} />;
export const SearchIcon = (props) => <IconWrapper {...props} src={SearchIconSrc} />;
export const SettingsIcon = (props) => <IconWrapper {...props} src={SettingsIconSrc} />;
export const SmartphoneIcon = (props) => <IconWrapper {...props} src={SmartphoneIconSrc} />;
export const SortIcon = (props) => <IconWrapper {...props} src={SortIconSrc} />;
export const SortAscIcon = (props) => <IconWrapper {...props} src={SortAscIconSrc} />;
export const SortDescIcon = (props) => <IconWrapper {...props} src={SortDescIconSrc} />;
export const SupportStickyIcon = (props) => <IconWrapper {...props} src={SupportStickyIconSrc} />;
export const ToolboxIcon = (props) => <IconWrapper {...props} src={ToolboxIconSrc} />;
export const TrashIcon = (props) => <IconWrapper {...props} src={TrashIconSrc} />;
export const UserIcon = (props) => <IconWrapper {...props} src={UserIconSrc} />;
export const UsersIcon = (props) => <IconWrapper {...props} src={UsersIconSrc} />;
export const WarningIcon = (props) => <IconWrapper {...props} src={WarningIconSrc} />;
export const WikiIcon = (props) => <IconWrapper {...props} src={WikiIconSrc} />;
export const DuplicateIcon = (props) => <IconWrapper {...props} src={DuplicateIconSrc} />;
export const ExportIcon = (props) => <IconWrapper {...props} src={ExportIconSrc} />;

const Icon = {
    add: AddIcon,
    'arrow-down': ArrowDownIcon,
    'arrow-left': ArrowLeftIcon,
    'arrow-right': ArrowRightIcon,
    'arrow-right-big': ArrowRightBigIcon,
    'arrow-up': ArrowUpIcon,
    bell: BellIcon,
    burger: BurgerIcon,
    checkmark: CheckmarkIcon,
    clipboard: ClipboardIcon,
    cross: CrossIcon,
    date: DateIcon,
    disabled: DisabledIcon,
    dot: DotIcon,
    downloads: DownloadsIcon,
    edit: EditIcon,
    email: EmailIcon,
    enabled: EnabledIcon,
    error: ErrorIcon,
    'external-link': ExternalLinkIcon,
    eye: EyeIcon,
    headphone: HeadphoneIcon,
    help: HelpIcon,
    home: HomeIcon,
    info: InfoIcon,
    intern: InternIcon,
    login: LoginIcon,
    logout: LogoutIcon,
    news: NewsIcon,
    newspaper: NewspaperIcon,
    package: PackageIcon,
    password: PasswordIcon,
    phone: PhoneIcon,
    'request-quotation': RequestQuotationIcon,
    save: SaveIcon,
    search: SearchIcon,
    settings: SettingsIcon,
    smartphone: SmartphoneIcon,
    sort: SortIcon,
    'sort-asc': SortAscIcon,
    'sort-desc': SortDescIcon,
    'support-sticky': SupportStickyIcon,
    toolbox: ToolboxIcon,
    trash: TrashIcon,
    user: UserIcon,
    users: UsersIcon,
    warning: WarningIcon,
    wiki: WikiIcon,
    duplicate: DuplicateIcon,
    'export-icon': ExportIcon,
};
export default Icon;
