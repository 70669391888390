import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import Input from '../Input/Input';
import Field, { ErrorIndicator, FieldPropTypes } from '../Field';
import { useTranslation } from 'react-i18next';

import styles from './Select.module.scss';
import { t } from 'i18next';

const Select = ({
    clearable = true,
    defaultValue = undefined,
    onChange = () => undefined,
    options = [],
    readonly = false,
    value = undefined,
    noOptionsMessage = () => t('generics.noOptions'),
    ...props
}) => {
    const { t } = useTranslation();

    return readonly ? (
        <Input
            label={props.label}
            type='text'
            className={props.className}
            placeholder={props.placeholder || t('generics.pleaseSelect')}
            value={
                options.find((option) =>
                    value !== undefined ? option.value === value : option.value === defaultValue
                )?.label
            }
            required={props.required}
            error={props.error}
            readonly
        />
    ) : (
        <Field
            label={props.label}
            required={props.required}
            block={props.block}
            className={props.className}
            data-testid='Select'
            error={props.error}
        >
            <ReactSelect
                className={`${styles.SelectContainer} ${props.error ? styles.WithError : ''}`}
                styles={{
                    container: (provided, { isFocused }) => ({
                        ...provided,
                        ...(isFocused && {
                            outline: '2px solid rgba(105, 148, 187, 0.3)',
                        }),
                    }),
                    valueContainer: (provided) => ({
                        ...provided,
                        paddingRight: 0,
                    }),
                    option: (provided, { isDisabled }) => ({
                        ...provided,
                        ...(isDisabled && { display: 'none' }),
                    }),
                    control: (provided) => ({
                        ...provided,
                        minHeight: 'auto',
                        backgroundColor: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                    }),
                    input: (provided) => ({
                        ...provided,
                        margin: 0,
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: 0,
                        paddingRight: '8px',
                        paddingLeft: '8px',
                    }),
                    clearIndicator: (provided) => ({
                        ...provided,
                        padding: 0,
                        paddingRight: '8px',
                        paddingLeft: '8px',
                    }),
                }}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'var(--bg-color-selected)',
                        primary25: 'var(--bg-color-hover)',
                    },
                })}
                options={options}
                isClearable={!props.required && clearable}
                placeholder={props.placeholder || t('generics.pleaseSelect')}
                defaultValue={options.find((option) => option.value === defaultValue)}
                value={options.find((option) => option.value === value) || null}
                onChange={(option) => {
                    onChange(option?.value);
                }}
                components={{
                    IndicatorSeparator: () => null,
                }}
                noOptionsMessage={noOptionsMessage}
            />
            {props.error && <ErrorIndicator className={styles.ErrorIndicator} />}
        </Field>
    );
};

Select.propTypes = {
    ...FieldPropTypes,
    clearable: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
            label: PropTypes.string.isRequired,
        })
    ),
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    noOptionsMessage: PropTypes.func,
};
export default Select;
