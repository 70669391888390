import React from 'react';
import styles from './NotFound.module.scss';

const NotFound = (props) => {
    return (
        <div className={styles.NotFound} data-testid='NotFound'>
            NotFound Component
        </div>
    );
};

NotFound.propTypes = {};

export default NotFound;
