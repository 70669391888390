import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Table from '../Table/Table';
import styles from './Booklets.module.scss';
import Button, { ButtonStyle } from '../Button/Button';
import { Link } from 'react-router-dom';
import ConfirmDeleteBookletDialog from './ConfirmDeleteBookletDialog';
import { fetchQuery, useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import Dialog from '../Dialog/Dialog';
import DialogType from '../Dialog/DialogType';
import emptyStateImage from './empty-state.png';
import ModalsContext from '../../contexts/ModalsContext';
import Modal from '../Modal/Modal';
import BookletExport, { encodeExportBooklet } from './BookletExport/BookletExport';
import { BookletsQuery } from './useBooklets';
import { UpsertBookletMutation } from '../UpsertBookletForm/UpsertBookletForm';
import i18n from 'i18next';

export const DeleteBookletMutation = graphql`
    mutation BookletsDeleteMutation($id: ID!) {
        deleteBooklet(id: $id) {
            successPlaceholder: id
        }
    }
`;

export const BookletsContactQuery = graphql`
    query BookletsContactQuery {
        bookletContact {
            id
            type
            avatar_url
            name
            position
            phone
            mobile
            email
            languages
        }
    }
`;

function useBookletContact() {
    const data = useLazyLoadQuery(BookletsContactQuery);
    if (data.bookletContact) {
        const contact = data.bookletContact;
        return {
            id: contact.id,
            type: contact.type,
            name: contact.name,
            avatarUrl: contact.avatar_url || undefined,
            position: contact.position,
            phoneNumber: contact.phone,
            mobileNumber: contact.mobile,
            email: contact.email,
            languages: contact.languages,
        };
    }
}

const Booklets = (props) => {
    const environment = useRelayEnvironment();
    const [bookletToDelete, setBookletToDelete] = useState(undefined);
    const listRef = useRef();
    const { t } = useTranslation();
    const [commitDelete, isDeleting] = useMutation(DeleteBookletMutation);
    const [error, setError] = useState('');
    const [duplicateError, setDuplicateError] = useState('');
    const [bookletContact] = useState(useBookletContact());
    const [bookletToContact, setBookletToContact] = useState(undefined);
    const [filteredBooklets, setFilteredBooklets] = useState([]);
    const { modal, closeModal } = useContext(ModalsContext);
    const [upsertBooklet, isLoading] = useMutation(UpsertBookletMutation);
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchBookletsData = useCallback(() => {
        if (environment) {
            fetchQuery(environment, BookletsQuery)
                .toPromise()
                .then((response) => {
                    setFilteredBooklets(response.booklets);
                })
                .catch((error) => {
                    console.error('Error reFetching data: ', error);
                });
        }
    }, [environment, setFilteredBooklets]);

    const duplicateBooklet = useCallback(
        (bookletToDuplicate) => {
            let title = bookletToDuplicate.title.replace(/[^\w\s]/g, ' ');
            const maxLength = i18n.language === 'de' ? 29 : 30;
            if (title.length >= maxLength) {
                title = title.slice(0, i18n.language === 'de' ? 29 : 30);
            }
            upsertBooklet({
                variables: {
                    id: '',
                    input: {
                        title: title + t('booklet.titlePrefixed'),
                        type: bookletToDuplicate.type,
                        include_other_information: bookletToDuplicate.include_other_information,
                        include_general_information: bookletToDuplicate.include_general_information,
                        timeframe: bookletToDuplicate.timeframe,
                        from: bookletToDuplicate.from,
                        to: bookletToDuplicate.to,
                        group_returns: bookletToDuplicate.group_returns,
                        routes: bookletToDuplicate.routes,
                    },
                },
                onError: (error) => {
                    let errors = error.source.errors;
                    if (errors?.length) {
                        const [mutationError] = errors;
                        if (mutationError.extensions.validation) {
                            let errorMessage = mutationError.extensions.validation;
                            let toError = errorMessage['input.to']?.[0] ?? '';
                            let titleError = errorMessage['input.title']?.[0] ?? '';

                            let genericMessage = t('booklet.duplicateGenericError') + '</br></br>';
                            if (toError === 'Invalid combination Date from/to') {
                                genericMessage += t('booklet.InvalidCombinationDateFromTo');
                            } else if (toError === 'The timeframe cannot exceed 35 days') {
                                genericMessage += t('booklet.timeframeWithIn35Days');
                            } else if (
                                titleError ===
                                'The title may have a maximum length of 35 characters'
                            ) {
                                genericMessage += t(
                                    'booklet.theTitleMayHaveAMaximumLengthOf35Characters'
                                );
                            } else if (
                                titleError ===
                                'The title may only contain the following characters in addition to whitespaces: A-Z, a-z, 0-9'
                            ) {
                                genericMessage += t(
                                    'booklet.theTitleMayOnlyContainTheFollowingCharacters'
                                );
                            } else {
                                genericMessage = t('booklet.genericInputError');
                            }
                            setDuplicateError(genericMessage);
                        } else {
                            setDuplicateError(
                                mutationError.message ?? t('generics.unexpectedError')
                            );
                        }
                    } else {
                        const message =
                            error instanceof Error ? error.message : t('generics.unexpectedError');
                        setDuplicateError(message);
                    }
                },
                onCompleted: (_, errors) => {
                    if (errors?.length) {
                    } else {
                        fetchBookletsData();
                    }
                },
            });
        },
        [fetchBookletsData, t, upsertBooklet]
    );

    useEffect(() => {
        fetchBookletsData();
    }, [fetchBookletsData]);

    const columns = useMemo(
        () => [
            {
                Header: t('booklet.columnName'),
                accessor: 'title',
                sortable: true,
                sortType: (rowA, rowB) => {
                    const nameA = rowA.original.title.toLowerCase();
                    const nameB = rowB.original.title.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                },
            },
            {
                Header: t('booklet.columnAction'),
                accessor: 'id',
                cellClass: styles.lastCell,
                Cell: ({ row }) => (
                    <div className={styles.lastCellActions}>
                        <Link to={`/booklet/edit/${row.original.internal_id}`}>
                            <Button
                                icon='edit'
                                style={ButtonStyle.SECONDARY}
                                tooltip={t('booklet.editAction')}
                            />
                        </Link>

                        <Button
                            icon='duplicate'
                            style={ButtonStyle.SECONDARY}
                            tooltip={t('booklet.duplicateAction')}
                            onClick={() => duplicateBooklet(row.original)}
                            disabled={isLoading}
                        />

                        <Button
                            icon='export-icon'
                            style={ButtonStyle.SECONDARY}
                            onClick={() => setBookletToContact(row.original)}
                            tooltip={t('booklet.dataExportAction')}
                            disabled={
                                !row.original.routes ||
                                row.original.routes === '' ||
                                row.original.routes === '[]'
                            }
                        />

                        <Button
                            icon='trash'
                            style={ButtonStyle.SECONDARY}
                            onClick={() => setBookletToDelete(row.original)}
                            tooltip={t('booklet.deleteAction')}
                            disabled={isDeleting}
                        />

                        <Link
                            to={`${
                                apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl
                            }/export/${encodeExportBooklet(row.original.internal_id)}`}
                        >
                            <Button
                                style={ButtonStyle.PRIMARY}
                                text={t('booklet.DownloadPDF')}
                                extraClasses={styles.ButtonHeight}
                                disabled={
                                    !row.original.routes ||
                                    row.original.routes === '' ||
                                    row.original.routes === '[]'
                                }
                            />
                        </Link>
                    </div>
                ),
            },
        ],
        [t, duplicateBooklet, isDeleting, isLoading, apiUrl]
    );

    const defaultSort = useMemo(
        () => [
            {
                id: 'title',
                desc: false,
            },
        ],
        []
    );

    const deleteBooklet = useCallback(
        (booklet) => {
            commitDelete({
                variables: { id: booklet.internal_id },
                onError: (networkError) => {
                    const message =
                        networkError instanceof Error
                            ? networkError.message
                            : t('generics.unexpectedError');
                    setError(message);
                },
                onCompleted: (_, errors) => {
                    if (errors?.length) {
                        const [mutationError] = errors;
                        setError(mutationError.message ?? t('generics.unexpectedError'));
                    } else {
                        const updatedBooklets = filteredBooklets.filter(
                            (b) => b.internal_id !== booklet.internal_id
                        );
                        setFilteredBooklets(updatedBooklets);
                    }
                },
            });
        },
        [commitDelete, filteredBooklets, t]
    );

    const closeExportModal = () => {
        setBookletToContact(undefined);
        closeModal();
    };

    useDocumentTitle(t('navigation.personalTimetableBooklets'), listRef);

    return (
        <>
            {bookletToContact ? (
                <Modal
                    isOpen={true}
                    title={t('bookletExport.DataExport')}
                    size={modal?.size}
                    onClose={closeExportModal}
                >
                    <BookletExport
                        key={bookletToContact.id}
                        internalId={bookletToContact.internal_id}
                        isMobileView={true}
                        {...bookletContact}
                    />
                </Modal>
            ) : null}
            <div ref={listRef} className={styles.Booklets} data-testid='Booklets'>
                <h1 className={styles.PageTitle}>{t('navigation.personalTimetableBooklets')}</h1>
                <div className={styles.SectionCreate}>
                    <Link to='/booklet/create'>
                        <Button
                            icon='add'
                            style={ButtonStyle.SECONDARY}
                            text={t('booklet.addNewTimetableBooklet')}
                        />
                    </Link>
                </div>
                <div className={styles.TableContainer}>
                    <Table
                        columns={columns}
                        data={filteredBooklets}
                        defaultSort={defaultSort}
                        emptyState={
                            <div>
                                <img src={emptyStateImage} alt={''} />
                                <div className={styles.EmptyHeadline}>
                                    {t('booklet.emptyStateHeadline')}
                                </div>
                                <div className={styles.EmptySubHeadline}>
                                    {t('booklet.emptyStateSubHeadline')}
                                </div>
                            </div>
                        }
                    />
                </div>

                {bookletToDelete && (
                    <ConfirmDeleteBookletDialog
                        bookletTitle={bookletToDelete.title}
                        onCancel={() => setBookletToDelete(undefined)}
                        onConfirm={() => {
                            deleteBooklet(bookletToDelete);
                            setBookletToDelete(undefined);
                        }}
                    />
                )}

                {error && (
                    <Dialog
                        title={t('generics.error')}
                        message={error}
                        type={DialogType.ERROR}
                        onClose={() => setError('')}
                    />
                )}

                {duplicateError && (
                    <Dialog
                        title={t('booklet.duplicateErrorHeadline')}
                        message={duplicateError}
                        type={DialogType.ERROR}
                        onClose={() => setDuplicateError('')}
                        actions={
                            <>
                                <Button
                                    style={ButtonStyle.SECONDARY}
                                    text={t('booklet.understood')}
                                    onClick={() => setDuplicateError('')}
                                />
                            </>
                        }
                    />
                )}
            </div>
        </>
    );
};

Booklets.propTypes = {
    booklets: PropTypes.arrayOf(
        PropTypes.shape({
            internal_id: PropTypes.number.isRequired,
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            include_other_information: PropTypes.bool,
            include_general_information: PropTypes.bool,
            timeframe: PropTypes.string,
            from: PropTypes.string,
            to: PropTypes.string,
            group_returns: PropTypes.bool,
        })
    ),
};

export default Booklets;
