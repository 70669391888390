import useDownloads from './useDownloads';
import Component from './Downloads';

const Downloads = () => {
    const downloads = useDownloads();

    return <Component downloads={downloads} />;
};

Downloads.propTypes = {};

export default Downloads;
