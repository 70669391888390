import React, { useCallback, useContext, useState } from 'react';

const TabsContext = React.createContext({
    tabs: {},
    addTab: (id, title, path, onClose) => undefined,
    removeTab: (id) => undefined,
});

export const useTabsContext = () => {
    return useContext(TabsContext);
};

export const TabsContextWrapper = ({ children }) => {
    const [tabs, setTabs] = useState({});

    const addTab = useCallback((id, title, path, onClose) => {
        setTabs((oldTabs) => {
            if (oldTabs[id] && oldTabs[id].title === title && oldTabs[id].path === path) {
                return oldTabs;
            }
            return {
                ...oldTabs,
                [id]: {
                    title,
                    path,
                    onClose,
                },
            };
        });
    }, []);

    const removeTab = useCallback((id) => {
        setTabs((oldTabs) => {
            const {
                [id]: { onClose },
                ...remainingTabs
            } = oldTabs;
            onClose();
            return remainingTabs;
        });
    }, []);

    return (
        <TabsContext.Provider value={{ tabs, addTab, removeTab }}>{children}</TabsContext.Provider>
    );
};

export default TabsContext;
