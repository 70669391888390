/**
 * @generated SignedSource<<6ab23ba5585c1827a1c614bb342bfa70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "crmId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "crmId",
        "variableName": "crmId"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteProfile",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteUserDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteUserDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1565f2dc7ccaa2f7b24511643d422c9d",
    "id": null,
    "metadata": {},
    "name": "DeleteUserDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteUserDeleteMutation(\n  $crmId: String!\n) {\n  deleteProfile(crmId: $crmId)\n}\n"
  }
};
})();

node.hash = "6912cc08d03bb9f319c00a5b0a3ea496";

module.exports = node;
