import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

export const FaqsQuery = graphql`
    query useFaqsQuery($tags: [String!], $first: Int = 99) {
        faqs(tags: $tags, first: $first) {
            edges {
                node {
                    id
                    title
                    content
                    tags(first: $first) {
                        edges {
                            node {
                                name
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
`;

function useFaqs(tags) {
    const data = useLazyLoadQuery(FaqsQuery, { tags });

    return data.faqs.edges.map(({ node: faq }) => ({
        id: faq.id,
        title: faq.title,
        content: faq.content,
        tags: faq.tags.edges.map(({ node }) => node.name),
    }));
}

export default useFaqs;
