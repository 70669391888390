import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Icon, { DotIcon, PasswordIcon } from '../Icon/Icon';
import Tooltip, { TooltipPosition } from '../Tooltip/Tooltip';

import styles from './NavigationItem.module.scss';

export const NavItemVariant = {
    LIGHT: 'light',
    DEFAULT: '',
};

const NavigationItem = ({
    iconOnly = false,
    active = false,
    open = false,
    disabled = false,
    variant = NavItemVariant.DEFAULT,
    title,
    icon,
    onClick,
    children,
    rightIcon,
    restricted,
    isMobileView,
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const navItemRef = useRef();

    const leftIcon = icon
        ? React.createElement(Icon[icon], {
              className: styles.NavigationItemIcon,
          })
        : null;

    let computedRightIcon = rightIcon;
    if (!computedRightIcon && open) {
        computedRightIcon = <DotIcon />;
    }

    if (restricted) {
        computedRightIcon = (
            <div className={styles.RightIconRestrictedContainer}>
                <PasswordIcon className={styles.RightIconRestricted} />
            </div>
        );
    }

    return (
        <div
            className={classNames({
                [styles.NavigationItem]: true,
                [styles.NavigationItemActive]: active,
                [styles.NavigationItemOpen]: open,
                [styles.NavigationItemDisabled]: disabled,
                [styles.NavigationItemRestricted]: restricted,
                [styles.NavigationItemLight]: variant === NavItemVariant.LIGHT,
            })}
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
        >
            <div className={styles.Row} onClick={onClick} ref={navItemRef}>
                {leftIcon || <div className={styles.NavigationItemIcon} />}
                {!iconOnly && <span>{title}</span>}
                {!iconOnly && computedRightIcon && (
                    <span className={styles.RightIcon}>{computedRightIcon}</span>
                )}
            </div>
            {!isMobileView && iconOnly && (
                <Tooltip
                    visible={isTooltipVisible}
                    position={TooltipPosition.RIGHT}
                    element={navItemRef.current}
                >
                    <div className={styles.Tooltip}>{title}</div>
                </Tooltip>
            )}
            {children}
        </div>
    );
};

NavigationItem.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconOnly: PropTypes.bool,
    active: PropTypes.bool,
    open: PropTypes.bool,
    isMobileView: PropTypes.bool,
    tooltip: PropTypes.shape({
        icon: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    }),
    variant: PropTypes.oneOf(Object.values(NavItemVariant)),
};

export default NavigationItem;
