import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Button, { ButtonStyle } from '../Button/Button';
import ModalsContext from '../../contexts/ModalsContext';
import CompanyInfo, { CompanyPropTypes } from './CompanyInfo';
import CreateEmployeeForm from '../CreateEmployeeForm/CreateEmployeeForm';
import CreateServiceAddressForm from '../CreateServiceAddressForm/CreateServiceAddressForm';
import EmployeesPermissionsTable, { EmployeesProps } from './EmployeesPermissionsTable';
import ServiceAddressesPermissionsTable, {
    ServiceAddressesProps,
} from './ServiceAddressesPermissionsTable';
// import InvoicingEmailForm from '../InvoicingEmailForm/InvoicingEmailForm'
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useAppConfigContext, Features } from '../../contexts/AppConfigContext';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';
import styles from './UserManagement.module.scss';

const UserManagement = ({
    companyInfo,
    employees = [],
    serviceAddresses = [],
    emails = {
        pdf: '',
        xml: '',
    },
    emailServicesConfig = [],
}) => {
    const pageRef = useRef();
    const { t } = useTranslation();
    const { features } = useAppConfigContext();
    const { showModal, closeModal } = useContext(ModalsContext);
    const [userType, setUserType] = useState('employees');

    useDocumentTitle(features[Features.USER_MANAGEMENT].label, pageRef);

    return (
        <div ref={pageRef} className={styles.UserManagement} data-testid='UserManagement'>
            <h1>{t('userManagement.title')}</h1>
            <p className={styles.Note}>{t('userManagement.description')}</p>
            <LocalErrorBoundary className={styles.CompanyInfoError} row>
                <CompanyInfo
                    accountCount={companyInfo.accountCount}
                    companyName={companyInfo.companyName}
                    companyUIRR={companyInfo.companyUIRR}
                />
                {/* <InvoicingEmailForm
                    pdfEmail={emails.pdf}
                    xmlEmail={emails.xml}
                /> */}
            </LocalErrorBoundary>
            <div className={styles.UserPermissions}>
                <div className={styles.TopBar}>
                    <div /** flex placeholder */></div>
                    <ButtonGroup>
                        <Button
                            icon='user'
                            text={t('userManagement.employeesButton')}
                            style={
                                userType === 'employees'
                                    ? ButtonStyle.PRIMARY
                                    : ButtonStyle.SECONDARY
                            }
                            onClick={() => setUserType('employees')}
                        />
                        <Button
                            icon='email'
                            text={t('userManagement.serviceAddressesButton')}
                            style={
                                userType === 'employees'
                                    ? ButtonStyle.SECONDARY
                                    : ButtonStyle.PRIMARY
                            }
                            onClick={() => setUserType('serviceAddresses')}
                        />
                    </ButtonGroup>
                    <Button
                        icon='add'
                        style={ButtonStyle.SECONDARY}
                        text={
                            userType === 'employees'
                                ? t('userManagement.addEmployee')
                                : t('userManagement.addServiceAddress')
                        }
                        onClick={() => {
                            if (userType === 'employees') {
                                showModal(
                                    t('userManagement.addEmployee'),
                                    <CreateEmployeeForm onClose={closeModal} />
                                );
                            } else {
                                showModal(
                                    t('userManagement.addServiceAddress'),
                                    <CreateServiceAddressForm onClose={closeModal} />
                                );
                            }
                        }}
                    />
                </div>
                {userType === 'employees' && (
                    <LocalErrorBoundary>
                        <EmployeesPermissionsTable
                            employees={employees}
                            emailServicesConfig={emailServicesConfig}
                        />
                    </LocalErrorBoundary>
                )}
                {userType === 'serviceAddresses' && (
                    <LocalErrorBoundary>
                        <ServiceAddressesPermissionsTable
                            serviceAddresses={serviceAddresses}
                            emailServicesConfig={emailServicesConfig}
                        />
                    </LocalErrorBoundary>
                )}
            </div>
        </div>
    );
};

UserManagement.propTypes = {
    companyInfo: PropTypes.shape(CompanyPropTypes).isRequired,
    employees: EmployeesProps,
    serviceAddresses: ServiceAddressesProps,
    emails: PropTypes.shape({
        pdf: PropTypes.string,
        xml: PropTypes.string,
    }),
    emailServicesConfig: PropTypes.arrayOf(
        PropTypes.shape({
            display: PropTypes.bool,
            name: PropTypes.string,
            description: PropTypes.string,
            services: PropTypes.arrayOf(
                PropTypes.shape({
                    emailServiceId: PropTypes.string,
                    name: PropTypes.string,
                    description: PropTypes.string,
                })
            ),
        })
    ),
};

export default UserManagement;
