import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '../Icon/Icon';
import styles from './FilterButtons.module.scss';
import { useEffect } from 'react';

export const FilterButtonStyles = {
    BLUE: 'blue',
    GREEN: 'green',
};

const FilterButton = ({ active, text, clearable, onClick }) => {
    const hasIcon = active && clearable;
    return (
        <span
            onClick={() => onClick()}
            className={classNames({
                [styles.Option]: true,
                [styles.Active]: active,
            })}
        >
            {text}
            {hasIcon && <CrossIcon className={styles.DismissIcon} />}
        </span>
    );
};

const FilterButtons = ({ options, value = [], onChange, style = FilterButtonStyles.BLUE }) => {
    const { t } = useTranslation();
    const [isAllSelected, setIsAllSelected] = useState(true);

    const changeSelection = useCallback(
        (selection) => {
            setIsAllSelected(options.length === selection.length);
            onChange(selection);
        },
        [options, onChange]
    );

    const isOptionIdSelected = useCallback(
        (optionId) => {
            return value.includes(optionId);
        },
        [value]
    );

    const toggleOption = useCallback(
        (optionId) => {
            if (isAllSelected) {
                changeSelection([optionId]);
            } else if (isOptionIdSelected(optionId)) {
                changeSelection(value.filter((id) => id !== optionId));
            } else {
                changeSelection([...value, optionId]);
            }
        },
        [value, isOptionIdSelected, changeSelection, isAllSelected]
    );

    const selectAllOptions = useCallback(() => {
        changeSelection(options.map(({ id }) => id));
    }, [changeSelection, options]);

    useEffect(() => {
        changeSelection(value);
    }, [changeSelection, value]);

    return (
        <div
            data-testid='FilterButtons'
            className={classNames({
                [styles.FilterButtons]: true,
                [styles.Blue]: style === FilterButtonStyles.BLUE,
            })}
        >
            <FilterButton
                active={isAllSelected}
                onClick={() => selectAllOptions()}
                text={t('downloads.filterButtonAll')}
            />
            {options.map(({ id, text }) => (
                <FilterButton
                    key={id}
                    active={!isAllSelected && isOptionIdSelected(id)}
                    onClick={() => toggleOption(id)}
                    text={text}
                    clearable
                />
            ))}
        </div>
    );
};

FilterButtons.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    style: PropTypes.oneOf(Object.values(FilterButtonStyles)),
};

export default FilterButtons;
