import classNames from 'classnames';
import React, { useRef } from 'react';
import { Features, useAppConfigContext } from '../../contexts/AppConfigContext';
import useBookingMessages from '../../hooks/useBookingMessages';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './TimeTable.module.scss';

const TimeTable = () => {
    const pageRef = useRef(null);
    const { features, isMobileView } = useAppConfigContext();
    const url = new URL(features[Features.TIMETABLE]?.getOption('url')).toString();

    useBookingMessages(url);
    useDocumentTitle(features[Features.TIMETABLE].label, pageRef);

    return (
        <div
            ref={pageRef}
            className={classNames({
                [styles.TimeTable]: true,
                [styles.TimeTableMobile]: isMobileView,
            })}
            data-testid='TimeTable'
        >
            <iframe
                name='timetable'
                title='Fahrplanauskunft'
                className={styles.TimeTableFrame}
                src={url}
            />
        </div>
    );
};

TimeTable.propTypes = {};

export default TimeTable;
