import React from 'react';
import PropTypes from 'prop-types';
import { WarningIcon, ErrorIcon, InfoIcon } from '../Icon/Icon';
import styles from './Alert.module.scss';

export const AlertType = Object.freeze({
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
});

export const AlertStyle = Object.freeze({
    SINGLE_COLOR: 'single-color',
    TWO_COLOR: 'two-color',
});

const TYPE_STYLES = {
    [AlertType.INFO]: styles.Info,
    [AlertType.WARNING]: styles.Warning,
    [AlertType.ERROR]: styles.Error,
};

const TYPE_ICONS = {
    [AlertType.INFO]: <InfoIcon />,
    [AlertType.WARNING]: <WarningIcon />,
    [AlertType.ERROR]: <ErrorIcon />,
};

const Alert = ({
    type = AlertType.INFO,
    style = AlertStyle.SINGLE_COLOR,
    className = '',
    ...props
}) => (
    <div
        data-testid='Alert'
        className={`
            ${styles.Alert}
            ${TYPE_STYLES[type]}
            ${className}
            ${style === AlertStyle.TWO_COLOR ? styles.TwoColor : ''}
        `}
    >
        <div className={styles.Icon}>{props.icon || TYPE_ICONS[type]}</div>
        <div className={styles.Content}>{props.children}</div>
    </div>
);

Alert.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(Object.values(AlertType)),
    style: PropTypes.oneOf(Object.values(AlertStyle)),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    icon: PropTypes.node,
};

export default Alert;
