import PropTypes from 'prop-types';
import Select from './Select';
import { useTranslation } from 'react-i18next';

const CountryPhoneCodeSelect = ({
    clearable = true,
    defaultValue = undefined,
    label = 'Ländervorwahl',
    onChange = () => undefined,
    placeholder = 'Bitte wählen',
    readonly = false,
    required = false,
    error = '',
    value = undefined,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <Select
            label={t('profile.countryPhoneCodeSelectionLabel')}
            clearable={clearable}
            defaultValue={defaultValue}
            onChange={onChange}
            placeholder={placeholder}
            readonly={readonly}
            required={required}
            error={error}
            value={value}
            {...props}
        />
    );
};

CountryPhoneCodeSelect.propTypes = {
    clearable: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CountryPhoneCodeSelect;
