import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Grid.module.scss';

const Grid = ({ columns = 2, columnWidth = '1fr', isMobileView = false, children }) => {
    /** @var gridStyle CssProperties **/
    var gridStyle = {
        '--grid-columns': isMobileView ? 1 : columns,
        '--grid-column-width': isMobileView ? '' : columnWidth,
    };

    return (
        <div
            className={classNames({ [styles.Grid]: true, [styles.GridMobile]: isMobileView })}
            style={gridStyle}
            data-testid='Grid'
        >
            {children}
        </div>
    );
};

Grid.propTypes = {
    columns: PropTypes.number,
    columnWidth: PropTypes.string,
    isMobileView: PropTypes.bool,
};
export default Grid;
