import React from 'react';

import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';

const Footer = (props) => {
    var Component = props.isMobileView ? FooterMobile : FooterDesktop;

    return <Component {...props} />;
};

Footer.propTypes = {};

export default Footer;
