/**
 * @generated SignedSource<<4ff3da72b89f5c59a5f09a1149829aa5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Timestamp",
  "kind": "LinkedField",
  "name": "lastModified",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "by",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ServiceAddress",
    "kind": "LinkedField",
    "name": "createServiceAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "crmId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Permission",
        "kind": "LinkedField",
        "name": "permissions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEnabled",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readonly",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateServiceAddressFormMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateServiceAddressFormMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "741113438fc84ee4eaad13d0bc4b35b4",
    "id": null,
    "metadata": {},
    "name": "CreateServiceAddressFormMutation",
    "operationKind": "mutation",
    "text": "mutation CreateServiceAddressFormMutation(\n  $input: CreateServiceAddressInput!\n) {\n  createServiceAddress(input: $input) {\n    crmId\n    email\n    lastModified {\n      at\n      by\n    }\n    permissions {\n      permissionId\n      name\n      isEnabled\n      lastModified {\n        at\n        by\n      }\n      readonly\n    }\n  }\n}\n"
  }
};
})();

node.hash = "d9d210099d14fd03d15f55bb5522ca92";

module.exports = node;
