import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagsContext from '../../contexts/TagsContext';
import { useVisibilityEffect } from '../../hooks/useVisibility';
import Button, { ButtonStyle } from '../Button/Button';
import Tag from '../Tag/Tag';

import styles from './Message.module.scss';
import useDateFormatter from '../../hooks/useDateFormatter';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { AppConfigContext } from '../../contexts/AppConfigContext';

const Message = ({ tags = [], categories = [], children = <></>, ...props }) => {
    const { isMobileView } = useContext(AppConfigContext);
    const { setTags, clearTags } = useContext(TagsContext);
    const messageRef = useRef(null);
    const navigate = useNavigate();
    const { toDateString } = useDateFormatter();
    const { t } = useTranslation();

    useDocumentTitle(props.title, messageRef);

    useVisibilityEffect(
        (isVisible) => {
            if (isVisible) {
                setTags(tags);
            }
        },
        [tags, setTags, clearTags],
        messageRef
    );

    useVisibilityEffect(
        (isVisible) => {
            if (isVisible) {
                return clearTags;
            }
        },
        [clearTags],
        messageRef
    );

    return (
        <article
            ref={messageRef}
            className={classNames({ [styles.Message]: true, [styles.MessageMobile]: isMobileView })}
            data-testid='Message'
        >
            {!isMobileView && (
                <nav>
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('messages.backToDashboard')}
                        icon='arrow-left'
                        onClick={() => navigate('/messages')}
                    />
                </nav>
            )}
            {categories.map((category) => (
                <Tag className={styles.Tag} key={category} text={category} />
            ))}
            <h1 className={styles.MessageTitle}>{props.title}</h1>
            <span className={styles.ReleaseDate}>{toDateString(props.releaseDate)}</span>
            {children}
        </article>
    );
};

Message.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
    releaseDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
    categories: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Message;
