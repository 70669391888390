import React, { useState } from 'react';
import Input, { IconPosition } from '../Input/Input';

const Password = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Input
            {...props}
            iconPosition={IconPosition.END}
            icon='eye'
            type={showPassword ? 'text' : 'password'}
            onIconClick={() => setShowPassword(!showPassword)}
        />
    );
};

Password.propTypes = Object.assign({}, Input.propTypes, {});

export default Password;
