import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import classNames from 'classnames';

import { TabsContextWrapper } from '../../contexts/TabsContext';
import TagsContext from '../../contexts/TagsContext';
import useTagsContext from '../../contexts/useTagsContext';
import ModalsContext from '../../contexts/ModalsContext';
import useModalsContext from '../../contexts/useModalsContext';
import ComponentCacheContext from '../../contexts/ComponentCacheContext';
import useComponentCacheContext from '../../contexts/useComponentCacheContext';
import { CachedComponents } from '../ComponentCache/CacheComponent';
import Header from '../Header/Header';
import ContentHeaderMobile from '../ContentHeaderMobile/ContentHeaderMobile';
import Footer from '../Footer/Footer';
import Navigation from '../Navigation/Navigation';
import SupportBar, { SupportBarLoadingIndicator } from '../SupportBar/SupportBar';
import ModalManager from '../Modal/ModalManager';
import AppLoadingIndicator from '../AppLoadingIndicator/AppLoadingIndicator';
import RestrictionBanner from '../RestrictionIndicator/RestrictionBanner';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useUserContext } from '../../contexts/UserContext';

import styles from './App.module.scss';
import NotAuthorizedErrorBoundary from '../ErrorBoundary/NotAuthorizedErrorBoundary';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';
import AppStateContext from '../../contexts/AppStateContext';
import { useAppConfigContext } from '../../contexts/AppConfigContext';
import Stoerer from '../Stoerer/Stoerer';
import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

export const ReadStoererMutation = graphql`
    mutation AppReadStoererMutation($input: ReadStoererInput!) {
        readStoerer(input: $input)
    }
`;

const App = () => {
    const appRef = useRef();
    const { features, isMobileView } = useAppConfigContext();
    const { isRestricted, me, timestamp } = useUserContext();

    const tagsContext = useTagsContext();
    const modalsContext = useModalsContext();
    const componentCacheContext = useComponentCacheContext();

    const matches = useMatches();
    const [feature, setFeature] = useState(matches[1]?.handle?.feature);
    const [featureTitle, setFeatureTitle] = useState('');
    const [showHotline, setShowHotline] = useState(!!matches[1]?.handle?.showHotline);
    const [stoerer, setStoerer] = useState(
        me.unknownPublishedStoerer.length > 0 ? me.unknownPublishedStoerer[0] : false
    );
    const [closedStoerer, setClosedStoerer] = useState([]);
    const [readStoerer] = useMutation(ReadStoererMutation);

    // nav state management
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    useDocumentTitle('', appRef);

    useEffect(() => {
        const filtered = me.unknownPublishedStoerer.filter((stoerer) => {
            return (
                closedStoerer.find(
                    (item) => item.internal_id === stoerer.internal_id && item.expires > timestamp
                ) === undefined
            );
        });
        setStoerer(filtered.length > 0 ? filtered[0] : false);
    }, [setStoerer, closedStoerer, me.unknownPublishedStoerer, timestamp]);

    useEffect(() => {
        setShowHotline(!!matches[1]?.handle?.showHotline);

        var featureName = matches[1]?.handle?.feature;
        setFeatureTitle(features[featureName]?.label);
    }, [matches, features, setShowHotline]);

    const state = {
        feature,
        setFeature,
        showHotline,
        setShowHotline,
    };

    const closeStoerer = (internal_id, snooze = false) => {
        setClosedStoerer([...closedStoerer, { internal_id, expires: Date.now() + 60 * 1000 }]);
        readStoerer({
            variables: {
                input: {
                    stoererId: internal_id,
                    snooze: snooze,
                },
            },
            onError: (networkError) => {
                console.error(networkError);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    console.error(errors);
                }
            },
        });
    };

    return (
        <AppStateContext.Provider value={state}>
            <TabsContextWrapper>
                <TagsContext.Provider value={tagsContext}>
                    <ModalsContext.Provider value={modalsContext}>
                        <ComponentCacheContext.Provider value={componentCacheContext}>
                            <div
                                className={classNames({
                                    [styles.App]: true,
                                    [styles.AppMobile]: isMobileView,
                                })}
                                data-testid='App'
                                ref={appRef}
                            >
                                <Header
                                    className={styles.AppHeader}
                                    isMobileView={isMobileView}
                                    onOpenMobileNav={() => setIsMobileNavOpen(true)}
                                />
                                <Navigation
                                    className={styles.AppNavigation}
                                    isMobileView={isMobileView}
                                    isMobileNavOpen={isMobileNavOpen}
                                    onAction={() => setIsMobileNavOpen(false)}
                                />
                                {!isMobileView && (
                                    <div className={styles.AppBanner}>
                                        {isRestricted && <RestrictionBanner />}
                                    </div>
                                )}
                                <div
                                    className={classNames({
                                        [styles.AppContent]: true,
                                        [styles.AppContentMobile]: isMobileView,
                                    })}
                                >
                                    <NotAuthorizedErrorBoundary>
                                        <LocalErrorBoundary>
                                            <Suspense
                                                fallback={<AppLoadingIndicator scope='main' />}
                                            >
                                                {isMobileView && featureTitle && (
                                                    <ContentHeaderMobile
                                                        title={featureTitle}
                                                        featureName={matches[1]?.handle?.feature}
                                                        isFeatureInner={!!matches[1]?.params?.slug}
                                                    />
                                                )}
                                                <CachedComponents />
                                                <Outlet />
                                            </Suspense>
                                        </LocalErrorBoundary>
                                    </NotAuthorizedErrorBoundary>
                                    <Footer
                                        className={styles.AppFooter}
                                        isMobileView={isMobileView}
                                    />
                                </div>
                            </div>
                            <Suspense fallback={<SupportBarLoadingIndicator />}>
                                <SupportBar
                                    feature={features && features[matches[1]?.handle?.feature]}
                                />
                            </Suspense>
                            <ModalManager />
                            {stoerer ? (
                                <Stoerer
                                    key={stoerer.internal_id}
                                    stoerer={stoerer}
                                    close={closeStoerer}
                                />
                            ) : null}
                        </ComponentCacheContext.Provider>
                    </ModalsContext.Provider>
                </TagsContext.Provider>
            </TabsContextWrapper>
        </AppStateContext.Provider>
    );
};

App.propTypes = {};
export default App;
