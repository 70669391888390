import React from 'react';
import PropTypes from 'prop-types';
import Gallery from '../Gallery/Gallery';
import Image from '../Image/Image';
import Panel from '../Panel/Panel';
import RichText from '../RichText/RichText';
import Tile, { TilesContainer } from '../Tile/Tile';
import Video from '../Video/Video';

import styles from './DynamicContent.module.scss';
import Terminals, { TerminalsBy, TerminalsQuery } from '../Terminals/Terminals.relay';
import { useQueryLoader } from 'react-relay';
import { DownloadsByCategoryBy } from '../DownloadsByCategory/DownloadsByCategory.relay';

const DynamicContent = ({ blocks = [] }) => {
    const renderBlock = (block, wrapped = true) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        let rendered = null;
        switch (block.layout) {
            case 'richtext':
                rendered = (
                    <RichText key={block.key} rawHTMLStringContent={block.attributes.inhalt} />
                );
                break;
            case 'terminals':
                rendered = <TerminalsBy key={block.key} periods={block.attributes.periods} />;
                break;
            case 'image':
                rendered = (
                    <Image
                        key={block.key}
                        src={
                            (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                            '/asset/' +
                            block.attributes.asset
                        }
                        title={block.attributes.title}
                        description={block.attributes.description}
                    />
                );
                break;
            case 'video':
                rendered = (
                    <Video
                        key={block.key}
                        src={
                            (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                            '/asset/' +
                            block.attributes.asset
                        }
                        poster={
                            block.attributes.poster
                                ? (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                  '/asset/' +
                                  block.attributes.poster
                                : null
                        }
                        title={block.attributes.title}
                        description={block.attributes.description}
                    />
                );
                break;
            case 'tile':
                rendered = (
                    <Tile
                        key={block.key}
                        image={
                            block.attributes.image
                                ? (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) +
                                  '/asset/' +
                                  block.attributes.image +
                                  '/tile'
                                : null
                        }
                        headline={block.attributes.headline}
                        text={block.attributes.text}
                        link={block.attributes.link}
                    />
                );
                break;
            case 'separator':
                return (
                    <div
                        key={block.key}
                        className={styles.Separator}
                        data-size={block.attributes.size}
                    />
                );
            case 'downloads':
                rendered = (
                    <DownloadsByCategoryBy key={block.key} ids={block.attributes.downloads} />
                );
                break;
            default:
                return null;
        }

        return wrapped && rendered ? (
            <div key={block.key} className={styles.Block}>
                {rendered}
            </div>
        ) : (
            rendered
        );
    };

    const renderCollection = (collection) => {
        let rendered = null;
        switch (collection.block.layout) {
            case 'panel_start':
                rendered = (
                    <Panel key={collection.block.key} title={collection.block.attributes.title}>
                        {collection.elements}
                    </Panel>
                );
                break;
            case 'gallery_start':
                rendered = <Gallery key={collection.block.key}>{collection.elements}</Gallery>;
                break;
            case 'tiles_start':
                rendered = (
                    <TilesContainer key={collection.block.key}>
                        {collection.elements}
                    </TilesContainer>
                );
                break;
            default:
                return null;
        }

        return rendered ? (
            <div key={collection.block.key} className={styles.Block}>
                {rendered}
            </div>
        ) : null;
    };

    const convertBlocksToDomTree = (blocksToRender, blocks = [], stack = []) => {
        if (!blocksToRender.length) return blocks;
        const [block, ...rest] = blocksToRender;

        if (block.layout.endsWith('_start')) {
            const collection = {
                block,
                elements: [],
            };
            return convertBlocksToDomTree(rest, blocks, [...stack, collection]);
        }

        if (block.layout.endsWith('_end')) {
            const collection = stack.pop();
            if (stack.length) {
                stack[stack.length - 1].elements.push(renderCollection(collection));
                return convertBlocksToDomTree(rest, blocks, stack);
            } else {
                return convertBlocksToDomTree(
                    rest,
                    [...blocks, renderCollection(collection)],
                    stack
                );
            }
        }

        if (stack.length) {
            stack[stack.length - 1].elements.push(renderBlock(block, false));
            return convertBlocksToDomTree(rest, blocks, stack);
        } else {
            return convertBlocksToDomTree(rest, [...blocks, renderBlock(block)], stack);
        }
    };

    const wrapTiles = (blocksToPrepare) =>
        blocksToPrepare.reduce((carry, block, idx, blocks) => {
            let extendedBlocks = [...carry];
            if (block.layout === 'tile') {
                if (blocks[idx - 1]?.layout !== 'tile') {
                    extendedBlocks = [
                        ...carry,
                        {
                            key: `tiles-container-${idx}`,
                            layout: 'tiles_start',
                        },
                    ];
                }
                extendedBlocks = [...extendedBlocks, block];
                if (blocks[idx + 1]?.layout !== 'tile') {
                    extendedBlocks = [
                        ...extendedBlocks,
                        {
                            key: `tiles-container-${idx}`,
                            layout: 'tiles_end',
                        },
                    ];
                }
            } else {
                extendedBlocks = [...extendedBlocks, block];
            }

            return extendedBlocks;
        }, []);

    const preparedBlocks = wrapTiles(blocks);

    return <div data-testid='DynamicContent'>{convertBlocksToDomTree(preparedBlocks)}</div>;
};

DynamicContent.propTypes = {
    blocks: PropTypes.arrayOf(
        PropTypes.shape({
            layout: PropTypes.string,
            key: PropTypes.string,
            attributes: PropTypes.oneOfType([
                PropTypes.shape({
                    inhalt: PropTypes.string,
                    asset: PropTypes.string,
                    title: PropTypes.string,
                    description: PropTypes.string,
                    headline: PropTypes.string,
                    text: PropTypes.string,
                    link: PropTypes.string,
                    image: PropTypes.string,
                }),
                PropTypes.array, // empty
            ]),
        })
    ),
};

export default DynamicContent;
