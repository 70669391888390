import { useEffect, useState, useRef, useCallback } from 'react';

/**
 * Checks if the element is visible in the viewport
 */
export function useIsVisible(ref) {
    const [isVisible, setIsVisible] = useState(false);
    const observerRef = useRef(null);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);
        });
    }, []);

    useEffect(() => {
        if (!ref.current) return;

        observerRef.current.observe(ref.current);

        return () => {
            observerRef.current.disconnect();
        };
    }, [ref]);

    // here we might need to check !!ref.current?.offsetParent as well
    // the intersection will be false if the content is out from the viewport
    // but with offset checking the hook might break when other hooks are used
    // eg Downloads with useSearchParams:
    // https://gitlab.nexttuesday.de/nt/kombiverkehr/portal/frontend/-/issues/196
    return isVisible;
}

export function useVisibilityEffect(callback, deps, elementRef) {
    const isVisible = useIsVisible(elementRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callbackFn = useCallback(callback, deps);
    useEffect(
        () => {
            // console.debug(`Visibility effect, visible: ${isVisible}`, elementRef.current);
            return callbackFn(isVisible);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [elementRef, isVisible, callbackFn, ...deps]
    );
}
