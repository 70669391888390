import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonGroup.module.scss';

const ButtonGroup = ({ children }) => {
    return (
        <div className={styles.ButtonGroup} data-testid='ButtonGroup'>
            {children}
        </div>
    );
};

ButtonGroup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ButtonGroup;
