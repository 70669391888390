import graphql from 'babel-plugin-relay/macro';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-relay';
import { useTranslation } from 'react-i18next';
import Button, { ButtonStyle } from '../Button/Button';
import Input from '../Form/Input/Input';
import TextArea from '../Form/TextArea/TextArea';
import PhoneNumberPicker from '../Form/PhoneNumberPicker/PhoneNumberPicker';
import Alert, { AlertType } from '../Alert/Alert';
import GenderSelect, { Gender } from '../Form/Select/GenderSelect';
import TitlePicker from '../Form/TitlePicker/TitlePicker';

import styles from './EditContactInformationForm.module.scss';

export const EditContactInformationMutation = graphql`
    mutation EditContactInformationFormMutation($input: UpdateProfileInput!) {
        updateProfile(input: $input) {
            id
        }
    }
`;

export const CompanyInformation = ({ user }) => {
    const { t } = useTranslation();

    const getCompanyTextAreaContent = () => {
        const addressParts = [user.company.name];
        if (user.company.address_supplement) {
            addressParts.push(user.company.address_supplement);
        }
        if (user.company.street) {
            addressParts.push(user.company.street);
        }
        if (user.company.city || user.company.zipcode || user.company.country) {
            [
                []
                    .concat(user.company.country ?? [])
                    .concat(user.company.zipcode ?? [])
                    .join('-'),
                user.company.city,
            ].join(' ');
        }

        return [
            ...addressParts,
            `${user.company.country}-${user.company.zipcode} ${user.company.city}`,
        ].join('\n');
    };

    return (
        <>
            <Input
                className={styles.CompanyInfoField}
                value={t('profile.uirrNumber', { number: user.company?.uirr ?? '' })}
                block
                readonly
            />
            <TextArea
                className={styles.CompanyInfoField}
                value={user.company ? getCompanyTextAreaContent() : ''}
                fitToHeight
                block
                readonly
            />
        </>
    );
};

const EditContactInformationForm = ({ user, onChange }) => {
    const { t } = useTranslation();
    const [isReadonly, setIsReadonly] = useState(true);
    const [gender, setGender] = useState(user.gender);
    const [title, setTitle] = useState(user.title);
    const [otherTitle, setOtherTitle] = useState(user.title_custom);
    const [landlineNumber, setLandlineNumber] = useState({
        countryCode: user.phone?.country,
        prefix: user.phone?.dialing_code,
        phoneNumber: user.phone?.number,
        extension: user.phone?.extension,
    });
    const [mobileNumber, setMobileNumber] = useState({
        countryCode: user.mobile?.country,
        prefix: user.mobile?.dialing_code,
        phoneNumber: user.mobile?.number,
    });
    const [forename, setForename] = useState(user.forename);
    const [surname, setSurname] = useState(user.surname);
    const [position, setPosition] = useState(user.position ?? '');

    const [genderError, setGenderError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [otherTitleError, setOtherTitleError] = useState('');
    const [landlineNumberError, setLandlineNumberError] = useState({});
    const [forenameError, setForenameError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const [positionError, setPositionError] = useState('');
    const [error, setError] = useState('');

    const [updateProfile, isLoading] = useMutation(EditContactInformationMutation);

    const submitForm = () => {
        setError('');
        setGenderError('');
        setForenameError('');
        setSurnameError('');
        setTitleError('');
        setOtherTitleError('');
        setPositionError('');
        setLandlineNumberError({});

        updateProfile({
            variables: {
                input: {
                    forename: forename,
                    surname: surname,
                    gender: gender,
                    title: title || null,
                    title_custom: otherTitle,
                    position: position,
                    phone: {
                        country: landlineNumber.countryCode,
                        dialing_code: landlineNumber.prefix,
                        number: landlineNumber.phoneNumber,
                        extension: landlineNumber.extension,
                    },
                    mobile: {
                        country: mobileNumber.countryCode,
                        dialing_code: mobileNumber.prefix,
                        number: mobileNumber.phoneNumber,
                    },
                },
            },
            onError: (networkError) => {
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        setGenderError(
                            mutationError.extensions.validation['input.gender']?.[0] ?? ''
                        );
                        setForenameError(
                            mutationError.extensions.validation['input.forename']?.[0] ?? ''
                        );
                        setSurnameError(
                            mutationError.extensions.validation['input.surname']?.[0] ?? ''
                        );
                        setTitleError(
                            mutationError.extensions.validation['input.title']?.[0] ?? ''
                        );
                        setOtherTitleError(
                            mutationError.extensions.validation['input.title_custom']?.[0] ?? ''
                        );
                        setPositionError(
                            mutationError.extensions.validation['input.position']?.[0] ?? ''
                        );
                        setLandlineNumberError({
                            countryCode:
                                mutationError.extensions.validation['input.phone.country']?.[0] ??
                                '',
                            prefix:
                                mutationError.extensions.validation[
                                    'input.phone.dialing_code'
                                ]?.[0] ?? '',
                            phoneNumber:
                                mutationError.extensions.validation['input.phone.number']?.[0] ??
                                '',
                            extension:
                                mutationError.extensions.validation['input.phone.extension']?.[0] ??
                                '',
                        });
                    } else {
                        setError(mutationError.message ?? t('generics.unexpectedError'));
                    }
                } else {
                    onChange();
                    setIsReadonly(true);
                }
            },
        });
    };

    const resetForm = () => {
        setGender(user.gender);
        setTitle(user.title ?? '');
        setOtherTitle(user.title_custom ?? '');
        setLandlineNumber({
            countryCode: user.phone?.country,
            prefix: user.phone?.dialing_code,
            phoneNumber: user.phone?.number,
            extension: user.phone?.extension,
        });
        setMobileNumber({
            countryCode: user.mobile?.country,
            prefix: user.mobile?.dialing_code,
            phoneNumber: user.mobile?.number,
        });
        setForename(user.forename);
        setSurname(user.surname);
        setPosition(user.position ?? '');
        setError('');
        setGenderError('');
        setForenameError('');
        setSurnameError('');
        setTitleError('');
        setOtherTitleError('');
        setPositionError('');
        setLandlineNumberError({});
    };

    return (
        <div className={styles.EditContactInformationForm} data-testid='EditContactInformationForm'>
            <h2>{t('profile.companyData')}</h2>
            <CompanyInformation user={user} />
            <form className={styles.Form}>
                <h2>{t('profile.contactInfo')}</h2>
                <p>{t('profile.editYourContactInfo')}:</p>
                <div className={styles.FormRow}>
                    <GenderSelect
                        label={t('profile.genderLabel')}
                        className={styles.FormCellSmall}
                        value={gender}
                        onChange={(value) => setGender(value)}
                        readonly={isReadonly || [Gender.COMPANY, Gender.TECHNICAL].includes(gender)}
                        error={genderError}
                        required
                    />
                    <TitlePicker
                        value={{ title, otherTitle }}
                        className={styles.FormCellTitle}
                        onChange={(value) => {
                            setTitle(value.title);
                            setOtherTitle(value.otherTitle);
                        }}
                        error={{ titleError, otherTitleError }}
                        readonly={isReadonly}
                    />
                </div>
                <div className={styles.FormRow}>
                    <Input
                        className={styles.FormCellMedium}
                        label={t('profile.forenameLabel')}
                        value={forename}
                        onChange={(value) => setForename(value)}
                        readonly={isReadonly}
                        error={forenameError}
                        required
                    />
                    <Input
                        className={styles.FormCellMedium}
                        label={t('profile.surnameLabel')}
                        value={surname}
                        onChange={(value) => setSurname(value)}
                        readonly={isReadonly}
                        error={surnameError}
                        required
                    />
                </div>
                {!isReadonly && <p>{t('profile.phoneLabel')}</p>}
                <div className={styles.FormRow}>
                    <PhoneNumberPicker
                        label={t('profile.phoneLabel')}
                        className={isReadonly ? styles.FormCellMedium : styles.FormCellFull}
                        composed={user.phone?.composed || ''}
                        countryCode={landlineNumber.countryCode}
                        countryCodeError={landlineNumberError.countryCode}
                        phoneNumber={landlineNumber.phoneNumber}
                        phoneNumberError={landlineNumberError.phoneNumber}
                        prefixLabel={t('profile.phonePrefixLabel')}
                        prefix={landlineNumber.prefix}
                        prefixError={landlineNumberError.prefix}
                        extension={landlineNumber.extension}
                        extensionError={landlineNumberError.extension}
                        onChange={setLandlineNumber}
                        readonly={isReadonly}
                        required
                        hasExtension
                    />
                </div>
                {!isReadonly && <p>{t('profile.mobileLabel')}</p>}
                <div className={styles.FormRow}>
                    <PhoneNumberPicker
                        label={t('profile.mobileLabel')}
                        composed={user.mobile?.composed || ''}
                        className={isReadonly ? styles.FormCellMedium : styles.FormCellFull}
                        countryCode={mobileNumber.countryCode}
                        phoneNumber={mobileNumber.phoneNumber}
                        prefix={mobileNumber.prefix}
                        prefixLabel={t('profile.mobilePrefixLabel')}
                        onChange={setMobileNumber}
                        readonly={isReadonly}
                    />
                </div>
                <div className={styles.FormRow}>
                    <Input
                        className={styles.FormCellMedium}
                        label={t('profile.positionLabel')}
                        value={position}
                        onChange={(value) => setPosition(value)}
                        readonly={isReadonly}
                        error={positionError}
                    />
                </div>
                {error && <Alert type={AlertType.ERROR}>{error}</Alert>}
            </form>
            <div className={styles.FormButtons}>
                {isReadonly ? (
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('generics.modifyData')}
                        onClick={() => setIsReadonly(false)}
                        block
                    />
                ) : (
                    <>
                        <Button
                            style={ButtonStyle.PRIMARY}
                            text={t('generics.saveData')}
                            onClick={() => submitForm()}
                            disabled={isLoading}
                            block
                        />
                        <Button
                            style={ButtonStyle.SECONDARY}
                            text={t('generics.cancelButton')}
                            disabled={isLoading}
                            onClick={() => {
                                resetForm();
                                setIsReadonly(true);
                            }}
                            block
                        />
                    </>
                )}
            </div>
        </div>
    );
};

EditContactInformationForm.propTypes = {
    user: PropTypes.shape({
        company: PropTypes.shape({
            uirr: PropTypes.number,
            name: PropTypes.string,
            country: PropTypes.string,
            city: PropTypes.string,
            street: PropTypes.string,
            zipcode: PropTypes.string,
            supplement_address: PropTypes.string,
        }),
        phone: PropTypes.shape({
            composed: PropTypes.string,
            country: PropTypes.string,
            dialing_code: PropTypes.string,
            number: PropTypes.string,
            extension: PropTypes.string,
        }),
        mobile: PropTypes.shape({}),
        gender: PropTypes.oneOf(['OTHER', 'FEMALE', 'MALE', 'TECHNICAL', 'COMPANY']),
        title: PropTypes.oneOf(['DR', 'DR_DR', 'PROF', 'PROF_DR']),
        custom_title: PropTypes.string,
        forename: PropTypes.string,
        surname: PropTypes.string,
        position: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
};

export default EditContactInformationForm;
