import hotlineImage from './servicehotline.svg';

import styles from './ServiceHotline.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useAppConfigContext } from '../../contexts/AppConfigContext';

const ServiceHotline = (props) => {
    const { isMobileView } = useAppConfigContext();
    const { t } = useTranslation();

    return (
        <div
            className={classNames({
                [styles.ServiceHotline]: true,
                [styles.ServiceHotlineMobile]: isMobileView,
            })}
            data-testid='ServiceHotline'
        >
            <p className={styles.ServiceHotlineHeadline}>{t('serviceHotline.wantToHelp')}</p>
            <a href='tel:+496979505555'>+49 / 69 79505-555</a>
            <p className={styles.ServiceHotlineNote}>{t('serviceHotline.availableAt')}</p>
            <img alt='' src={hotlineImage} />
        </div>
    );
};

ServiceHotline.propTypes = {};

export default ServiceHotline;
