/**
 * @generated SignedSource<<b729d7121e89caf983e23089311c0c0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feature",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Tooltip",
  "kind": "LinkedField",
  "name": "tooltip",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Option",
  "kind": "LinkedField",
  "name": "featureOptions",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v1/*: any*/),
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AppNavigation",
    "kind": "LinkedField",
    "name": "children",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/)
    ],
    "storageKey": null
  }
],
v9 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      }
    ],
    "concreteType": "App",
    "kind": "LinkedField",
    "name": "app",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeatureDescription",
        "kind": "LinkedField",
        "name": "features",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "restricted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Option",
            "kind": "LinkedField",
            "name": "defaultOptions",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AppNavigation",
        "kind": "LinkedField",
        "name": "navigation",
        "plural": true,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AppNavigation",
        "kind": "LinkedField",
        "name": "navigationBottom",
        "plural": true,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AppOptions",
        "kind": "LinkedField",
        "name": "options",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TransportFrequencyOption",
            "kind": "LinkedField",
            "name": "transport_frequencies",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TransportUnitAmountOption",
            "kind": "LinkedField",
            "name": "transport_unit_amounts",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TransportUnitTypeOption",
            "kind": "LinkedField",
            "name": "transport_unit_types",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TransportWeightOption",
            "kind": "LinkedField",
            "name": "transport_weights",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppConfigContext_AppQuery",
    "selections": (v10/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppConfigContext_AppQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "9e220f73638778a3345fa5353ca68615",
    "id": null,
    "metadata": {},
    "name": "AppConfigContext_AppQuery",
    "operationKind": "query",
    "text": "query AppConfigContext_AppQuery(\n  $mobile: Boolean!\n) {\n  app(mobile: $mobile) {\n    features {\n      feature\n      label\n      restricted\n      defaultOptions {\n        key\n        value\n      }\n    }\n    navigation {\n      label\n      icon\n      tooltip {\n        icon\n        message\n      }\n      feature\n      featureOptions {\n        key\n        value\n      }\n      children {\n        label\n        icon\n        tooltip {\n          icon\n          message\n        }\n        feature\n        featureOptions {\n          key\n          value\n        }\n      }\n    }\n    navigationBottom {\n      label\n      icon\n      tooltip {\n        icon\n        message\n      }\n      feature\n      featureOptions {\n        key\n        value\n      }\n      children {\n        label\n        icon\n        tooltip {\n          icon\n          message\n        }\n        feature\n        featureOptions {\n          key\n          value\n        }\n      }\n    }\n    options {\n      transport_frequencies {\n        value\n        label\n      }\n      transport_unit_amounts {\n        value\n        label\n      }\n      transport_unit_types {\n        value\n        label\n      }\n      transport_weights {\n        value\n        label\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "8504322fe1c1e3eccf9ddd7b3555b93a";

module.exports = node;
