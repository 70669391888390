import graphql from 'babel-plugin-relay/macro';
import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from '../Table/Table';
import LastModifiedCell, { lastModifiedSortType } from './Cells/LastModifiedCell';
import PermissionsCell from './Cells/PermissionsCell';
import MainCell from './Cells/MainCell';
import Dialog from '../Dialog/Dialog';
import DialogType from '../Dialog/DialogType';
import Button, { ButtonStyle } from '../Button/Button';

import styles from './PermissionsTable.module.scss';
import EmailServicesCell from './Cells/EmailServicesCell';

export const UpdateServiceAddressMutation = graphql`
    mutation ServiceAddressesPermissionsTableUpdateMutation(
        $crmId: String!
        $input: UpdateServiceAddressInput!
    ) {
        updateServiceAddress(crmId: $crmId, input: $input) {
            crmId
            permissions {
                permissionId
                name
                isEnabled
                lastModified {
                    at
                    by
                }
                readonly
            }
            emailServices {
                emailServiceId
                isEnabled
            }
            lastModified {
                at
                by
            }
        }
    }
`;

export const DeleteServiceAddressMutation = graphql`
    mutation ServiceAddressesPermissionsTableDeleteMutation($crmId: String!) {
        deleteServiceAddress(crmId: $crmId) {
            crmId
        }
    }
`;

const ServiceAddressesPermissionsTable = ({ serviceAddresses = [], emailServicesConfig }) => {
    const { t } = useTranslation();
    const [deletedServiceAddress, setDeletedServiceAddress] = useState(null);

    const updateServiceAddressUpdater = useCallback((store, serviceAddressId) => {
        const userRecord = store
            .getRoot()
            .getLinkedRecord('userManagement')
            .getLinkedRecords('serviceAddresses')
            .find((record) => record.getValue('crmId') === serviceAddressId);

        if (!userRecord) {
            return;
        }
        if (!store.getRootField('updateServiceAddress')) {
            return;
        }

        const permissionsRecord = store
            .getRootField('updateServiceAddress')
            .getLinkedRecords('permissions');
        userRecord.setLinkedRecords(permissionsRecord, 'permissions');

        const emailServicesRecord = store
            .getRootField('updateServiceAddress')
            .getLinkedRecords('emailServices');
        userRecord.setLinkedRecords(emailServicesRecord, 'emailServices');

        const lastModifiedRecord = store
            .getRootField('updateServiceAddress')
            .getLinkedRecord('lastModified');
        userRecord.setLinkedRecord(lastModifiedRecord, 'lastModified');
    }, []);

    const deleteServiceAddressUpdater = useCallback((store) => {
        try {
            const crmId = store.getRootField('deleteServiceAddress').getValue('crmId');
            const userManagementRecord = store.getRoot().getLinkedRecord('userManagement');
            userManagementRecord.setLinkedRecords(
                userManagementRecord
                    .getLinkedRecords('serviceAddresses')
                    .filter((record) => record.getValue('crmId') !== crmId),
                'serviceAddresses'
            );
        } catch (err) {
            console.log(err);
        }
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: t('userManagement.emailColumn'),
                accessor: 'email',
                cellClass: `${styles.MainCell} ${styles.EmailCell}`,
                sortable: true,
                Cell: (props) => (
                    <MainCell
                        {...props}
                        canDelete={false}
                        deleteMutation={DeleteServiceAddressMutation}
                        deleteMutationUpdater={deleteServiceAddressUpdater}
                        updateMutation={UpdateServiceAddressMutation}
                        updateMutationUpdater={updateServiceAddressUpdater}
                        onUserDeleted={(user) => {
                            setDeletedServiceAddress(user);
                        }}
                    >
                        <div className={styles.CellBreak} title={props.value}>
                            {props.value}
                        </div>
                    </MainCell>
                ),
            },
            {
                Header: t('userManagement.permissionsColumn'),
                accessor: 'permissions',
                filterable: true,
                cellClass: `${styles.MainCell} ${styles.PermissionsCell}`,
                Cell: PermissionsCell,
            },
            {
                Header: t('userManagement.emailServicesColumn'),
                accessor: 'emailServices',
                filterable: true,
                Cell: (props) => (
                    <EmailServicesCell {...props} emailServicesConfig={emailServicesConfig} />
                ),
            },
            {
                Header: t('userManagement.lastModifiedColumn'),
                accessor: 'lastModified',
                sortable: true,
                cellClass: styles.LastModifiedCell,
                Cell: LastModifiedCell,
                sortType: lastModifiedSortType,
            },
        ],
        [t, updateServiceAddressUpdater, deleteServiceAddressUpdater, emailServicesConfig]
    );

    return (
        <>
            <Table columns={columns} data={serviceAddresses} />
            {deletedServiceAddress && (
                <Dialog
                    title={t('userManagement.userDeletedTitle')}
                    message={t('userManagement.userDeletedContent', {
                        email: deletedServiceAddress.email,
                    })}
                    type={DialogType.INFO}
                    onClose={() => setDeletedServiceAddress(null)}
                    actions={
                        <Button
                            style={ButtonStyle.PRIMARY}
                            text={t('generics.close')}
                            onClick={() => setDeletedServiceAddress(null)}
                        />
                    }
                />
            )}
        </>
    );
};

const serviceAddressProps = {
    email: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(
        PropTypes.shape({
            permissionId: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
            lasModified: PropTypes.shape({
                at: PropTypes.string,
                by: PropTypes.string,
            }),
        })
    ),
    emailServices: PropTypes.arrayOf(
        PropTypes.shape({
            emailServiceId: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
        })
    ),
    lastModified: PropTypes.shape({
        at: PropTypes.string,
        by: PropTypes.string,
    }),
};

ServiceAddressesPermissionsTable.propTypes = {
    serviceAddresses: PropTypes.arrayOf(PropTypes.shape(serviceAddressProps)),
};

export const ServiceAddressesProps = PropTypes.arrayOf(PropTypes.shape(serviceAddressProps));
export default ServiceAddressesPermissionsTable;
