import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Tag.module.scss';

export const TagColor = {
    BLUE: 1,
    GREEN: 2,
};

const Tag = ({
    isMobileView = false,
    color = TagColor.BLUE,
    className: classNameProps = '',
    multiline = false,
    ...props
}) => {
    const className = classNames(styles.Tag, {
        [styles.TagMobile]: isMobileView,
        [styles.TagIsMultiline]: multiline,
        [styles.TagIsGreen]: color === TagColor.GREEN,
        [styles.TagIsBlue]: color === TagColor.BLUE,
        [styles.TagIsClickable]: props.onClick,
        [classNameProps]: !!classNameProps,
    });

    const onClick = (ev) => {
        if (props.onClick) {
            ev.preventDefault();
            props.onClick();
        }
    };

    return (
        <span
            className={className}
            data-testid='Tag'
            onClick={onClick}
            title={!multiline && props.text}
        >
            {props.text}
        </span>
    );
};

Tag.propTypes = {
    isMobileView: PropTypes.bool,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.number,
    multiline: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Tag;
