import { useTranslation } from 'react-i18next';
import { useEnquiryContext } from '../../contexts/EnquiryContext';
import TextArea from '../Form/TextArea/TextArea';
import { ArrowRightBigIcon } from '../Icon/Icon';
import Panel from '../Panel/Panel';
import styles from './Connection.module.scss';
import TerminalPicker from './TerminalPicker.relay';
import TransportDetailsForm from './TransportDetailsForm';

export function useConnectionFormValidator() {
    const {
        connectionFromTerminal,
        connectionFromCity,
        connectionToTerminal,
        connectionToCity,
        transportUnitType,
        transportUnitAmount,
        transportFrequency,
    } = useEnquiryContext();

    return () =>
        (connectionFromTerminal || connectionFromCity) &&
        (connectionToTerminal || connectionToCity) &&
        transportUnitType &&
        transportUnitAmount &&
        transportFrequency;
}

const Connection = () => {
    const { t } = useTranslation();
    const {
        connectionFromTerminal,
        connectionFromTerminalError,
        connectionFromCity,
        connectionFromCityError,
        connectionToTerminal,
        connectionToTerminalError,
        connectionToCity,
        connectionToCityError,
        message,
        setFields,
    } = useEnquiryContext();

    return (
        <div className={styles.Connection} data-testid='Connection'>
            <Panel title={t('enquiry.shippingInfo')} collapsed={false}>
                <div className={styles.ShippingInfo}>
                    <div className={styles.TerminalPickerContainer}>
                        <TerminalPicker
                            title={t('enquiry.connectionFrom')}
                            terminalLabel={t('enquiry.connectionFromTerminalLabel')}
                            onChange={({ terminal, city }) =>
                                setFields({
                                    connectionFromTerminal: terminal,
                                    connectionFromCity: city,
                                })
                            }
                            values={{
                                terminal: connectionFromTerminal,
                                city: connectionFromCity,
                            }}
                            errors={{
                                terminal: connectionFromTerminalError,
                                city: connectionFromCityError,
                            }}
                        />
                    </div>
                    <ArrowRightBigIcon className={styles.ArrowIcon} />
                    <div className={styles.TerminalPickerContainer}>
                        <TerminalPicker
                            title={t('enquiry.connectionTo')}
                            terminalLabel={t('enquiry.connectionToTerminalLabel')}
                            onChange={({ terminal, city }) =>
                                setFields({
                                    connectionToTerminal: terminal,
                                    connectionToCity: city,
                                })
                            }
                            values={{
                                terminal: connectionToTerminal,
                                city: connectionToCity,
                            }}
                            errors={{
                                terminal: connectionToTerminalError,
                                city: connectionToCityError,
                            }}
                        />
                    </div>
                </div>
            </Panel>
            <Panel title={t('enquiry.transportDetails')}>
                <TransportDetailsForm />
            </Panel>
            <Panel title={t('enquiry.otherInfo')}>
                <TextArea
                    className={styles.OtherInfoInput}
                    label={t('enquiry.otherInfoLabel')}
                    value={message}
                    onChange={(value) => setFields({ message: value })}
                    fitToHeight
                />
            </Panel>
        </div>
    );
};

Connection.propTypes = {};

export default Connection;
