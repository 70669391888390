import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PermissionsList from '../PermissionsList/PermissionsList';

import styles from './Roles.module.scss';

const Roles = ({ user }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.Roles} data-testid='Roles'>
            <h2>{t('profile.rolesTitle')}</h2>
            <p>{t('profile.rolesDescription')}</p>
            <PermissionsList permissions={user.permissions} />
        </div>
    );
};

Roles.propTypes = {
    user: PropTypes.shape({
        permissions: PropTypes.arrayOf(
            PropTypes.shape({
                permissionId: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                isEnabled: PropTypes.bool.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default Roles;
