import classNames from 'classnames';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DeLogoSrc from './Logo.svg';
import EnLogoSrc from './Logo_en.svg';

const CompanyLogo = ({ className = '' }) => {
    const { i18n } = useTranslation();

    const logoSrc = useMemo(() => {
        switch (i18n.language) {
            case 'en':
                return EnLogoSrc;
            default:
                return DeLogoSrc;
        }
    }, [i18n.language]);

    return (
        <img
            data-testid='CompanyLogo'
            src={logoSrc}
            alt=''
            className={classNames({
                [className]: !!className,
            })}
        />
    );
};

CompanyLogo.propTypes = {
    className: PropTypes.string,
};

export default CompanyLogo;
