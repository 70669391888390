import graphql from 'babel-plugin-relay/macro';
import React, { useState } from 'react';
import { useMutation } from 'react-relay';
import { useTranslation } from 'react-i18next';

import Button, { ButtonStyle } from '../Button/Button';
import Password from '../Form/Password/Password';
import Alert, { AlertType } from '../Alert/Alert';

import styles from './ChangePasswordForm.module.scss';

export const ChangePasswordMutation = graphql`
    mutation ChangePasswordFormMutation($input: UpdatePasswordInput!) {
        updatePassword(input: $input)
    }
`;

const ChangePasswordForm = () => {
    const [isReadonly, setIsReadonly] = useState(true);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [updatePassword, isLoading] = useMutation(ChangePasswordMutation);
    const { t } = useTranslation();

    const submitPassword = () => {
        setPasswordError('');
        setPasswordConfirmationError('');
        setError('');

        updatePassword({
            variables: {
                input: {
                    password: password,
                    password_confirmation: passwordConfirmation,
                },
            },
            onError: (networkError) => {
                setSuccess(false);
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    setSuccess(false);
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        setPasswordError(
                            mutationError.extensions.validation['input.password']?.[0] ?? ''
                        );
                        setPasswordConfirmationError(
                            mutationError.extensions.validation[
                                'input.password_confirmation'
                            ]?.[0] ?? ''
                        );
                    } else {
                        setError(mutationError.message);
                    }
                } else {
                    setPassword('');
                    setPasswordConfirmation('');
                    setIsReadonly(true);
                    setSuccess(true);
                }
            },
        });
    };

    const showForm = () => {
        setIsReadonly(false);
    };

    const resetForm = () => {
        setSuccess(false);
        setIsReadonly(true);
        setPassword('');
        setPasswordConfirmation('');
    };

    return (
        <form data-testid='ChangePasswordForm'>
            <fieldset className={styles.PasswordFields}>
                <Password
                    autocomplete='off'
                    value={password}
                    label={t('profile.newPasswordLabel')}
                    className={styles.Input}
                    placeholder={t('profile.newPasswordPlaceholder')}
                    error={passwordError}
                    onChange={(value) => {
                        setPassword(value);
                        setPasswordError('');
                    }}
                    readonly={isReadonly}
                    required
                />
                <Password
                    autocomplete='off'
                    value={passwordConfirmation}
                    label={t('profile.confirmNewPasswordLabel')}
                    className={styles.Input}
                    placeholder={t('profile.confirmNewPasswordPlaceholder')}
                    error={passwordConfirmationError}
                    onChange={(value) => {
                        setPasswordConfirmation(value);
                        setPasswordConfirmationError('');
                    }}
                    readonly={isReadonly}
                    required
                />
            </fieldset>
            {!isReadonly && <Alert type={AlertType.INFO}>{t('profile.editPasswordInfo')}</Alert>}
            {isReadonly && success && (
                <Alert type={AlertType.INFO}>{t('profile.passwordChangedInfo')}</Alert>
            )}
            {error && <Alert type={AlertType.ERROR}>{error}</Alert>}
            <div className={styles.ButtonContainer}>
                {isReadonly ? (
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('profile.editPassword')}
                        onClick={showForm}
                        block
                    />
                ) : (
                    <>
                        <Button
                            style={ButtonStyle.PRIMARY}
                            text={t('profile.savePassword')}
                            onClick={submitPassword}
                            disabled={isLoading}
                            block
                        />
                        <Button
                            style={ButtonStyle.SECONDARY}
                            text={t('generics.cancelButton')}
                            onClick={resetForm}
                            disabled={isLoading}
                            block
                        />
                    </>
                )}
            </div>
        </form>
    );
};

ChangePasswordForm.propTypes = {};

export default ChangePasswordForm;
