import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CrossIcon } from '../Icon/Icon';
import styles from './Modal.module.scss';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';

export const ModalBox = ({ children, title, onClose, size }) => {
    return (
        <section className={styles.Modal} data-testid='Modal' data-size={size}>
            <div className={styles.ModalHeader}>
                <h1 data-testid='ModalTitle'>{title}</h1>
                {onClose && (
                    <button onClick={() => onClose()}>
                        <CrossIcon />
                    </button>
                )}
            </div>
            <div className={styles.ModalBody} data-testid='ModalContent'>
                <LocalErrorBoundary>{children}</LocalErrorBoundary>
            </div>
        </section>
    );
};

const Modal = ({
    isOpen = false,
    title = '',
    size = 'default',
    children = <></>,
    onClose = () => {},
}) => {
    useEffect(() => {
        document.documentElement.style.overflow = isOpen ? 'hidden' : 'auto';
    }, [isOpen]);

    if (!isOpen) {
        return <></>;
    }

    return (
        <div className={styles.Backdrop}>
            <ModalBox title={title} onClose={onClose} size={size}>
                {children}
            </ModalBox>
        </div>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    size: PropTypes.string,
};

export default Modal;
