import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { useTranslation } from 'react-i18next';

export const useRouteTerminalsQuery = graphql`
    query useRouteTerminalsQuery {
        terminals {
            internal_id
            name
            country {
                internal_id
                name
                name_en
            }
        }
    }
`;

function useRouteTerminals() {
    const { i18n } = useTranslation();
    const data = useLazyLoadQuery(useRouteTerminalsQuery);
    return data.terminals.map((terminal) => terminalMapper(terminal, i18n.language));
}

export const terminalMapper = (terminal, language) => ({
    internal_id: terminal.internal_id,
    name: terminal.name,
    countryName: language === 'en' ? terminal.country.name_en : terminal.country.name,
    countryID: terminal.country.internal_id,
});

export default useRouteTerminals;
