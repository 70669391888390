/**
 * @generated SignedSource<<5496246fee9cfb21eb60a175c4908775>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "meta_column",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "booklet_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file_name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file_type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file_size",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated_at",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated_at_diff",
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  (v0/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "DownloadCategory",
  "kind": "LinkedField",
  "name": "main_category",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "DownloadCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DownloadsOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DownloadCard",
        "kind": "LinkedField",
        "name": "downloadCards",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Download",
            "kind": "LinkedField",
            "name": "downloads",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "DownloadHasCategoriesConnection",
                "kind": "LinkedField",
                "name": "categoryAssignments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DownloadHasCategoriesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DownloadHasCategories",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "categoryAssignments(first:20)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DownloadsOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DownloadCard",
        "kind": "LinkedField",
        "name": "downloadCards",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Download",
            "kind": "LinkedField",
            "name": "downloads",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "DownloadHasCategoriesConnection",
                "kind": "LinkedField",
                "name": "categoryAssignments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DownloadHasCategoriesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DownloadHasCategories",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "categoryAssignments(first:20)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47bc2af710a4b30b4034377bfae4b37a",
    "id": null,
    "metadata": {},
    "name": "DownloadsOverviewQuery",
    "operationKind": "query",
    "text": "query DownloadsOverviewQuery {\n  downloadCards {\n    name\n    meta_column\n    downloads {\n      id\n      name\n      url\n      type\n      booklet_id\n      file_name\n      file_type\n      file_size\n      updated_at\n      updated_at_diff\n      main_category {\n        id\n        name\n      }\n      categoryAssignments(first: 20) {\n        edges {\n          node {\n            category {\n              id\n              name\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "4990006c9360ca2586770a471a7d201a";

module.exports = node;
