import PropTypes from 'prop-types';
import Auth from './Auth';
import { useAuth, useLogout } from '../../hooks/useAuth';
import UserContextWrapper from '../../contexts/UserContext';

const AuthGuard = (props) => {
    const { isLoggedIn, setAccessToken } = useAuth();
    const logout = useLogout(true);
    if (window.location.href.endsWith('/impersonate')) {
        logout().then(() => {
            window.location.href = '/';
            return null;
        });
    }

    return !isLoggedIn ? (
        <Auth setAccessToken={setAccessToken} />
    ) : (
        <UserContextWrapper>{props.children}</UserContextWrapper>
    );
};

AuthGuard.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AuthGuard;
