import Table from '../Table/Table';
import { useTranslation } from 'react-i18next';
import React, { useContext, useMemo, useState } from 'react';
import ModalsContext from '../../contexts/ModalsContext';
import StorageConditions from './StorageConditions';
import emptyStateImage from './empty-state.png';
import styles from './Terminals.module.scss';
import Input from '../Form/Input/Input';

const Terminals = (props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalsContext);

    const [search, setSearch] = useState('');
    const filteredTerminals = useMemo(
        () =>
            props.terminals.filter((terminal) =>
                terminal.name.toLowerCase().includes(search.toLowerCase())
            ),
        [search, props.terminals]
    );

    const columns = [
        {
            Header: t('terminals.terminal'),
            accessor: 'name',
            sortable: true,
            sortType: 'alphanumericCaseInsensitive',
            Cell: ({ row }) => {
                return row.original.name;
            },
        },
        {
            Header: t('terminals.country'),
            accessor: 'country.name',
            sortable: true,
            sortType: 'alphanumericCaseInsensitive',
            Cell: ({ row }) => {
                return row.original.country.name;
            },
        },
        {
            Header: (
                <Input
                    type='text'
                    value={search}
                    onChange={setSearch}
                    placeholder={t('terminals.search')}
                    icon={'search'}
                    className={styles.Search}
                />
            ),
            accessor: 'id',
            sortable: false,
            Cell: ({ row }) => {
                return (
                    <div className={styles.Last}>
                        <span
                            className={styles.Action}
                            onClick={() =>
                                showModal(
                                    row.original.name,
                                    <StorageConditions hafasId={row.original.hafasId} />,
                                    'back',
                                    'extra-wide'
                                )
                            }
                        >
                            {t('terminals.storageConditions')}
                        </span>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <Table
                columns={columns}
                data={filteredTerminals}
                defaultSort={[
                    {
                        id: 'name',
                        desc: false,
                    },
                ]}
                emptyState={
                    <div>
                        <img src={emptyStateImage} alt={''} />
                        <div className={styles.EmptyHeadline}>
                            {t('terminals.emptyStateHeadline')}
                        </div>
                        <div className={styles.EmptySubHeadline}>
                            {t('terminals.emptyStateSubHeadline')}
                        </div>
                    </div>
                }
                rowAction={(row) => {
                    showModal(
                        row.original.name,
                        <StorageConditions hafasId={row.original.hafasId} />,
                        'back',
                        'extra-wide'
                    );
                }}
            />
        </div>
    );
};
export default Terminals;
