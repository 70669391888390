import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function useDateFormatter() {
    const { i18n } = useTranslation();

    const createFormatter = useCallback(
        (config) => {
            const formatter = new Intl.DateTimeFormat(i18n.language, config);
            return (date) => {
                const dateInstance = date instanceof Date ? date : new Date(date.replace(' ', 'T'));
                return formatter.format(dateInstance);
            };
        },
        [i18n.language]
    );

    const toDateString = useCallback(
        (date) => {
            return createFormatter({
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            })(date);
        },
        [createFormatter]
    );

    const toDateWithDayString = useCallback(
        (date) => {
            return createFormatter({
                weekday: 'long',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            })(date);
        },
        [createFormatter]
    );

    const toDateTimeString = useCallback(
        (date) => {
            return createFormatter({
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            })(date);
        },
        [createFormatter]
    );

    return {
        toDateString,
        toDateWithDayString,
        toDateTimeString,
    };
}

export default useDateFormatter;
