/**
 * @generated SignedSource<<55bd76752af4db0a4b4535c45b3ba8fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "crmId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "crmId",
        "variableName": "crmId"
      }
    ],
    "concreteType": "ServiceAddress",
    "kind": "LinkedField",
    "name": "deleteServiceAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "crmId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceAddressesPermissionsTableDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ServiceAddressesPermissionsTableDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7008d8ec407c2303a55f8094627260c0",
    "id": null,
    "metadata": {},
    "name": "ServiceAddressesPermissionsTableDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ServiceAddressesPermissionsTableDeleteMutation(\n  $crmId: String!\n) {\n  deleteServiceAddress(crmId: $crmId) {\n    crmId\n  }\n}\n"
  }
};
})();

node.hash = "58881247914083fbcc836ee15f04324a";

module.exports = node;
