import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useAppConfigContext } from '../../contexts/AppConfigContext';

import FeaturePreviewMobile from './FeaturePreviewMobile';

import styles from './FeaturePreview.module.scss';

import defaultPreviewImgDE from './restricted_de.png';
import defaultPreviewImgEN from './restricted_en.png';
import bookingsPreviewImgDE from './restricted_bookings_de.jpg';
import bookingsPreviewImgEN from './restricted_bookings_en.jpg';
import ordersPreviewImgDE from './restricted_orders_de.jpg';
import ordersPreviewImgEN from './restricted_orders_en.jpg';
import messagesPreviewImgDE from './restricted_messages_de.jpg';
import messagesPreviewImgEN from './restricted_messages_en.jpg';

const images = {
    default: {
        en: defaultPreviewImgEN,
        de: defaultPreviewImgDE,
    },
    bookings: {
        en: bookingsPreviewImgEN,
        de: bookingsPreviewImgDE,
    },
    orders: {
        en: ordersPreviewImgEN,
        de: ordersPreviewImgDE,
    },
    messages: {
        en: messagesPreviewImgEN,
        de: messagesPreviewImgDE,
    },
};

const FeaturePreview = ({ feature = 'default', img }) => {
    const { isMobileView } = useAppConfigContext();
    const { i18n } = useTranslation();

    if (isMobileView) return <FeaturePreviewMobile />;

    const src = img || images[feature][i18n.language];

    if (isMobileView) return null;

    return (
        <div className={styles.FeaturePreview} data-testid='FeaturePreview'>
            <img src={src} alt='' />
        </div>
    );
};

FeaturePreview.propTypes = {
    img: PropTypes.string,
    feature: PropTypes.oneOf(['bookings', 'orders', 'messages', 'default']),
};

export default FeaturePreview;
