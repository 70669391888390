import graphql from 'babel-plugin-relay/macro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-relay';
import Alert, { AlertType } from '../Alert/Alert';
import Button, { ButtonStyle } from '../Button/Button';
import Input from '../Form/Input/Input';
import LanguageSelect from '../Form/Select/LanguageSelect';

import styles from './CreateServiceAddressForm.module.scss';

export const CreateServiceAddressMutation = graphql`
    mutation CreateServiceAddressFormMutation($input: CreateServiceAddressInput!) {
        createServiceAddress(input: $input) {
            crmId
            email
            lastModified {
                at
                by
            }
            permissions {
                permissionId
                name
                isEnabled
                lastModified {
                    at
                    by
                }
                readonly
            }
        }
    }
`;

const CreateServiceAddressForm = ({ onClose }) => {
    const [language, setLanguage] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [languageError, setLanguageError] = useState('');
    const [createServiceAddress, isLoading] = useMutation(CreateServiceAddressMutation);
    const [error, setError] = useState('');
    const { t } = useTranslation();

    const submitForm = () => {
        setError('');
        setEmailError('');
        setLanguageError('');

        createServiceAddress({
            variables: {
                input: {
                    email,
                    language,
                },
            },
            onError: (networkError) => {
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        setEmailError(
                            mutationError.extensions.validation['input.email']?.[0] ?? ''
                        );
                        setLanguageError(
                            mutationError.extensions.validation['input.language']?.[0] ?? ''
                        );
                    } else {
                        setError(mutationError.message ?? t('generics.unexpectedError'));
                    }
                } else {
                    onClose();
                }
            },
            updater: (store) => {
                if (!store.getRootField('createServiceAddress')) return;

                const userManagementRecord = store.getRoot().getLinkedRecord('userManagement');
                userManagementRecord.setLinkedRecords(
                    [
                        ...userManagementRecord.getLinkedRecords('serviceAddresses'),
                        store.getRootField('createServiceAddress'),
                    ],
                    'serviceAddresses'
                );
            },
        });
    };

    return (
        <form className={styles.CreateServiceAddressForm} data-testid='CreateServiceAddressForm'>
            <div className={styles.Fields}>
                <Input
                    label={t('profile.emailLabel')}
                    value={email}
                    onChange={(value) => setEmail(value)}
                    type='email'
                    autocomplete='email'
                    error={emailError}
                    required
                />
                <LanguageSelect
                    value={language}
                    error={languageError}
                    onChange={(value) => setLanguage(value)}
                    required
                />
            </div>
            {error && <Alert type={AlertType.ERROR}>{error}</Alert>}
            <Button
                text={t('generics.saveData')}
                style={ButtonStyle.SECONDARY}
                onClick={() => submitForm()}
                disabled={isLoading}
            />
        </form>
    );
};

CreateServiceAddressForm.propTypes = {};

export default CreateServiceAddressForm;
