/**
 * @generated SignedSource<<193baf0166ab4333c9286e0ad82956a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasActiveStorageConditions"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periods"
},
v2 = [
  {
    "kind": "Variable",
    "name": "hasActiveStorageConditions",
    "variableName": "hasActiveStorageConditions"
  },
  {
    "kind": "Variable",
    "name": "periods",
    "variableName": "periods"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hafasId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TerminalsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Terminal",
        "kind": "LinkedField",
        "name": "terminals",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "country",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TerminalsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Terminal",
        "kind": "LinkedField",
        "name": "terminals",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "country",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d3e196d84984fdb94aa28101f99a1a7",
    "id": null,
    "metadata": {},
    "name": "TerminalsQuery",
    "operationKind": "query",
    "text": "query TerminalsQuery(\n  $periods: [Int!]\n  $hasActiveStorageConditions: Boolean\n) {\n  terminals(periods: $periods, hasActiveStorageConditions: $hasActiveStorageConditions) {\n    id\n    name\n    hafasId\n    country {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "19d01aa586cc2e9608cb2a31cfdc1b8e";

module.exports = node;
