import PermissionsList from '../../PermissionsList/PermissionsList';
import PermissionsListEditor from '../../PermissionsList/PermissionsListEditor';

import styles from '../PermissionsTable.module.scss';

const PermissionsCell = ({ row, value, editCellContent }) => {
    return row.state.isEditing ? (
        <PermissionsListEditor
            permissions={value}
            onChange={(permission, newValue) => {
                editCellContent(row, 'permissions', {
                    ...row.state.changes.permissions,
                    [permission]: newValue,
                });
            }}
        />
    ) : (
        <PermissionsList className={styles.PermissionsList} permissions={value} />
    );
};

export default PermissionsCell;
