import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';
import DynamicContent from '../DynamicContent/DynamicContent';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';
import Component from '../Message/Message';

export const MessageQuery = graphql`
    query MessageQuery($slug: String!, $state: String) {
        findMessageBySlug(slug: $slug, state: $state) {
            id
            title
            slug
            content
            release_date
            tags(first: 20) {
                edges {
                    node {
                        name
                        slug
                    }
                }
            }
            categoryAssignments(first: 20) {
                edges {
                    node {
                        category {
                            name
                        }
                    }
                }
            }
        }
    }
`;

const Message = (props) => {
    const { findMessageBySlug: message } = usePreloadedQuery(MessageQuery, props.preloadedQuery);
    const tags = useMemo(() => message.tags.edges.map(({ node }) => node.name), [message]);
    const categories = useMemo(
        () => message.categoryAssignments.edges.map(({ node }) => node.category.name),
        [message]
    );
    const content = useMemo(() => JSON.parse(message.content), [message]);

    return (
        <Component
            title={message.title}
            tags={tags}
            releaseDate={message.release_date}
            categories={categories}
        >
            <LocalErrorBoundary>
                <DynamicContent blocks={content} />
            </LocalErrorBoundary>
        </Component>
    );
};

Message.propTypes = {
    preloadedQuery: PropTypes.object,
};

export default Message;
