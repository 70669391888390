import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../Form/CheckBox/CheckBox';
import styles from './EmailServicesList.module.scss';
import { InfoIcon } from '../Icon/Icon';
import classNames from 'classnames';

const EmailServicesListEditor = ({
    className = '',
    isEditing = false,
    emailServices = [],
    emailServicesConfig = [],
    ...props
}) => {
    return (
        <form
            className={[styles.EmailServicesList, className].join(' ').trim()}
            data-testid='EmailServicesListEditor'
        >
            {emailServicesConfig.map((group, groupIndex) =>
                group.display ? (
                    <div key={groupIndex} className={styles.EmailServiceGroup}>
                        <div className={styles.EmailServiceGroupLabel}>
                            {group.name}
                            {group.description ? (
                                <div className={styles.EmailServiceGroupIcon}>
                                    <InfoIcon />
                                    <div className={styles.EmailServiceTooltipWrapper}>
                                        <div className={styles.EmailServiceTooltip}>
                                            {group.description}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className={styles.EmailServiceGroupServices}>
                            {group.services.map((service) => (
                                <div
                                    key={service.emailServiceId}
                                    className={styles.EmailServiceContainer}
                                >
                                    <CheckBox
                                        className={classNames({
                                            [styles.EmailServiceCheckBox]: true,
                                            [styles.EmailServiceCheckBoxSmall]: true,
                                        })}
                                        checked={
                                            emailServices.find(
                                                ({ emailServiceId }) =>
                                                    emailServiceId === service.emailServiceId
                                            )?.isEnabled || false
                                        }
                                        label={service.name.length < 1 ? 'Unknown' : service.name}
                                        onChange={(value) =>
                                            props.onChange(service.emailServiceId, value)
                                        }
                                        readonly={false}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div key={groupIndex} className={styles.EmailServiceGroup}>
                        {group.services.map((service) => (
                            <div
                                key={service.emailServiceId}
                                className={styles.EmailServiceContainer}
                            >
                                <CheckBox
                                    className={styles.EmailServiceCheckBox}
                                    checked={
                                        emailServices.find(
                                            ({ emailServiceId }) =>
                                                emailServiceId === service.emailServiceId
                                        )?.isEnabled || false
                                    }
                                    label={service.name.length < 1 ? 'Unknown' : service.name}
                                    onChange={(value) =>
                                        props.onChange(service.emailServiceId, value)
                                    }
                                    readonly={false}
                                />
                                {service.description ? (
                                    <div className={styles.EmailServiceGroupIcon}>
                                        <InfoIcon />
                                        <div className={styles.EmailServiceTooltipWrapper}>
                                            <div className={styles.EmailServiceTooltip}>
                                                {service.description}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </div>
                )
            )}
        </form>
    );
};

EmailServicesListEditor.propTypes = {
    className: PropTypes.string,
    isEditing: PropTypes.bool,
    emailServices: PropTypes.arrayOf(
        PropTypes.shape({
            emailServiceId: PropTypes.string.isRequired,
            isEnabled: PropTypes.bool.isRequired,
        })
    ),
    emailServicesConfig: PropTypes.arrayOf(
        PropTypes.shape({
            display: PropTypes.bool,
            name: PropTypes.string,
            description: PropTypes.string,
            services: PropTypes.arrayOf(
                PropTypes.shape({
                    emailServiceId: PropTypes.string,
                    name: PropTypes.string,
                    description: PropTypes.string,
                })
            ),
        })
    ),
};

export default EmailServicesListEditor;
