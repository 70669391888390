import EmailServicesList from '../../EmailServicesList/EmailServicesList';
import EmailServicesListEditor from '../../EmailServicesList/EmailServicesListEditor';

import styles from '../EmailServicesTable.module.scss';
import PropTypes from 'prop-types';

const EmailServicesCell = ({
    row,
    value,
    editCellContent,
    emailServicesConfig,
    readonly = false,
}) => {
    return !readonly && row.state.isEditing ? (
        <EmailServicesListEditor
            emailServices={value}
            onChange={(emailService, newValue) => {
                editCellContent(row, 'emailServices', {
                    ...row.state.changes.emailServices,
                    [emailService]: newValue,
                });
            }}
            emailServicesConfig={emailServicesConfig}
        />
    ) : (
        <EmailServicesList
            className={styles.EmailServicesList}
            emailServices={value}
            emailServicesConfig={emailServicesConfig}
        />
    );
};

EmailServicesCell.propTypes = {
    row: PropTypes.any,
    value: PropTypes.any,
    editCellContent: PropTypes.any,
    emailServicesConfig: PropTypes.any,
    readonly: PropTypes.bool,
};

export default EmailServicesCell;
