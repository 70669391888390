import { useTranslation } from 'react-i18next';
import { PasswordIcon } from '../Icon/Icon';

import styles from './RestrictionIndicator.module.scss';

const RestrictionBanner = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.RestrictionBanner} data-testid='RestrictionBanner'>
            <span>
                <PasswordIcon className={styles.LockIcon} />
            </span>
            <p>
                <strong>{t('auth.limitedAccessBanner')}:</strong>{' '}
                {t('auth.limitedAccessBannerDescription')}
            </p>
        </div>
    );
};

export default RestrictionBanner;
