import graphql from 'babel-plugin-relay/macro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-relay';
import Alert, { AlertType } from '../Alert/Alert';
import Button, { ButtonStyle } from '../Button/Button';
import Input from '../Form/Input/Input';
import GenderSelect from '../Form/Select/GenderSelect';
import LanguageSelect from '../Form/Select/LanguageSelect';
import TitlePicker from '../Form/TitlePicker/TitlePicker';

import styles from './CreateEmployeeForm.module.scss';

export const CreateEmployeeMutation = graphql`
    mutation CreateEmployeeFormMutation($input: CreateEmployeeInput!) {
        createEmployee(input: $input) {
            crmId
            forename
            surname
            email
            lastModified {
                at
                by
            }
            permissions {
                permissionId
                name
                isEnabled
                lastModified {
                    at
                    by
                }
                readonly
            }
        }
    }
`;

const CreateEmployeeForm = ({ onClose }) => {
    const [gender, setGender] = useState(null);
    const [title, setTitle] = useState(null);
    const [otherTitle, setOtherTitle] = useState('');
    const [forename, setForename] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [language, setLanguage] = useState(null);
    const [createEmployee, isLoading] = useMutation(CreateEmployeeMutation);

    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [otherTitleError, setOtherTitleError] = useState('');
    const [forenameError, setForenameError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [languageError, setLanguageError] = useState('');

    const submitForm = () => {
        setError('');
        setGenderError('');
        setTitleError('');
        setOtherTitleError('');
        setForenameError('');
        setSurnameError('');
        setEmailError('');
        setLanguageError('');

        createEmployee({
            variables: {
                input: {
                    forename,
                    surname,
                    email,
                    gender,
                    language,
                    ...(title && { title }),
                    ...(otherTitle && { title_custom: otherTitle }),
                },
            },
            onError: (networkError) => {
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        setGenderError(
                            mutationError.extensions.validation['input.gender']?.[0] ?? ''
                        );
                        setEmailError(
                            mutationError.extensions.validation['input.email']?.[0] ?? ''
                        );
                        setForenameError(
                            mutationError.extensions.validation['input.forename']?.[0] ?? ''
                        );
                        setSurnameError(
                            mutationError.extensions.validation['input.surname']?.[0] ?? ''
                        );
                        setTitleError(
                            mutationError.extensions.validation['input.title']?.[0] ?? ''
                        );
                        setOtherTitleError(
                            mutationError.extensions.validation['input.title_custom']?.[0] ?? ''
                        );
                        setLanguageError(
                            mutationError.extensions.validation['input.language']?.[0] ?? ''
                        );
                    } else {
                        setError(mutationError.message ?? t('generics.unexpectedError'));
                    }
                } else {
                    onClose();
                }
            },
            updater: (store) => {
                if (!store.getRootField('createEmployee')) return;

                const userManagementRecord = store.getRoot().getLinkedRecord('userManagement');
                userManagementRecord.setLinkedRecords(
                    [
                        ...userManagementRecord.getLinkedRecords('users'),
                        store.getRootField('createEmployee'),
                    ],
                    'users'
                );
            },
        });
    };

    return (
        <form className={styles.CreateEmployeeForm} data-testid='CreateEmployeeForm'>
            <div className={styles.FormRow}>
                <GenderSelect
                    label={t('profile.genderLabel')}
                    className={styles.FormCellSmall}
                    value={gender}
                    onChange={(value) => setGender(value)}
                    error={genderError}
                    required
                />
                <TitlePicker
                    value={{ title, otherTitle }}
                    className={styles.FormCellTitle}
                    onChange={(value) => {
                        setTitle(value.title);
                        setOtherTitle(value.otherTitle);
                    }}
                    error={{ titleError, otherTitleError }}
                />
            </div>
            <div className={styles.FormRow}>
                <Input
                    className={styles.FormCellMedium}
                    label={t('profile.forenameLabel')}
                    value={forename}
                    onChange={(value) => setForename(value)}
                    error={forenameError}
                    required
                />
                <Input
                    className={styles.FormCellMedium}
                    label={t('profile.surnameLabel')}
                    value={surname}
                    onChange={(value) => setSurname(value)}
                    error={surnameError}
                    required
                />
            </div>
            <div className={styles.FormRow}>
                <Input
                    className={styles.FormCellMedium}
                    label={t('profile.emailLabel')}
                    value={email}
                    onChange={(value) => setEmail(value)}
                    type='email'
                    autocomplete='email'
                    error={emailError}
                    required
                />
            </div>
            <div className={styles.FormRow}>
                <LanguageSelect
                    className={styles.FormCellMedium}
                    value={language}
                    onChange={(value) => setLanguage(value)}
                    error={languageError}
                    required
                />
            </div>
            {error && <Alert type={AlertType.ERROR}>{error}</Alert>}
            <Button
                style={ButtonStyle.SECONDARY}
                text={t('generics.saveData')}
                onClick={() => submitForm()}
                disabled={isLoading}
            />
        </form>
    );
};

CreateEmployeeForm.propTypes = {};

export default CreateEmployeeForm;
